import { SMess } from "../socket";
import { Bot, RobotStateMessageType } from "./bot";
import BotBase from "./bot-base";

class HomeBot extends BotBase {
    constructor(b: Bot) {
        super(b);
    }

    async dispatch(me: SMess) {
        switch (me.cmd) {
            case 'news':
                if (this.bot.setup.playRoomGame == 100) {
                    this.scheduleEvent(this.ranT(1500, 1000), RobotStateMessageType.GoToRooms);
                }
                break;
        }
    }

    async stateMessage(am: RobotStateMessageType, d?: any) {
        switch (am) {
            case RobotStateMessageType.LoggedIn:
                if (this.bot.setup.playRoomGame == 100) {
                    this.scheduleEvent(this.ranT(1500, 1000), RobotStateMessageType.GoToRooms);
                }
                break;
        }
    }
}

export default HomeBot
