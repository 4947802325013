import Ui from "../utils/ui";
import State from "./state";

class StateInfo extends State {
    async onEnter(data?:any): Promise<void> {
        await super.onEnter(data);
    }
    async render(): Promise<string> {
        console.log('render', this.constructor.name)
        return `this is info</br>
        ${Ui.button('test')}
        `;
    }
    async click(ac: string, tgt: JQuery<HTMLElement>=null): Promise<void> {
        switch (ac) {
            case 'test':
                const id= Ui.popup({hl:'Nice Popup',content:'With a content'})
                break;
        }
        return null;
    }
}
export default StateInfo