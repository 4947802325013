// import $ from "jquery";
// import * as _ from 'lodash';
import App from './app';
import state from './state/state'
import './css/index.scss';
import platform from './platform/platform-web'
// const platform = new Pltf()
import './fonts/style.css';
import FontFaceObserver from 'fontfaceobserver'


$(async function () {
	console.log('platform say', platform.say())
	var replacePage = function (aurl: string) {
		// $.ajax({
		// 	url: url,
		// 	type: 'get',
		// 	dataType: 'html',
		// 	success: function(data){
		// 		var dom = $(data);
		// 		var title = dom.filter('title').text();
		// 		var html = dom.filter('.container').html();
		// 		$('title').text(title);
		// 		$('.container').html(html);
		// 	}
		// });
		try {
			const url = new URL(aurl);
			app.menu.go(url.pathname);
		} catch (e) {
			console.log(e);
		}
	}

	$(document).on("click", "a.html5history", function (e) {
		if (window.location.protocol != 'file:') {
			history.pushState(null, null, this.href);
		}
		replacePage(this.href);
		e.preventDefault();
	});

	$('#status').val($('#status').val() + ' on ' + new Date());

	$(window).bind('popstate', function () {
		replacePage(location.pathname);
	});





	var font2 = new FontFaceObserver('brotesque');

	font2.load(null, 10000).then(function () {
		console.log('FONT brotesque font has loaded.');
	}).catch(function (e) {
		console.log('FONT brotesque font failed to load.', e);
	});
});

// function component() {
// 	const element = document.createElement('div');
// 	element.id = 'all';
// 	element.innerHTML = `<div id="content"></div>\n<div id="menu"></div>\n<div id="div-gpt-ad-1443956755766-0"></div>\n<div id="header"></div>`;
// 	return element;
// }

document.body.appendChild(platform.cmp());


// setTimeout(function() {
//     adslots.length = 0;
//     var dfpslots = $("body").find("div[data-dfp]");
//     console.log("googletag div[data-dfp]:visible1", dfpslots.length, $(dfpslots).length);

//     if ($(dfpslots).length) {
//       googletag.cmd.push(function() {
//         // adslots.length = 0;
//         var adslot0 = googletag.defineSlot('/72800089/schnapsen', [160, 600], 'div-gpt-ad-1443956755766-0').addService(googletag.pubads());
//         adslots.push(adslot0);
//         var adslot1 = googletag.defineSlot('/72800089/schnapsensmart', [320, 50], 'div-gpt-ad-1445802620866-0').addService(googletag.pubads());
//         adslots.push(adslot1);

//         googletag.pubads().setPrivacySettings({
//           nonPersonalizedAds: consentOrNOt ? false : true
//         });

//         googletag.pubads().enableSingleRequest();
//         googletag.enableServices();

//         if ($("#div-gpt-ad-1443956755766-0:visible").length) {
//           console.log("#div-gpt-ad-1443956755766-0:visible");
//           googletag.cmd.push(function() {
//             googletag.display('div-gpt-ad-1443956755766-0');
//             ads.adRefreshInterval = setInterval(function() {
//               googletag.pubads().refresh([adslots[0]]);
//             }, 30000);
//           });
//         } else if ($("#div-gpt-ad-1445802620866-0:visible").length) {
//           console.log("#div-gpt-ad-1445802620866-0:visible");
//           googletag.cmd.push(function() {
//             googletag.display('div-gpt-ad-1445802620866-0');
//             ads.adRefreshInterval = setInterval(function() {
//               googletag.pubads().refresh([adslots[1]]);
//             }, 30000);
//           });
//         }
//       });
//     }
//   }, 0);

const app = App.instance;
app.init();