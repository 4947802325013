import { AppTaskType } from "./task.type"

class AppTaskBase {
    type: AppTaskType
    static lastTaskId: number = 0
    id: number
    constructor(type: AppTaskType) {
        this.id = AppTaskBase.lastTaskId++
        this.type = type
    }
    async fire() {
    }
    tick() {
    }
}

export {AppTaskBase}