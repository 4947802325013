import App from "../app";
import { CMess, SMess } from "../socket";
import StateGame from "./state.game";

class StateComputerGame extends StateGame {
    data: SMess;
    async onEnter(data?:any): Promise<void> {
        await super.onEnter(data);
        this.data = await App.instance.socket.sendRoundtrip(new CMess('robotgame'));
        console.log('robotgame', this.data)
    }
}
export default StateComputerGame