// export class Bar {
//     email?: string;
//     uid?: number;
//     token?: string;
//     device?: string;
//     constructor(data:keyable){
//         console.log(this.constructor.name, data);
//         this.uid = data.uid;
//         this.token = data.token;
//         this.email = data.email;
//         this.device = data.device;
//     }
// }

export interface keyable {
    [key: string]: any
}

// export enum CacheKey {
//     userdata = 'userdata0000'
// }

// interface CacheValues {
//     [CacheKey.userdata]: Bar;
// }

// interface CacheUtil {
//     set: <T extends CacheKey>(key: T, object: CacheValues[T]) => void;
//     get: <T extends CacheKey>(key: T) => CacheValues[T];
//     remove: (key: CacheKey) => void;
//     removeAll: () => void;
// }

// export const cacheUtil: CacheUtil = {
//     set: (key, object) => {
//         console.log('cacheUtil set', JSON.stringify(object));
//         localStorage.setItem(key, JSON.stringify(object));
//     },
//     get: (key) => JSON.parse(localStorage.getItem(key) ?? '{}'),
//     remove: (key) => localStorage.removeItem(key),
//     removeAll: () => localStorage.clear(),
// };

class MyStorage {
    async get(name: string): Promise<string | null> {
        try {
            return JSON.parse(localStorage.getItem(name) ?? null)
        } catch (e) {
            console.log(this.constructor.name, e);
        }
        return null;
    }
    async set(name: string, val: any): Promise<boolean> {
        if (val===undefined || val === null) {
            return await this.remove(name)
        }
        localStorage.setItem(name, JSON.stringify(val));
        return null;
    }
    async remove(name: string): Promise<boolean> {
        localStorage.removeItem(name)
        return true;
    }
    async clear(name: string): Promise<boolean> {
        localStorage.clear()
        return true;
    }
}

export default new MyStorage()