import { Col, Val } from './game.card.enums';
class Card {
    readonly col: Col;
    readonly val: Val;
    constructor(col: Col, val: Val = Val.Bub) {
        this.col = col;
        this.val = val;
        // console.log(this.constructor.name, Card.log(this));
    }
    equals(col: Col, val: Val): boolean {
        return ((this.col == col) && (this.val == val))
    }
    static log(c: Card): string {
        if (!c) {
            return "na-na";
        }
        return Col[c.col] + '-' + Val[c.val];
    }
    static json(c: Card): string {
        return Col[c.col] + '-' + Val[c.val];
    }
    static fromJson(c: string): Card | null {
        const spl = c.match(/^([a-z]{3,6})-([a-z]{2,8})$/i);
        // console.log('card fromJson', c, spl);
        if (spl == null) {
            console.log('Card from json, could not parse', c);
            return null;
        }
        let col = Card.colFromStr(spl[1]);
        let val = Card.valFromStr(spl[2]);
        if ((col == null) || (val == null)) {
            return null;
        }
        return new Card(col, val);
    }
    static colFromStr(col: string): Col | null {
        switch (col) {
            case Col[Col.Herz]:
                return Col.Herz;
            case Col[Col.Karo]:
                return Col.Karo;
            case Col[Col.Pik]:
                return Col.Pik;
            case Col[Col.Treff]:
                return Col.Treff;
            default:
                return null;
        }
    }
    static valFromStr(val: string): Val | null {
        switch (val) {
            case Val[Val.Bub]:
                return Val.Bub;
            case Val[Val.Dame]:
                return Val.Dame;
            case Val[Val.Koenig]:
                return Val.Koenig;
            case Val[Val.Zehn]:
                return Val.Zehn;
            case Val[Val.As]:
                return Val.As;
            default:
                return null;
        }
    }
}

export default Card;