import Card from './game.card';
import { Col, Val } from './game.card.enums';

class CardList {
    cards: Card[] 
    constructor(){
        this.cards = new Array()
    }

    // to check
    static copy(src: CardList): CardList {
        let ret = new CardList();
        for (const card of src.cards) {
            ret.add(new Card(card.col, card.val));
        }
        return ret;
    }
    
    static from(src: Card[]): CardList {
        // console.log('CL from Cards', src.map(el=>(Card.log(el))));
        let ret = new CardList();
        for (const card of src.filter((c) => c)) {
            // console.log('CL from add', Card.log(card));
            ret.add(card);
        }
        return ret;
    }

    static fromJsonStrDepr(j: string): CardList {
        // console.log('CL fromJ', j, typeof(j));
        var rc: Card[] = (j||'').trim().split(/,\s*/).filter((el) => el.trim()).map((el) => Card.fromJson(el)).filter((el) => el).map((el) => el!);
        return CardList.from(rc);
    }

    static fromJson(j: string[]): CardList {
        // console.log('CL fromJ', j, typeof(j));
        if ((!j) || (!Array.isArray(j))) {
            return CardList.from([])
        }
        var rc: Card[] = j.filter(el=>el).map(el=>el.trim()).map(el=>Card.fromJson(el)).filter(el=>el)
        return CardList.from(rc);
    }

    json() {
        return this.cards.map(Card.json);
    }

    // to check
    has(col: Col, val: Val): boolean {
        return (this.cards.find((c) => ((c.col == col) && (c.val == val))) != null);
    }

    // to check
    indexOf(col: Col, val: Val): number {
        for (const { index, value } of this.cards.map((value, index) => ({ index, value }))) {
            if ((value.col == col) && (value.val == val)) {
                return index;
            }
        }
        return -1;
    }

    // to check
    add(card: Card) {
        this.cards.push(card);
    }

    // to check
    remove(card: Card) {
        const indx = this.indexOf(card.col, card.val);
        console.log('remove', Card.json(card), indx);
        if (indx >= 0) {
            this.cards.splice(indx, 1);
        }
    }

    // to check
    cardListOfSameColorAndHigherThanThis(card: Card): Card[] {
        return this.cards.filter((c) => ((c.col == card.col) && (c.val > card.val)));
    }

    // to check
    filterByColor(col: Col): Card[] {
        return this.cards.filter((c) => (c.col == col));
    }

    log(): string {
        return this.constructor.name + ': ' + this.cards.map(Card.log).join(', ');
    }


}

export default CardList;
