import App from "../app";
import { SMess } from "../socket";
import { Bot, RobotStateMessageType } from "./bot";
import BotBase from "./bot-base";

class RobotGameBot extends BotBase {
    constructor(b: Bot) {
        super(b);
    }

    async dispatch(me: SMess) {
        switch (me.cmd) {
            // case 'loggedin':
            //     this.loggedin = true;
            //     console.log(`B* ${this.constructor.name} loggedin`);
            //     this.bot.stateMessage(RobotStateMessageType.LoggedIn);

            //     break;
        }
    }

    async stateMessage(am: RobotStateMessageType, d?: any) {
        switch (am) {
            case RobotStateMessageType.LoggedIn:
                if (this.bot.setup.playRobotGame == 100) {
                    this.scheduleEvent(1500, RobotStateMessageType.TryRobotGame);
                }
                break;
            case RobotStateMessageType.TryRobotGame:
                if (this.bot.setup.loggedin && this.bot.setup.game == null) {
                    App.instance.menu.goWithUrlChange('/computer')
                }
                break;
        }
    }
}

export default RobotGameBot