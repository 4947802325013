import Game from "../game/game"
import { CardAni, GameTaskMoveCard } from "../tasks/tasks.game"

export { BotCol, BotVal, BotInMessages, BotZugType, BotCard, BotBrain, BotState, BotAnswer, GamePlayBot }


enum BotInMessages {
    bummerlstart,
    bummerlend,
    warte,
    ausspielen,
    gspieltaus,
    stechen,
    stechen20,
    wurdegestochenC2,
    hastgestochenC2,
    wurdegestochenC1,
    hastgestochenC1,
    gamestart,
    gemeend,
    ausgetauscht,
    zugedreht,
    gameend,
    timeout
}

enum BotZugType {
    ausspielen,
    stechen,
    austauschen,
    zudrehen,
    error
}


enum BotCol { Herz, Karo, Pik, Treff, Undis }
enum BotVal { Bub = 2, Dame = 3, Koenig = 4, Zehn = 10, As = 11, Undis = 0 }

class BotCard {
    constructor(public readonly col: BotCol, public readonly val: BotVal) { }
    static from(crd: string): BotCard {
        var [col, val] = crd.split(/\-/)
        return new BotCard(BotCard.colFromStr(col), BotCard.valFromStr(val))
    }
    static colFromStr(col: string): BotCol {
        return BotCol[col as keyof typeof BotCol]
    }
    static valFromStr(val: string): BotVal {
        return BotVal[val as keyof typeof BotVal]
    }
    equals(crd: BotCard) {
        return this.col == crd.col && this.val == crd.val
    }
    isPartOf(list: BotCard[]): boolean {
        return !!list.find(c => c.equals(this))
    }
}

class BotBrain {
    constructor(public readonly stupidity: number = 0.5, public readonly zudrehBias: number = 2) { }
}

class BotState {
    constructor(
        public readonly zugC: number,
        public readonly state: string,
        public readonly hand: BotCard[],
        public readonly atout: BotCard,
        public readonly table: BotCard[],
        public readonly zwang: boolean,
        public readonly deckSize: number,
        public readonly hisStiche: BotCard[],
        public readonly myStiche: BotCard[],
    ) {
        // this.hand = hand.map(c => BotCard.from(c))
        // this.table = table.map(c => BotCard.from(c))
        // this.hisStiche = hisStiche.map(c => BotCard.from(c))
        // this.myStiche = myStiche.map(c => BotCard.from(c))
        // this.atout = BotCard.from(atout)
    }
}

class BotAnswer {
    constructor(
        public readonly type: BotZugType,
        public readonly card: BotCard,
        public readonly comment: string,
        public readonly error: string = null,
    ) {}
}



class GamePlayBot {
    static imFromStr(i: string): BotInMessages {
        return BotInMessages[i as keyof typeof BotInMessages]
    }
    static inMessage(req: BotInMessages, state: BotState, brain: BotBrain, comment?: string): BotAnswer {
        // const req = GamePlayBot.imFromStr(reqs)
        console.log(`${this.constructor.name} zugRequest`, BotInMessages[req]);
        if ((state.state != null) && ((state.state! == 'player0') || (state.state! == 'player1'))) {
            switch (req) {
                case BotInMessages.ausspielen:
                case BotInMessages.hastgestochenC2:
                case BotInMessages.hastgestochenC1:
                    if ((state.table.length == 0) || (state.table.length == 2)) {
                        // const [typea, carda, commenta] = GamePlayBot.botausspielen(state);
                        // this.respond(typea, carda, commenta);
                        return GamePlayBot.botausspielen(state, brain);
                    } else {
                        console.log(this.constructor.name, 'undis tablelen:', state.table.length);
                        return new BotAnswer(BotZugType.error, null, `message:${BotInMessages[req]} want:0 or 2 got:${state.table.length}`, 'tablelen')
                    }
                    break;
                case BotInMessages.stechen:
                case BotInMessages.stechen20:
                    if (state.table.length == 1) {
                        // const [types, cards, comments] = GamePlayBot.botstechen(state);
                        // this.respond(types, cards, comments);
                        return GamePlayBot.botstechen(state, brain);
                    } else {
                        console.log(this.constructor.name, 'undis tablelen:', state.table.length);
                        return new BotAnswer(BotZugType.error, null, `message:${BotInMessages[req]} want:1 got:${state.table.length}`, 'tablelen')
                    }
                    break;
                case BotInMessages.warte:
                case BotInMessages.wurdegestochenC2:
                case BotInMessages.wurdegestochenC1:
                case BotInMessages.ausgetauscht:
                case BotInMessages.gamestart:
                case BotInMessages.gameend:
                case BotInMessages.bummerlstart:
                case BotInMessages.bummerlend:
                case BotInMessages.zugedreht:
                case BotInMessages.gemeend:
                case BotInMessages.gspieltaus:
                    break;
                case BotInMessages.timeout:
                    console.log(this.constructor.name, 'UNDISIQ', BotInMessages[req]);
                    break;
                default:
                    console.log(this.constructor.name, 'UNDISI', BotInMessages[req]);
            }
        }
        return new BotAnswer(BotZugType.error, undefined, `message:${BotInMessages[req]} zugC:${state.zugC} table:${state.table.length}`, 'undis or unused')
    }


    static botausspielen(state: BotState, brain: BotBrain): BotAnswer { //[BotZugType, Card?, string?] {
        let l20: BotCard[] = GamePlayBot.list20(state.hand, state.atout);
        let l20Dames = l20.filter((c) => (c.val == BotVal.Dame));

        if (!state.zwang) { // NO ZWANG RULES
            var AtBu = new BotCard(state.atout.col, BotVal.Bub);
            // RULE 1: Austauschen ifpossible
            if ((new BotCard(AtBu.col, AtBu.val)).isPartOf(state.hand) && (state.deckSize >= 0)) { // talon and only after 1st stich
                return new BotAnswer(BotZugType.austauschen, AtBu, "AN0 exchn")
            }

            var zudrsum = this.sumPossiblePoints(l20Dames, state.hand, state.atout) // RULE 2: Zudrehen, if possible
            if ((state.deckSize >= 0) && (state.deckSize < 10) && (zudrsum + brain.zudrehBias >= 66)) {
                return new BotAnswer(BotZugType.zudrehen, undefined, "AN1 ZuCkS")
            }
            if (l20.length > 0) { // RULE 3: 40er, 20er
                return new BotAnswer(BotZugType.ausspielen, l20.find((c) => (c.val == BotVal.Dame)), "AN2 40/20")
            }
            var ck = GamePlayBot.ValueIsInHandNotOfColour(state.hand, BotVal.Bub, state.atout.col);
            if (ck.length > 0) { // RULE 4: BUB, no Atout
                // responder(null, "ausspielen", {
                //     c: ck[0]
                // }, "AN3 BnA__");
                return new BotAnswer(BotZugType.ausspielen, ck[0], "AN3 BnA__")
            }
            ck = this._ValueIsInHandNotOfColourAnd20PartnerWasDropped(BotVal.Dame, state.atout.col, state.hand, state);
            if (ck.length > 0) {// RULE 5: DAME, no Atout, KOENIG was dropped before
                return new BotAnswer(BotZugType.ausspielen, ck[0], "AN4 DnAcK")
            }
            ck = this._ValueIsInHandNotOfColourAnd20PartnerWasDropped(BotVal.Koenig, state.atout.col, state.hand, state);
            if (ck.length > 0) { // RULE 6: KOENIG, no Atout, DAME was dropped before(fixme überprüfen)
                return new BotAnswer(BotZugType.ausspielen, ck[0], "AN5 KnADc")
            }
            ck = GamePlayBot.ValueIsInHandNotOfColour(state.hand, BotVal.Dame, state.atout.col);
            if (ck.length > 0) { // RULE 7: DAME, no Atout
                return new BotAnswer(BotZugType.ausspielen, ck[0], "AN6 DnA__")
            }
            ck = GamePlayBot.ValueIsInHandNotOfColour(state.hand, BotVal.Koenig, state.atout.col);
            if (ck.length > 0) { // RULE 8: KOENIG, no Atout
                return new BotAnswer(BotZugType.ausspielen, ck[0], "AN7 KnA__")
            }
            ck = GamePlayBot.ValueIsInHandNotOfColour(state.hand, BotVal.Zehn, state.atout.col);
            if (ck.length > 0) { // RULE 9: 10er, no Atout
                return new BotAnswer(BotZugType.ausspielen, ck[0], "AN8 1nA__")
            }
            ck = GamePlayBot.ValueIsInHandNotOfColour(state.hand, BotVal.As, state.atout.col);
            if (ck.length > 0) { // RULE 10: AS, no Atout
                return new BotAnswer(BotZugType.ausspielen, ck[0], "AN9 AnA__")
            }
            if ((new BotCard(state.atout.col, BotVal.Dame)).isPartOf(state.hand)) { // RULE 11: Atout DAME
                return new BotAnswer(BotZugType.ausspielen, new BotCard(state.atout.col, BotVal.Dame), "ANa DA___")
            }
            if ((new BotCard(state.atout.col, BotVal.Koenig)).isPartOf(state.hand)) {// RULE 12: Atout KOENIG
                return new BotAnswer(BotZugType.ausspielen, new BotCard(state.atout.col, BotVal.Koenig), "ANb KA___")
            }
            if ((new BotCard(state.atout.col, BotVal.Zehn)).isPartOf(state.hand)) {// RULE 13: Atout 10er
                return new BotAnswer(BotZugType.ausspielen, new BotCard(state.atout.col, BotVal.Zehn), "ANc 1A___")
            }
            if ((new BotCard(state.atout.col, BotVal.As)).isPartOf(state.hand)) {// RULE 14: Atout AS
                return new BotAnswer(BotZugType.ausspielen, new BotCard(state.atout.col, BotVal.As), "ANd AA___")
            }
            console.log("ANe *_nc_");
            return new BotAnswer(BotZugType.ausspielen, undefined, "ANe *_nc_")

        } else { // ZWANG RULEZ
            console.log('GamePlugSessionHookBot botausspielen zw', 2);

            //
            // 		 * fixme: atAs()
            // 		 *
            // 		 * At10(wenn atas gefallen | wenns startatout war und nicht ausgetauscht
            // 		 * ist)
            // 		 *
            // 		 * 40er
            // 		 *
            // 		 * 20er
            // 		 *
            // 		 * AtKo(wenn as/10 der farbe gefallen | wenns startatout war und nicht
            // 		 * ausgetauscht ist)
            // 		 *
            // 		 * AtDa(wenn as/10/Ko der farbe gefallen | wenns startatout war und nicht
            // 		 * ausgetauscht ist)
            // 		 *
            // 		 * AS
            // 		 *
            // 		 * 10er(wenn as der farbe gefallen)
            // 		 *
            // 		 * Ko(wenn as/10 der farbe gefallen)
            // 		 *
            // 		 * Da(wenn as/10/Ko der farbe gefallen)
            // 		 *
            // 		 * BUB(wenn as/10/Ko/Da der farbe gefallen)
            // 		 *
            // 		 * BUB
            // 		 *
            // 		 * Da
            // 		 *
            // 		 * Ko
            // 		 *
            // 		 * 10er
            // if (schnapsen.cardIsInCardList(e.h, schnapsen.card(schnapsen.color(e.at), schnapsen.As))) { // RULE 14: Atout AS
            if ((new BotCard(state.atout.col, BotVal.As)).isPartOf(state.hand)) {// RULE 14: Atout AS
                return new BotAnswer(BotZugType.ausspielen, new BotCard(state.atout.col, BotVal.As), "AZ0 AtAs_")
            }

            if ((l20Dames.length > 0) && (l20Dames[0].col == state.atout.col)) { // RULE 2: DAME of 40er
                return new BotAnswer(BotZugType.ausspielen, l20Dames[0], "AZ1 40Da_")
            }

            if (l20Dames.length > 0) { // RULE 2: DAME of 20er
                return new BotAnswer(BotZugType.ausspielen, l20Dames[0], "AZ2 20Da_")
            }

            let ck = GamePlayBot.ValueIsInHand(state.hand, BotVal.As);
            if (ck.length > 0) { // RULE 4: AS, no Atout
                return new BotAnswer(BotZugType.ausspielen, ck[0], "AZ3 AsnAt")
            }

            if ((new BotCard(state.atout.col, BotVal.Zehn)).isPartOf(state.hand)) { // RULE 5: Atout 10er
                return new BotAnswer(BotZugType.ausspielen, new BotCard(state.atout.col, BotVal.Zehn), "AZ4 At10_")
            }

            ck = GamePlayBot.ValueIsInHand(state.hand, BotVal.Zehn)
            if (ck.length > 0) { // RULE 6: 10er, no Atout
                return new BotAnswer(BotZugType.ausspielen, ck[0], "AZ5 10nAt")
            }
            console.log('GamePlugSessionHookBot botausspielen zw', 20);

            if ((new BotCard(state.atout.col, BotVal.Koenig)).isPartOf(state.hand)) {// RULE 7: Atout KOENIG
                // responder(null, "ausspielen", {
                //     c: schnapsen.card(schnapsen.color(e.at), schnapsen.Koenig)
                // }, "AZ6 AtKo_");
                return new BotAnswer(BotZugType.ausspielen, new BotCard(state.atout.col, BotVal.Koenig), "AZ6 AtKo_")
            }

            ck = GamePlayBot.ValueIsInHand(state.hand, BotVal.Koenig);
            if (ck.length > 0) { // // RULE 8: KOENIG, no Atout
                return new BotAnswer(BotZugType.ausspielen, ck[0], "AZ7  KonAt")
            }

            if ((new BotCard(state.atout.col, BotVal.Dame)).isPartOf(state.hand)) { // RULE 9: Atout DAME
                return new BotAnswer(BotZugType.ausspielen, new BotCard(state.atout.col, BotVal.Dame), "AZ8 AtDa_")
            }

            ck = GamePlayBot.ValueIsInHand(state.hand, BotVal.Dame);
            if (ck.length > 0) { // RULE 10: DAME, no Atout
                return new BotAnswer(BotZugType.ausspielen, ck[0], "AZ9 DanAt")
            }

            if ((new BotCard(state.atout.col, BotVal.Bub)).isPartOf(state.hand)) { // RULE 9: Atout DAME
                return new BotAnswer(BotZugType.ausspielen, new BotCard(state.atout.col, BotVal.Bub), "AZa AtBu_")
            }

            ck = GamePlayBot.ValueIsInHand(state.hand, BotVal.Bub);
            if (ck.length > 0) { // RULE 12: BUB, no Atout
                return new BotAnswer(BotZugType.ausspielen, ck[0], "AZb BunAt")
            }
            console.log("AZ6 *");
            return new BotAnswer(BotZugType.ausspielen, undefined, "AZ6 *")
        }
    }




    static botstechen(state: BotState, brain: BotBrain): BotAnswer {//[BotZugType, Card?, string?] {
        try {
            //logger.trace("botstechen",e);
            // fixme, always take the lowest card of possible, if it does not stich

            if (state.table.length == 0) {
                console.log("botstechen but table was empty");
                return new BotAnswer(BotZugType.ausspielen, undefined, "S00000")
            }
            var t0 = state.table[0];
            if (state.zwang) { // ZWANG
                // if (timeoutRatio > penaltyTimeoutRatio) { // random card if penalty is high
                //     var ck = this._WithinZwang(e.h, t0, e.at);
                //     if (ck.length > 0) {
                //         responder(null, "stechen", {
                //             c: ck[Math.floor(Math.random() * ck.length)]
                //         }, "SZ10 rancard");
                //         return;
                //     }
                // }
                // RULE 1: Same Colour, No Atout, Higher, but dont break apart 20er
                let ck = this._SameColIsNoAtAndHigherCare20break(t0, state.hand, t0, state.atout, state.zwang);
                if (ck.length > 0) {
                    // responder(null, "stechen", {
                    //     c: ck[0]
                    // }, "SZ0 nACogt");
                    return new BotAnswer(BotZugType.stechen, ck[0], "SZ0 nACogt")
                }
                // RULE 2: 10 or as we take with atout but take care if is member of 20er
                // ac, ahnd, atbl, aat, azw
                ck = this.Is10orAsNoAtWeHaveAtoutCare20(t0, state.hand, t0, state.atout, state.zwang);
                if (ck.length > 0) {
                    // responder(null, "stechen", {
                    //     c: ck[0]
                    // }, "SZ1 At/Ac2");
                    return new BotAnswer(BotZugType.stechen, ck[0], "SZ1 At/Ac2")
                }
                // RULE 3: Atout on the table, we take lowest non atout but take care of 20er have at
                ck = this._IsAtWeTakeNoAtCare20(t0, state.hand, t0, state.atout, state.zwang);
                if (ck.length > 0) {
                    // responder(null, "stechen", {
                    //     c: ck[0]
                    // }, "SZ2 AtnA2c"); // zwangerror sometimes
                    return new BotAnswer(BotZugType.stechen, ck[0], "SZ2 AtnA2c")
                }
                // RULE 4: Atout on the table, we take no atout but take care of 20er no at in hand
                ck = this._NotOfColourAndCare20(state.atout, state.hand, t0, state.atout, state.zwang);
                if (ck.length > 0) {
                    // responder(null, "stechen", {
                    //     c: ck[0]
                    // }, "SZ3 nAnA2c");
                    return new BotAnswer(BotZugType.stechen, ck[0], "SZ3 nAnA2c")
                }
                // RULE 5: lowest card within zwangrulez
                ck = this._WithinZwang(state.hand, t0, state.atout);
                if (ck.length > 0) {
                    // responder(null, "stechen", {
                    //     c: ck[0]
                    // }, "SZ4 loval_");
                    return new BotAnswer(BotZugType.stechen, ck[0], "SZ4 loval_")
                }
                console.log("SZ5 * zw");
                return new BotAnswer(BotZugType.stechen, undefined, "SZ5 * zw")

            } else { // NO ZWANG
                // if (timeoutRatio > penaltyTimeoutRatio) { // random card if penalty is high
                //     responder(null, "stechen", {
                //         c: e.h[Math.floor(Math.random() * e.h.length)]
                //     }, "SN10 rancard");
                //     return;
                // }
                // RULE 1: Same Colour, No Atout, Higher, but dont break apart 20er
                var ck = this._SameColIsNoAtAndHigherCare20break(t0, state.hand, t0, state.atout, state.zwang);
                if (ck.length > 0) {
                    // responder(null, "stechen", {
                    //     c: ck[0]
                    // }, "SN0 nACogt");
                    return new BotAnswer(BotZugType.stechen, ck[0], "SN0 nACogt")
                }
                // RULE 2: 10 or as we take with atout but take care if is member of 20er
                ck = this.Is10orAsNoAtWeHaveAtoutCare20(t0, state.hand, t0, state.atout, state.zwang);
                if (ck.length > 0) {
                    // responder(null, "stechen", {
                    //     c: ck[0]
                    // }, "SN1 A1/A2c");
                    return new BotAnswer(BotZugType.stechen, ck[0], "SN1 A1/A2c")
                }
                ck = this._IsAtWeTakeNoAtCare20(t0, state.hand, t0, state.atout, state.zwang);
                if (ck.length > 0) { // RULE 3: Atout on the table, we take lowest non atout but take care of 20er
                    // responder(null, "stechen", {
                    //     c: ck[0]
                    // }, "SN2 AtnA2c");
                    return new BotAnswer(BotZugType.stechen, ck[0], "SN2 AtnA2c")
                }
                ck = this._AllSorted(this._NotOfColourAndCare20(state.atout, state.hand, t0, state.atout, state.zwang));
                if (ck.length > 0) { // RULE 4: Lowest possible take care of 20er
                    // responder(null, "stechen", {
                    //     c: ck[0]
                    // }, "SN3 LoVa2c");
                    return new BotAnswer(BotZugType.stechen, ck[0], "SN3 LoVa2c")
                }
                ck = this._AllSorted(state.hand);
                if (ck.length > 0) { // RULE 5: Lowest possible card
                    // responder(null, "stechen", {
                    //     c: ck[0]
                    // }, "SN4 loval_");
                    return new BotAnswer(BotZugType.stechen, ck[0], "SN4 loval_")
                }
                console.log("stechen DID NOT FIND A CARD no zw");
            }
        } catch (err) {
            console.error("botstechen", err);
        }
        return new BotAnswer(BotZugType.stechen, undefined, "Undef *")
    }


    static ValueIsInHand(ahand: BotCard[], val: BotVal): BotCard[] { // ######## toto testing
        // var ret = [];
        // for (var i = 0; i < ahand.length; i++) {
        //     var ci = ahand[i];
        //     if (schnapsen.value(ci) == schnapsen.value(v)) {
        //         ret.push(ci);
        //     }
        // }
        return ahand.filter((c) => (c.val == val));
    }


    static wasDropped(ac: BotCard, state: BotState): boolean {// ######## todo testing
        // return (state.hisStiche.has(ac.col, ac.val) || state.myStiche.has(ac.col, ac.val));
        return (ac.isPartOf(state.hisStiche) || ac.isPartOf(state.myStiche));
    }

    static partner20(ac: BotCard): BotCard {// ######## toto testing
        return new BotCard(ac.col, ac.val == BotVal.Dame ? BotVal.Koenig : BotVal.Dame);
    }

    static _ValueIsInHandNotOfColourAnd20PartnerWasDropped(val: BotVal, col: BotCol, hand: BotCard[], state: BotState): BotCard[] {// ######## toto testing
        // var res = [];
        var li = GamePlayBot.ValueIsInHandNotOfColour(hand, val, col);
        // const that = this;
        return li.filter((c) => (GamePlayBot.wasDropped(GamePlayBot.partner20(new BotCard(col, val)), state)));
        // for (var i = li.length - 1; i >= 0; i--) {
        //     var cl = li[i];
        //     if (this.wasDropped(this.partner20(cl), g1, g2)) {
        //         res.push(cl);
        //     }
        // }
        // return res;
    }

    static sumPossiblePoints(al20: BotCard[], ahnd: BotCard[], aat: BotCard): number {// ######## toto testing
        var res = 0;
        //let dames = al20.filter((c) => (c.val == BotVal.Dame));
        for (let dame of al20) {
            res += 20;
            if (dame.col == aat.col) {
                res += 20; // cumulate 20er-points
            }
        }
        for (let crd of ahnd) {
            res += 20;
            if (crd.val === BotVal.As) { // 11pts AS
                res += 11;//schnapsen.cardvalue(schnapsen.As);
            } else if (crd.val === BotVal.Zehn) { // 10pts 10er
                res += 10;//schnapsen.cardvalue(schnapsen.Zen);
            }
        }
        // for (var c = 0; c < al20.length; c++) {
        //     var d20 = al20[c];
        //     res += 20; // cumulate 20er-points
        //     if (schnapsen.color(d20) === schnapsen.color(aat)) {
        //         res += 20;
        //     }
        // }

        // for (var c = 0; c < ahnd.length; c++) {
        //     var crd = ahnd[c];
        //     if (schnapsen.color(crd) === schnapsen.As) { // 11pts AS
        //         res += schnapsen.cardvalue(schnapsen.As);
        //     } else if (schnapsen.color(crd) == schnapsen.Zehn) { // 10pts 10er
        //         res += schnapsen.cardvalue(schnapsen.Zen);
        //     }
        // }
        res += res / 5;
        return res;
    }









    // TODO: dont use atout bube, better try austausch
    // TODO: ismemberof20 should check in dropped cards if 20er partner was dropped
    static _NotOfColourAndCare20(ac: BotCard, ahnd: BotCard[], atbl: BotCard, aat: BotCard, azw: boolean): BotCard[] {// ######## todo testing
        var res: BotCard[] = [];
        var li = this.differentColor(ahnd, ac.col);
        for (var i = li.length - 1; i >= 0; i--) {
            var cl = li[i];
            if ((!GamePlayBot.ismemberof20er(ahnd, cl)) && ((azw && (GamePlayBot.cardIsZwangOk(ahnd, atbl, aat, cl) === true)) || (!azw))) {
                res.push(cl);
            }
        }
        return this._AllSorted(res);
    }









    static _SameColIsNoAtAndHigherCare20break(ac: BotCard, ahnd: BotCard[], atbl: BotCard, aat: BotCard, azw: boolean): BotCard[] {// ######## toto testing
        var res: BotCard[] = [];
        // TODO, only check 20er breakup if he ! zugedreht, as we only get 20er points in this case
        var li = this.sameColorAndHigher(ahnd, ac);
        for (var i = li.length - 1; i >= 0; i--) {
            var cl = li[i];
            if ((cl.col, aat.col) && ((azw && (GamePlayBot.cardIsZwangOk(ahnd, atbl, aat, cl) === true)) || (!azw))) {
                if (!GamePlayBot.ismemberof20er(ahnd, cl)) {
                    res.push(cl);
                }
            }
        }
        return res;
    }

    static Is10orAsNoAtWeHaveAtoutCare20(ac: BotCard, ahnd: BotCard[], atbl: BotCard, aat: BotCard, azw: boolean): BotCard[] {// ######## toto testing
        var res: BotCard[] = [];
        if ((ac.col != aat.col) && (ac.val >= BotVal.Zehn) && (ac.val <= BotVal.As)) {
            var li = this.listSameColor(ahnd, aat.col);
            for (var i = li.length - 1; i >= 0; i--) {
                var cl = li[i];
                if ((!GamePlayBot.ismemberof20er(ahnd, cl) && ((!azw) || (azw && (GamePlayBot.cardIsZwangOk(ahnd, atbl, aat, cl) === true))))) {
                    res.push(cl);
                }
            }
        }
        return res;
    }

    static _IsAtWeTakeNoAtCare20(ac: BotCard, ahnd: BotCard[], atbl: BotCard, aat: BotCard, azw: boolean): BotCard[] {// ######## todo testing
        var res: BotCard[] = [];
        if (ac.col === aat.col) {
            var li = this.differentColor(ahnd, ac.col);
            for (var i = li.length - 1; i >= 0; i--) {
                var cl = li[i];
                if (((cl.col != aat.col) && (!GamePlayBot.ismemberof20er(ahnd, cl))) && ((azw && (GamePlayBot.cardIsZwangOk(ahnd, atbl, aat, cl) === true)) || (!azw))) {
                    res.push(cl);
                }
            }
        }
        return GamePlayBot._AllSorted(res);
    }



    // DameOf40or20(al20, aat) { // ######## toto testing
    //     // returns 40er DAME, or first 20er DAME if no 40er exists
    //     if (al20.length > 0) {
    //         if (schnapsen.color(al20[0]) === schnapsen.color(aat)) { // first 20er is 40er
    //             return al20[0];
    //         } else if (al20.length > 1 && schnapsen.color(al20[1]) === schnapsen.color(aat)) { // second 20er is 40er
    //             return al20[1];
    //         } else { // no 40er, take first 20er
    //             return al20[0];
    //         }
    //     } else {
    //         return null;
    //     }
    // }

    static sameColorAndHigher(ahand: BotCard[], ac: BotCard): BotCard[] { // ######## todo testing
        // var ret = [];
        // for (var i = 0; i < ahand.length; i++) {
        //     var cc = ahand[i];
        //     if ((schnapsen.color(cc) == schnapsen.color(ac)) && (schnapsen.value(cc) > schnapsen.value(ac))) {
        //         ret.push(cc);
        //     }
        // }
        return ahand.filter((c) => ((ac.col == c.col) && (c.val > ac.val)));
    }

    static cardIsZwangOk(ahand: BotCard[], table0: BotCard, atout: BotCard, acard: BotCard): boolean { /////////////// to test
        if (((table0.col == acard.col) && (table0.val == acard.val)) || !(new BotCard(acard.col, acard.val)).isPartOf(ahand)) { // illegal query
            // card or table0 can be atout, dont check
            // if (logfunction) {
            //     logfunction("cardIsZwangOk illegal cards", table0 == acard, !(this.cardIsInCardList(ahand, acard)));
            // }
            console.log("cardIsZwangOk illegal cards", (table0.col == acard.col) && (table0.val == acard.val), (new BotCard(acard.col, acard.val).isPartOf(ahand)));
            return null;
        }
        let handWithoutThis = [...ahand] //CardList.copy(ahand);
        handWithoutThis = handWithoutThis.filter(c => !c.equals(acard));

        if (acard.col == table0.col) { // farbzwang fulfilled
            if (acard.val > table0.val) { // farbzwang && stichzwang fulfilled
                return true;
            } else if (acard.val < table0.val) { // stichzwang only filfilled if there is no higher by that color
                if (this.cardListOfSameColorAndHigherThanThis(handWithoutThis, table0).length === 0) {
                    return true;
                } else {
                    return false;
                }
            } else { // impossible, same color and same val
                // if (logfunction) {
                //     logfunction("cardIsZwangOk same color and same value", this.value(acard) > this.value(v), this.value(acard) < this.value(v));
                // }
                console.log("cardIsZwangOk same color and same value");
                return null;
            }

        } else {
            let samec = this.listSameColor(handWithoutThis, table0.col);
            if (samec.length === 0) { // no card of that color in hand
                if (acard.col == atout.col) { // card is atout, implies that table is no atout
                    return true; // atout drawn after non atout
                } else if (this.listSameColor(handWithoutThis, atout.col).length === 0) {
                    return true; // card is no atout, and no atout in hand (farbzwang fulfilled), will never be a stich, as it is different color and no atout, stichzwang fulfilled
                } else if (this.listSameColor(handWithoutThis, atout.col).length > 0) { // card is no atout, i got atouts, stichzwang not filfilled
                    return false;
                } else {
                    // if (logfunction) {
                    //     logfunction("cardIsZwangOk same color and same value", this.filterByColor(hand, table0).length, this.filterByColor(hand, atout).length);
                    // }
                    console.log("cardIsZwangOk same color and same value 2");
                    return null; // impossible
                }
            } else {
                return false; // farbzwang ! fulfilled
            }
        }
        // if (logfunction) {
        //     console.log("cardIsZwangOk ??? ", sameColor(acard, table0));
        // }
        // return null;
    }

    static listSameColor(list: BotCard[], col: BotCol): BotCard[] {
        return list.filter((c) => (c.col == col));
    };

    static cardListOfSameColorAndHigherThanThis(inhand: BotCard[], avalueorcard: BotCard): BotCard[] { /////////////// to test
        // let handOfColor = inhand.cards.filter((c)=>(avalueorcard.col==c.col));
        // var ret = [];
        // for (var i = 0; i < hand.length; i++) {
        //     var c = hand[i];
        //     if (this.value(c) > this.value(avalueorcard)) {
        //         ret.push(c);
        //     }
        // }
        return inhand.filter((c) => ((avalueorcard.col == c.col) && (c.val > avalueorcard.val)));
    }

    static _WithinZwang(li: BotCard[], atbl: BotCard, aat: BotCard): BotCard[] { // ######## toto testing
        // var res = [],
        //     i, cl;
        // for (i = 0; i < li.length; i++) {
        //     cl = li[i];
        //     if (schnapsen.cardIsZwangOk(li, atbl, aat, cl) === true) {
        //         res.push(cl);
        //     }
        // }
        return this._AllSorted(li.filter((c) => (this.cardIsZwangOk(li, atbl, aat, c))));
    }

    static _AllSorted(alist: BotCard[]): BotCard[] { // ######## toto testing
        var ret: BotCard[] = [];
        for (var i = 0; i < alist.length; i++) {
            var cc = alist[i];
            if (cc.val == BotVal.Bub) {
                ret.push(cc);
            }
        }
        for (var i = 0; i < alist.length; i++) {
            var cc = alist[i];
            if (cc.val == BotVal.Dame) {
                ret.push(cc);
            }
        }
        for (var i = 0; i < alist.length; i++) {
            var cc = alist[i];
            if (cc.val == BotVal.Koenig) {
                ret.push(cc);
            }
        }
        for (var i = 0; i < alist.length; i++) {
            var cc = alist[i];
            if (cc.val == BotVal.Zehn) {
                ret.push(cc);
            }
        }
        for (var i = 0; i < alist.length; i++) {
            var cc = alist[i];
            if (cc.val == BotVal.As) {
                ret.push(cc);
            }
        }
        return ret;
    }



    static ValueIsInHandNotOfColour(hand: BotCard[], v: BotVal, col: BotCol): BotCard[] { // ######## toto testing
        // var ret = [];
        // for (var i = 0; i < hand.length; i++) {
        //     var cc = hand[i];
        //     if (schnapsen.value(cc) === schnapsen.value(v) && schnapsen.color(cc) !== schnapsen.color(c)) {
        //         ret.push(cc);
        //     }
        // }
        return hand.filter((c) => ((c.val === v) && (c.col !== col)));
    }

    static list20(ahnd: BotCard[], aat: BotCard): BotCard[] { // ######## toto testing
        let l40 = ahnd.filter((c) => (((c.val == BotVal.Koenig) && (new BotCard(c.col, BotVal.Dame)).isPartOf(ahnd) || (c.val == BotVal.Dame) && (new BotCard(c.col, BotVal.Koenig)).isPartOf(ahnd) && c.col == aat.col)))
        let l20 = ahnd.filter((c) => (((c.val == BotVal.Koenig) && (new BotCard(c.col, BotVal.Dame)).isPartOf(ahnd) || (c.val == BotVal.Dame) && (new BotCard(c.col, BotVal.Koenig)).isPartOf(ahnd) && c.col != aat.col)))

        // for (var c = 0; c < ahnd.length; c++) {
        //     var it = ahnd[c];
        //     if (schnapsen.value(it) === schnapsen.Dame && schnapsen.cardIsInCardList(ahnd, schnapsen.card(schnapsen.color(it), schnapsen.Koenig)) && (schnapsen.color(it) === schnapsen.color(aat))) {
        //         l20.push(it);
        //     }
        // }
        // for (var c = 0; c < ahnd.length; c++) {
        //     var it = ahnd[c];
        //     if (schnapsen.value(it) === schnapsen.Dame && schnapsen.cardIsInCardList(ahnd, schnapsen.card(schnapsen.color(it), schnapsen.Koenig)) && (schnapsen.color(it) !== schnapsen.color(aat))) {
        //         l20.push(it);
        //     }
        // }
        return l40.concat(l20)
    }

    static ismemberof20er(alist: BotCard[], ac: BotCard): boolean { // ######## toto testing
        if (!(new BotCard(ac.col, ac.val)).isPartOf(alist)) {
            return false;
        }
        return (
            ((ac.val === BotVal.Dame) && (new BotCard(ac.col, BotVal.Koenig)).isPartOf(alist))
            ||
            ((ac.val === BotVal.Koenig) && (new BotCard(ac.col, BotVal.Dame)).isPartOf(alist))
        )
    }

    static differentColor(ahand: BotCard[], col: BotCol): BotCard[] { // ######## toto testing
        // var ret = [];
        // for (var i = 0; i < ahand.length; i++) {
        //     var cc = ahand[i];
        //     if (schnapsen.color(cc) != schnapsen.color(col)) {
        //         ret.push(cc);
        //     }
        // }
        return ahand.filter((c) => (col != c.col));
    }



    // init(ap) {
    //     const that = this;
    //     logger = ap.logger;
    //     pool = ap.pool;
    //     login = ap.login;
    //     setup = ap.setup;
    //     clientnamespace = ap.clientnamespace;
    //     game = ap.game;
    //     schnapsen = ap.schnapsen;
    //     // testing();
    //     setInterval(function () {
    //         that.maintenance();
    //     }, 60000);
    // }

    // regmod(socket) {
    //     const that = this;

    //     socket.on("botgame", function (data, fnconf) {
    //         //         logger.trace('botgame', data);
    //         if (!common.ckloggedinamdsetlact(socket)) {
    //             logger.error("botgame ! common.loggedin()");
    //             return;
    //         }
    //         if (socket.user.sessiondata.currentTurn) {
    //             fnconf({
    //                 e: "currentTurn"
    //             });
    //             return;
    //         }
    //         if (socket.user.sessiondata.currentGame) {
    //             fnconf({
    //                 e: "currentGame"
    //             });
    //             return;
    //         }
    //         var debugdata = setup.bot.debugGame ? setup.bot.debugData : null;
    //         var bummerl = 7;
    //         //         logger.info("botgame", setup.bot, debugdata);
    //         game.createGame(socket, that.createBotSession(), game.CGameTypeRobot, 0, bummerl, setup.game.zugTimeoutBotGame, debugdata,
    //             function (errcg, rescg) {
    //                 if (errcg) {
    //                     logger.error('robot g end:', errcg);
    //                 }

    //                 logger.trace('robot game ended');
    //             },
    //             function (errst) {
    //                 logger.trace('robot game started', errst);
    //                 if (errst) {
    //                     fnconf(errst);
    //                 }
    //             }
    //         );
    //     });
    // };


    // gameOfBot() {
    // }

    // timeoutRatioOfUser(u) {
    //     // 	console.log("timeoutRatioOfUser",u.sessiondata);
    //     return (u && u.sessiondata) ? u.sessiondata.timeouts / (u.sessiondata.games > 0 ? u.sessiondata.games : 1) : 0;
    // }

    // createBotSession(au) {
    //     const that = this;

    //     var botuid = lastBotUid++;
    //     logger.info("createBotSession", au ? au.name : "noname", botuid);
    //     var ret = {
    //         timeoutRatio: that.timeoutRatioOfUser(au),
    //         emit: function (m, e) {
    //             //logger.info("BotSession emit", m);
    //             var botpl = that.getBot(botuid);
    //             botpl.lastAct = new Date();
    //             var g = (botpl && botpl.user && botpl.user.sessiondata && botpl.user.sessiondata.currentGame) ? botpl.user.sessiondata.currentGame : null;
    //             var hisstiche = g != null ? g.cards.states[(botpl.user.botuid === g.players[0].botuid) ? 1 : (botpl.user.botuid === g.players[1].botuid) ? 0 : -1].stiche : [];
    //             that.botAction(botpl.user, m, e, hisstiche, that.timeoutRatio);
    //         },
    //         user: {
    //             uid: au ? au.uid : 0,
    //             botuid: botuid,
    //             name: au ? au.name : setup.bot.name,
    //             sessiondata: game.createSessionData(),
    //             isBot: true
    //         },
    //         gamedata: {
    //             stiche: [],
    //             hisstiche: []
    //         },
    //         lastAct: new Date()
    //     };
    //     bots.push(ret);
    //     return ret;
    // };


    // getBot(botuid) {
    //     for (var i = 0; i < bots.length; i++) {
    //         var b = bots[i];
    //         if (b.user.botuid === botuid) {
    //             return b;
    //         }
    //     }
    //     return null;
    // }

    // removeBot(botuid) {
    //     for (var i = bots.length - 1; i >= 0; i--) {
    //         var b = bots[i];
    //         if (b.user.uid === botuid) {
    //             common.arrayRemoveIndex(i);
    //         }
    //     }
    //     return null;
    // }

    // disconnected = function (socket) { }





    // maintenance() {
    //     var lately = new Date();
    //     lately.setSeconds(lately.getSeconds() - 3600);
    //     for (var i = bots.length - 1; i >= 0; i--) {
    //         var b = bots[i];
    //         if (b.lastAct < lately) {
    //             common.arrayRemoveIndex(i);
    //         }
    //     }
    // }







    // // ########################## BOT BOT #######################
    // botAction(pl, m, e, hisstiche, timeoutRatio) {
    //     if (!(e && e.gc)) {
    //         logger.info("botAction ! (e && e.gc)", m);
    //         return;
    //     }
    //     console.log("botAction timeoutRatio", timeoutRatio);
    //     switch (m) {
    //         case "gamesetup":
    //         case "ausspielen":
    //         case "austauschen":
    //         case "zudrehen":
    //             if (!e.isd) {
    //                 return;
    //             }
    //             botausspielen(e, hisstiche, timeoutRatio, function (errauss, me, mr, minfo) {
    //                 if (errauss) {
    //                     logger.error("botAction botausspielen:", errauss, me, mr, minfo, e);
    //                     return;
    //                 }
    //                 //logger.info("botAction", pl.name, m, zuglog(e), minfo);
    //                 mr.g = pl.sessiondata.currentGame.uid;
    //                 botresponse(errauss, pl, me, mr, minfo, hisstiche);
    //             });
    //             break;
    //         case "stechen":
    //             if (!e.isd) {
    //                 logger.error("botAction ! isd", m);
    //             }
    //             botstechen(e, hisstiche, timeoutRatio, function (errstch, me, mr, minfo) {
    //                 if (errstch) {
    //                     logger.error("botAction botstechen:", errstch, me, mr, minfo, e);
    //                     return;
    //                 }
    //                 //logger.info("botAction", pl.name, m, zuglog(e), minfo);
    //                 mr.g = pl.sessiondata.currentGame.uid;
    //                 botresponse(errstch, pl, me, mr, minfo, hisstiche);
    //             });
    //             break;
    //         case "gameend":
    //             break;
    //         case "bummerlend":
    //             removeBot(pl.botuid);
    //             break;
    //         case "gspieltaus":
    //         case "warte":
    //             break;
    //         default:
    //             logger.error("botAction bot undis", m);
    //     }
    // }

    // zuglog(e) {
    //     return "h:" + schnapsen.cardsR(e.h) + " t:" + schnapsen.cardsR(e.t) + " a:" + schnapsen.cardR(e.at) + " z:" + e.zw + " d:" + e.d;
    // }



    // botresponse(errr, user, m, e, info, hisstiche) {
    //     logger.info("botresponse", info);
    //     switch (m) {
    //         case "austauschen":
    //             setTimeout(function () {
    //                 schnapsen.austauschen(user, e, function (conf) {
    //                     //logger.trace("botresponse",m,conf);
    //                     if (conf.isd) {
    //                         botAction(user, "ausspielen", conf, hisstiche);
    //                     }
    //                 });
    //             }, botResponseTime());
    //             break;
    //         case "zudrehen":
    //             setTimeout(function () {
    //                 schnapsen.zudrehen(user, e, function (conf) {
    //                     //logger.trace("botresponse",m,conf);
    //                     if (conf.isd) {
    //                         botAction(user, "ausspielen", conf, hisstiche);
    //                     }
    //                 });
    //             }, botResponseTime());
    //             break;
    //         case "ausspielen":
    //             setTimeout(function () {
    //                 schnapsen.ausspielen(user, e, function (conf) {
    //                     logger.trace("botresponse", m, conf);
    //                 });
    //             }, botResponseTime());
    //             break;
    //         case "stechen":
    //             setTimeout(function () {
    //                 schnapsen.stechen(user, e, function (conf) {
    //                     logger.trace("botresponse", m, conf);
    //                 });
    //             }, botResponseTime());
    //             break;

    //         default:
    //             logger.error('botresponse bot undis', m);
    //     }
    // }

    // botResponseTime() {
    //     return botTiming;
    // }




    // ######################################### TEST TEST TEST #############################
    // testing() {
    //testContains();
    //testDifferentColor();
    //testIsmemberof20er();
    //testList20();
    //testValueIsInHandNotOfColour();
    //testValueIsInHand();
    //testWithinZwang();
    //testSameColorAndHigher();
    //testDameOf40or20();
    //testIs10orAsNoAtWeHaveAtoutCare20();
    // }

    // testIs10orAsNoAtWeHaveAtoutCare20() {
    //     logger.info("testIs10orAsNoAtWeHaveAtoutCare20");
    //     var t0 = cardFromR("ka");
    //     var at = cardFromR("hb");
    //     var h = [cardFromR("hk"), cardFromR("hd"), cardFromR("hz"), cardFromR("ha"), cardFromR("ka")];
    //     var ck = Is10orAsNoAtWeHaveAtoutCare20(t0, h, t0, at, false);
    //     logger.info("Is10orAsNoAtWeHaveAtoutCare20", schnapsen.cardsR(h), schnapsen.cardR(t0), schnapsen.cardR(at), "->" + schnapsen.cardsR(ck));
    // }


    // testDifferentColor() {
    //     /*
    //     [2015-07-24 09:08:53.641] [INFO] sch - differentColor hb kb,kd,kk,kz,ka,pb,pd,pk,pz,pa,tb,td,tk,tz,ta
    //     [2015-07-24 09:08:53.642] [INFO] sch - differentColor tk hb,hd,hk,hz,ha,kb,kd,kk,kz,ka,pb,pd,pk,pz,pa
    //     */
    //     logger.info("differentColor");
    //     var tcrd = schnapsen.card(schnapsen.Herz, schnapsen.Bub);
    //     var dico = this.differentColor(schnapsen.gatAllCardsCp(), tcrd);
    //     logger.info("differentColor", schnapsen.cardR(tcrd), schnapsen.cardsR(dico));
    //     tcrd = schnapsen.card(schnapsen.Treff, schnapsen.Koenig);
    //     dico = this.differentColor(schnapsen.gatAllCardsCp(), tcrd);
    //     logger.info("differentColor", schnapsen.cardR(tcrd), schnapsen.cardsR(dico));
    // }

    // testIsmemberof20er() {
    //     /*
    //     [2015-07-24 09:18:15.912] [INFO] sch - ismemberof20er
    //     [2015-07-24 09:18:15.913] [INFO] sch - ismemberof20er hb hd,ha,hk,pk,td null
    //     [2015-07-24 09:18:15.913] [INFO] sch - ismemberof20er ha hd,ha,hk,pk,td false
    //     [2015-07-24 09:18:15.914] [INFO] sch - ismemberof20er hd hd,ha,hk,pk,td true
    //     */
    //     logger.info("ismemberof20er");
    //     var h = [schnapsen.card(schnapsen.Herz, schnapsen.Dame), schnapsen.card(schnapsen.Herz, schnapsen.As), schnapsen.card(schnapsen.Herz, schnapsen.Koenig), schnapsen.card(schnapsen.Pik, schnapsen.Koenig), schnapsen.card(schnapsen.Treff, schnapsen.Dame)];
    //     var c = schnapsen.card(schnapsen.Herz, schnapsen.Bub);
    //     logger.info("ismemberof20er", schnapsen.cardR(c), schnapsen.cardsR(h), this.ismemberof20er(h, c));
    //     c = schnapsen.card(schnapsen.Herz, schnapsen.As);
    //     logger.info("ismemberof20er", schnapsen.cardR(c), schnapsen.cardsR(h), this.ismemberof20er(h, c));
    //     c = schnapsen.card(schnapsen.Herz, schnapsen.Dame);
    //     logger.info("ismemberof20er", schnapsen.cardR(c), schnapsen.cardsR(h), this.ismemberof20er(h, c));
    // }

    // testList20() {
    //     /*
    //     [2015-07-24 09:32:19.816] [INFO] sch - list20
    //     [2015-07-24 09:32:19.817] [INFO] sch - list20 hd,ha,hk,pk,td,tk tz l20:td,hd
    //     */
    //     logger.info("list20");
    //     var h = [schnapsen.card(schnapsen.Herz, schnapsen.Dame), schnapsen.card(schnapsen.Herz, schnapsen.As), schnapsen.card(schnapsen.Herz, schnapsen.Koenig), schnapsen.card(schnapsen.Pik, schnapsen.Koenig), schnapsen.card(schnapsen.Treff, schnapsen.Dame), schnapsen.card(schnapsen.Treff, schnapsen.Koenig)];
    //     var at = schnapsen.card(schnapsen.Treff, schnapsen.Zehn);
    //     logger.info("list20", schnapsen.cardsR(h), schnapsen.cardR(at), "l20:" + schnapsen.cardsR(this.list20(h, at)));

    // }

    // testValueIsInHand() {
    //     /*
    //     [2015-07-24 09:32:19.816] [INFO] sch - list20
    //     [2015-07-24 09:32:19.817] [INFO] sch - list20 hd,ha,hk,pk,td,tk tz l20:td,hd
    //     */
    //     logger.info("ValueIsInHand");
    //     var h = [schnapsen.card(schnapsen.Herz, schnapsen.Dame), schnapsen.card(schnapsen.Herz, schnapsen.As), schnapsen.card(schnapsen.Herz, schnapsen.Koenig), schnapsen.card(schnapsen.Pik, schnapsen.Koenig), schnapsen.card(schnapsen.Treff, schnapsen.Dame), schnapsen.card(schnapsen.Treff, schnapsen.Koenig)];
    //     var val = schnapsen.Dame;
    //     logger.info("ValueIsInHand", schnapsen.cardsR(h), schnapsen.valueR(val), schnapsen.cardsR(GamePlayBot.ValueIsInHand(h, val)));

    // }

    // testValueIsInHandNotOfColour() {
    //     /*
    //     [2015-07-24 09:32:19.817] [INFO] sch - list20 hd,ha,hk,pk,td,tk tz l20:td,hd
    //     */
    //     logger.info("valueIsInHandNotOfColour");
    //     var h = [schnapsen.card(schnapsen.Herz, schnapsen.Dame), schnapsen.card(schnapsen.Herz, schnapsen.As), schnapsen.card(schnapsen.Herz, schnapsen.Koenig), schnapsen.card(schnapsen.Pik, schnapsen.Koenig), schnapsen.card(schnapsen.Treff, schnapsen.Dame), schnapsen.card(schnapsen.Treff, schnapsen.Koenig)];
    //     var val = schnapsen.Dame;
    //     var col = schnapsen.Herz;
    //     logger.info("valueIsInHandNotOfColour", schnapsen.cardsR(h), schnapsen.valueR(val), schnapsen.colorR(col), schnapsen.cardsR(GamePlayBot.ValueIsInHandNotOfColour(h, val, col)));

    // }



    // testWithinZwang() {
    //     /*
    //     [2015-07-24 18:01:30.944] [INFO] sch - _WithinZwang hd,hk,ha
    //     [2015-07-24 18:01:30.946] [INFO] sch - _WithinZwang kd,kk,kz,ka
    //     */
    //     logger.info("_WithinZwang");
    //     var h = [schnapsen.card(schnapsen.Herz, schnapsen.Dame), schnapsen.card(schnapsen.Herz, schnapsen.As), schnapsen.card(schnapsen.Herz, schnapsen.Koenig), schnapsen.card(schnapsen.Pik, schnapsen.Koenig), schnapsen.card(schnapsen.Treff, schnapsen.Dame), schnapsen.card(schnapsen.Treff, schnapsen.Koenig)];
    //     var ta = schnapsen.card(schnapsen.Karo, schnapsen.Bub);
    //     var at = schnapsen.card(schnapsen.Herz, schnapsen.Bub);
    //     logger.info("_WithinZwang", schnapsen.cardsR(this._WithinZwang(h, ta, at)));
    //     logger.info("_WithinZwang", schnapsen.cardsR(this._WithinZwang(schnapsen.gatAllCardsCp(), ta, at)));
    // }

    // testSameColorAndHigher() {
    //     /*
    //     [2015-07-24 18:01:30.944] [INFO] sch - _WithinZwang hd,hk,ha
    //     [2015-07-24 18:01:30.946] [INFO] sch - _WithinZwang kd,kk,kz,ka
    //     */
    //     logger.info("sameColorAndHigher");
    //     var c = schnapsen.card(schnapsen.Herz, schnapsen.Dame);
    //     logger.info("sameColorAndHigher", schnapsen.cardR(c), schnapsen.cardsR(this.sameColorAndHigher(schnapsen.gatAllCardsCp(), c)));

    // }

    // testDameOf40or20() {
    //     /*
    //     [2015-07-24 19:19:37.795] [INFO] sch - DameOf40or20 hd,ha,hk,pk,td,tk kb hd
    //     [2015-07-24 19:19:37.796] [INFO] sch - DameOf40or20 hd,ha,hk,pk,td,tk tb td
    //     */
    //     logger.info("DameOf40or20");
    //     var h, at, l20;
    //     h = [schnapsen.card(schnapsen.Herz, schnapsen.Dame), schnapsen.card(schnapsen.Herz, schnapsen.As), schnapsen.card(schnapsen.Herz, schnapsen.Koenig), schnapsen.card(schnapsen.Pik, schnapsen.Koenig), schnapsen.card(schnapsen.Treff, schnapsen.Dame), schnapsen.card(schnapsen.Treff, schnapsen.Koenig)];
    //     at = schnapsen.card(schnapsen.Karo, schnapsen.Bub);
    //     l20 = this.list20(h, at);
    //     logger.info("DameOf40or20", schnapsen.cardsR(h), schnapsen.cardR(at), schnapsen.cardR(this.DameOf40or20(l20, at)));
    //     at = schnapsen.card(schnapsen.Treff, schnapsen.Bub);
    //     l20 = this.list20(h, at);
    //     logger.info("DameOf40or20", schnapsen.cardsR(h), schnapsen.cardR(at), schnapsen.cardR(this.DameOf40or20(l20, at)));
    // }
}

export default GamePlayBot