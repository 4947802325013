class Utils {
    static async timeout(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    static Flip(x: number): number {
        return 1 - x;
    }
    static Square(x: number): number {
        return x * x;
    }
    // https://blog.febucci.com/2018/08/easing-functions/
    public static EaseOut(t: number): number {
        return Utils.Flip(Utils.Square(Utils.Flip(t)));
    }

    public static interpolate(v0: number, v1: number, t0: number, duration: number, tx: number) {
        return v0 + (v1 - v0) * Utils.EaseOut((tx - t0) / duration)
    }
}

export default Utils