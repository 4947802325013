import App from "../app";
import { CMess, SMess } from "../socket";
import { keyable } from "../utils/storage";
import { Bot, RobotStateMessageType } from "./bot";
import BotBase from "./bot-base";



class RoomBot extends BotBase {
    constructor(b: Bot) {
        super(b);
    }
    roomDetails?: SMess
    inviteOrConfirm: ReturnType<typeof setTimeout>

    async dispatch(me: SMess) {
        if (!App.instance.login.user) {
            return
        }
        switch (me.cmd) {
            case 'room':
                // console.log('B**', this.constructor.name, me.cmd, me.list)
                this.roomDetails = me
                if (!this.inviteOrConfirm) {
                    this.inviteOrConfirm = this.scheduleEvent(1500, RobotStateMessageType.InviteOrConfirmRoomGame);
                }
                break;
            case 'gotinvited':
                // console.log('B**', this.constructor.name, me.cmd, me.list)
                if (!this.inviteOrConfirm) {
                    this.inviteOrConfirm = this.scheduleEvent(1500, RobotStateMessageType.InviteOrConfirmRoomGame);
                }
                break;
            case 'bummerstart':
            case 'bummerlend':
                if (this.inviteOrConfirm) {
                    clearTimeout(this.inviteOrConfirm)
                    this.inviteOrConfirm = null
                }
                break
        }
    }

    async stateMessage(am: RobotStateMessageType, d?: any) {
        const app = App.instance
        if (!app.login.user) {
            return
        }
        switch (am) {
            case RobotStateMessageType.InviteOrConfirmRoomGame:
                if (this.bot.setup.loggedin && (!this.bot.setup.game) && this.roomDetails && this.roomDetails.list && this.roomDetails.list.length) {
                    const notMe = this.roomDetails.list.filter(m => m.uid != app.login.user.uid)

                    if (app.invitationsPassive.length) {
                        const passNotMe = notMe.filter(m => !app.invitationsPassive.find(ac => ac.user == m.user)).filter(i => this.roomDetails && this.roomDetails.data.id == i.room)
                        if (passNotMe.length) {
                            const ret = await App.instance.socket.sendRoundtrip(new CMess('invite', { uid: passNotMe[0].uid }));
                            return
                        }
                    }
                    const invActInRoom = app.invitationsActive.filter(i => this.roomDetails && this.roomDetails.data.id == i.room)
                    const notinv = notMe.filter(m => !invActInRoom.find(ac => ac.user == m.user))
                    if (notinv.length) {
                        const ret = await App.instance.socket.sendRoundtrip(new CMess('invite', { uid: notinv[0].uid, room: this.roomDetails ? this.roomDetails.data.id : 'na' }));
                    }
                }
                this.inviteOrConfirm = undefined
                break;
        }
    }
}

export default RoomBot