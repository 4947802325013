import App from "../app";
import { SMess } from "../socket";
import { keyable } from "../utils/storage";
import { Bot, RobotStateMessageType } from "./bot";
import BotBase from "./bot-base";

class RoomsBot extends BotBase {
    constructor(b: Bot) {
        super(b);
    }
    roomsList?: keyable[]
    goToCrowsedRoomTimeout: ReturnType<typeof setTimeout>

    async dispatch(me: SMess) {
        if (!App.instance.login.user) {
            return
        }
        switch (me.cmd) {
            case 'rooms':
                // console.log('B**', this.constructor.name, 'rooms', me.list)
                this.roomsList = me.list
                if (!this.goToCrowsedRoomTimeout) {
                    this.goToCrowsedRoomTimeout = this.scheduleEvent(this.ranT(1000, 1000), RobotStateMessageType.GoToCrowdedRoom);
                }
                break;
        }
    }

    async stateMessage(am: RobotStateMessageType, d?: any) {
        if (!App.instance.login.user) {
            return
        }
        switch (am) {
            // case RobotStateMessageType.LoggedIn:
            //     if (this.bot.setup.playRoomGame == 100) {
            //         this.scheduleEvent(this.ranT(1500, 1000), RobotStateMessageType.GoToRooms);
            //     }
            //     break;
            case RobotStateMessageType.GoToRooms:
                if (this.bot.setup.loggedin && this.bot.setup.game == null && App.instance.menu.state.link != '/game') {
                    App.instance.menu.goWithUrlChange('/rooms')
                }
                break;
            case RobotStateMessageType.GoToCrowdedRoom:
                this.goToCrowsedRoomTimeout = null
                if (this.bot.setup.loggedin && this.bot.setup.game == null && this.roomsList && this.roomsList.length && App.instance.menu.state.link != '/game') {
                    const r = this.roomsList.sort((a, b) => b.mem - a.mem)[0]
                    App.instance.menu.goWithUrlChange(`/room/${r.id}`)
                }
                break;
        }
    }
}

export default RoomsBot