import App from "../app";
import { SMess } from "../socket";
import StateGame, { TextFileds, UpDownDir } from "../state/state.game";
import { CardAni, GameTaskBotTrigger, GameTaskDrawHisAndMyNew, GameTaskMoveCard, GameTaskSetDraggablePlayable, GameTaskSetUpDown, GameTaskShowAtout, GameTaskWait } from "../tasks/tasks.game";
import Bummerl from "./bummerl";
import CardSprite from "./cardsprite";
import Card from "./game.card";
import { Col, Val } from "./game.card.enums";
import CardList from "./game.card.list";


enum ActionType { drawMyCard, drawHisCard, showStack, showAtout, showCommand, moveCard, setHandPlayable, waitTimeout, moveHisCard }

enum ButtonAction { quitGame }

enum CardIndx { st = 0, at = 1, my0 = 2, my1 = 3, my2 = 4, my3 = 5, my4 = 6, h0 = 7, h1 = 8, h2 = 9, h3 = 10, h4 = 11, ms0 = 12, hs0 = 13, t0 = 14, t1 = 15 }

class Game {
    private _id: string
    private round: Bummerl
    private _st: StateGame
    private setup: SMess
    private lMess: SMess
    private _isDD: boolean = false
    private _deckSize: number
    private _hand: CardList
    private _table: CardList
    private _atout: Card
    private _zwang: boolean
    private _zu: boolean
    private _gameContinues: boolean;
    // private _isContinuedGame: boolean = true;
    

    static readonly MinTalonCZumAustauschen: number = 0;
    static readonly MinTalonCZumZudrehen: number = 0;
    static readonly MinStichCZumZudrehen: number = 1; // not used, nur wenn hastgestochenC1 oder C2
    static readonly MaxTalonCZumZudrehen: number = 10;
    static readonly WienerischZudrehen: boolean = false; // not used, only relevant on server
    static readonly myCardindxs: CardIndx[] = [CardIndx.my0, CardIndx.my1, CardIndx.my2, CardIndx.my3, CardIndx.my4]
    static readonly hisCardindxs: CardIndx[] = [CardIndx.h0, CardIndx.h1, CardIndx.h2, CardIndx.h3, CardIndx.h4]

    constructor(id: string, ro: Bummerl, st: StateGame) {
        this._id = id
        this.round = ro
        this._st = st
    }

    id(): string {
        return this._id;
    }
    stage(): StateGame {
        return this._st;
    }
    isdd(): boolean {
        return this._isDD;
    }
    // tick(dt: number) {
    //     this._st.tick(dt)
    // }
    lmess(): SMess {
        return this.lMess
    }
    hand(): CardList {
        return this._hand;
    }
    public get atout(): Card {
        return this._atout
    }
    public get gameContinues(): boolean {
        return this._gameContinues;
    }

    newInHand(old: CardList): Card {
        if (!(old && this._hand)) {
            return null
        }
        const ret = this._hand.cards.filter(f => !old.cards.find(c => c.equals(f.col, f.val))).find(t => true)
        // console.log(`newInHand o:${old ? old.cards.map(c => Card.json(c)) : '#'} nih:${ret ? Card.json(ret) : '#'}`)
        return ret
    }

    drawHisAndMyNew(newInHand: Card, meFirst: boolean, decksize: number) {
        // console.log(`drawHisAndMyNew nih:${newInHand ? Card.json(newInHand) : '#'}`)
        if (!newInHand) {
            return
        }
        const hisFirstEmpty = Game.hisCardindxs.map(i => this.stage().card(i)).find(c => c.col == Col.Invisible)
        const myFirstEmpty = Game.myCardindxs.map(i => this.stage().card(i)).find(c => c.col == Col.Invisible)
        if (meFirst && myFirstEmpty && hisFirstEmpty) {
            if (decksize<=2) {
                App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.st, CardIndx.st, CardIndx.st, new Card(Col.Invisible)), 'drawHisAndMyNew 1'))
            }
            App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(myFirstEmpty._index, CardIndx.st, myFirstEmpty._sortPos, newInHand), 'drawHisAndMyNew 2'))
            if (decksize<=2) {
                App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.at, CardIndx.at, CardIndx.at, new Card(Col.Invisible)), 'drawHisAndMyNew 3'))
            }
            App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(hisFirstEmpty._index, CardIndx.st, hisFirstEmpty._sortPos, new Card(Col.Back)), 'drawHisAndMyNew 4'))
        } else if ((!meFirst) && myFirstEmpty && hisFirstEmpty) {
            if (decksize<=2) {
                App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.st, CardIndx.st, CardIndx.st, new Card(Col.Invisible)), 'drawHisAndMyNew 1'))
            }
            App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(hisFirstEmpty._index, CardIndx.st, hisFirstEmpty._sortPos, new Card(Col.Back)), 'drawHisAndMyNew 5'))
            if (decksize<=2) {
                App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.at, CardIndx.at, CardIndx.at, new Card(Col.Invisible)), 'drawHisAndMyNew 3'))
            }
            App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(myFirstEmpty._index, CardIndx.st, myFirstEmpty._sortPos, newInHand), 'drawHisAndMyNew 6'))
        } else {
            console.log(`!!! ${meFirst} hfe:${hisFirstEmpty ? 1 : 0} mfe:${myFirstEmpty ? 1 : 0}`)
        }
    }

    public static ckGameContinues(m: SMess): boolean {
        return m.data && (m.data.state == 'player0' || m.data.state == 'player1' || m.cmd == 'gamestart')
    }

    async dispatchInMessage(m: SMess) {
        console.log(`G > ${m.data.game} ${m.cmd} d:${m.data ? m.data.deckSize : '-'} ${JSON.stringify(m.data)}`);
        if ((m.cmd != 'gamestart') && (m.cmd != 'continuegame') && (!this.setup)) {
            console.log(`m.cmd != 'gamestart' && (!this.setup) ${m.cmd}`);
            return
        }
        var newInHand: Card = null
        switch (m.cmd) {
            case 'gamestart':
            case 'gameend':
                break
            default:
                this._table = CardList.fromJson(m.data.table)
                this._zwang = m.data.zwang
                this._atout = Card.fromJson(m.data.atout)
                this._zu = m.data.zu
                this._deckSize = m.data.deckSize
                const hand: CardList = this._hand
                this._hand = CardList.fromJson(m.data.hand)
                newInHand = this.newInHand(hand)

                await this._st.writeText(TextFileds.MyGamePoints, `${m.data.myGp}`)
                await this._st.writeText(TextFileds.HisGamePoints, `${m.data.hisGp}`)
                break
        }
        this._gameContinues = Game.ckGameContinues(m)
        this.lMess = m
        switch (m.cmd) {
            case 'gamestart':
            case 'continuegame':
                // {"cmd":"gamestart","data":{"game":"r-pub-1-1-1736246857601-0","myName":"rickie","hisName":"poveri","myBp":7,"hisBp":7}}
                // console.log(m.cmd, JSON.stringify(m))
                this.setup = m
                for (var i = 0; i <= CardIndx.t1; i++) {
                    if (!this._st.card(i)) {
                        await this._st.addCard(i)
                    }
                }
                await this._st.writeText(TextFileds.MyBummerlPoints, `${m.data.myBp}`)
                await this._st.writeText(TextFileds.MyName, m.data['myName'])
                await this._st.writeText(TextFileds.HisName, m.data['hisName'])
                await this._st.writeText(TextFileds.MyBummerlPoints, `${m.data.myBp}`)
                await this._st.writeText(TextFileds.HisBummerlPoints, `${m.data.hisBp}`)          
                App.instance.tasks.push(new GameTaskBotTrigger(this, m))
                if (m.cmd=='continuegame') {
                    this.drawCards(m, true)
                }
                break;
            case 'ausspielen':
                //{"cmd":"ausspielen","data":{"hand":["Karo-Koenig","Pik-Zehn","Treff-As","Pik-As","Treff-Bub"],"table":[],"atout":"Pik-Bub","myStiche":[],"hisStiche":[],"myGp":0,"hisGp":0,"zwang":false,"zu":false,"deckSize":10,"zugC":0,"game":"r-pub-1-1-1735860813091-0","action":"ausgespielt","state":"player1"}}
                // console.log(m.cmd, JSON.stringify(m))
                if (m.data.zugC == 0) {
                    this.drawCards(m)
                }
                App.instance.tasks.push(new GameTaskSetDraggablePlayable(this, m))
                App.instance.tasks.push(new GameTaskSetUpDown(this, UpDownDir.Up))
                App.instance.tasks.push(new GameTaskBotTrigger(this, m))

                break;
            case 'warte':
                // console.log(m.cmd, JSON.stringify(m))
                if (m.data.zugC == 0) {
                    this.drawCards(m)
                }
                App.instance.tasks.push(new GameTaskSetDraggablePlayable(this, m))
                App.instance.tasks.push(new GameTaskSetUpDown(this, UpDownDir.Down))
                App.instance.tasks.push(new GameTaskBotTrigger(this, m))

                break;
            case 'stechen':
            case 'stechen20':
                // console.log(m.cmd, JSON.stringify(m))
                const ct0 = Card.fromJson(m.data.table[0])
                const first = this.hisRandCardToTable(CardIndx.t0, ct0)
                if (m.cmd == 'stechen20') {
                    const p20 = new Card(ct0.col, ct0.val == Val.Dame ? Val.Koenig : Val.Dame)
                    const second = this.hisRandCardToTable(CardIndx.t1, p20, first)
                    App.instance.tasks.push(new GameTaskWait(this, 300))
                    App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(second, CardIndx.t1, CardIndx.t1, p20), 'stechen20 1'))
                    App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.t1, CardIndx.t1, CardIndx.t1, new Card(Col.Invisible)), 'stechen20 2'))
                    App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(second, CardIndx.t1, second, new Card(Col.Back)), 'stechen20 3'))
                }
                App.instance.tasks.push(new GameTaskSetDraggablePlayable(this, m))
                App.instance.tasks.push(new GameTaskSetUpDown(this, UpDownDir.Up))
                App.instance.tasks.push(new GameTaskBotTrigger(this, m))

                break;
            case 'hastgestochenC1': // new cards missing
                this.hisRandCardToTable(CardIndx.t1, Card.fromJson(m.data.table[1]))
                App.instance.tasks.push(new GameTaskWait(this, 300))
                // first t card to myst
                App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.t0, CardIndx.t0, CardIndx.ms0, new Card(Col.Back)), 'hastgestochenC1 1'))
                App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.ms0, CardIndx.ms0, CardIndx.ms0, new Card(Col.Back)), 'hastgestochenC1 2'))
                App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.t0, CardIndx.t0, CardIndx.t0, new Card(Col.Invisible)), 'hastgestochenC1 3'))
                // second t card to myst
                App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.t1, CardIndx.t1, CardIndx.ms0, new Card(Col.Back)), 'hastgestochenC1 4'))
                App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.t1, CardIndx.t1, CardIndx.t1, new Card(Col.Invisible)), 'hastgestochenC1 5'))
                // this.drawHisAndMyNew(newInHand, true)
                App.instance.tasks.push(new GameTaskDrawHisAndMyNew(this, true, newInHand, this._deckSize))
                App.instance.tasks.push(new GameTaskSetDraggablePlayable(this, m))
                App.instance.tasks.push(new GameTaskSetUpDown(this, UpDownDir.Up))
                App.instance.tasks.push(new GameTaskBotTrigger(this, m))

                break
            case 'hastgestochenC2':
                // first t card to myst
                App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.t0, CardIndx.t0, CardIndx.ms0, new Card(Col.Back)), 'hastgestochenC2 1'))
                App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.ms0, CardIndx.ms0, CardIndx.ms0, new Card(Col.Back)), 'hastgestochenC2 2'))
                App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.t0, CardIndx.t0, CardIndx.t0, new Card(Col.Invisible)), 'hastgestochenC2 3'))
                // second t card to myst
                App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.t1, CardIndx.t1, CardIndx.ms0, new Card(Col.Back)), 'hastgestochenC2 4'))
                App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.t1, CardIndx.t1, CardIndx.t1, new Card(Col.Invisible)), 'hastgestochenC2 5'))
                //this.drawHisAndMyNew(newInHand, true)
                App.instance.tasks.push(new GameTaskDrawHisAndMyNew(this, true, newInHand, this._deckSize))
                App.instance.tasks.push(new GameTaskSetDraggablePlayable(this, m))
                App.instance.tasks.push(new GameTaskSetUpDown(this, UpDownDir.Up))
                App.instance.tasks.push(new GameTaskBotTrigger(this, m))

                break
            case 'wurdegestochenC1': // new cards missing
                this.hisRandCardToTable(CardIndx.t1, Card.fromJson(m.data.table[1]))
                App.instance.tasks.push(new GameTaskWait(this, 2000))
                // first t card to myst
                App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.t0, CardIndx.t0, CardIndx.hs0, new Card(Col.Back)), 'wurdegestochenC1 1'))
                App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.hs0, CardIndx.hs0, CardIndx.hs0, new Card(Col.Back)), 'wurdegestochenC1 2'))
                App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.t0, CardIndx.t0, CardIndx.t0, new Card(Col.Invisible)), 'wurdegestochenC1 3'))
                // second t card to myst
                App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.t1, CardIndx.t1, CardIndx.hs0, new Card(Col.Back)), 'wurdegestochenC1 4'))
                App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.t1, CardIndx.t1, CardIndx.t1, new Card(Col.Invisible)), 'wurdegestochenC1 5'))
                App.instance.tasks.push(new GameTaskDrawHisAndMyNew(this, false, newInHand, this._deckSize))
                App.instance.tasks.push(new GameTaskSetDraggablePlayable(this, m))
                App.instance.tasks.push(new GameTaskSetUpDown(this, UpDownDir.Down))
                App.instance.tasks.push(new GameTaskBotTrigger(this, m))

                break;
            case 'wurdegestochenC2': // looks right
                // first t card to myst
                App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.t0, CardIndx.t0, CardIndx.hs0, new Card(Col.Back)), 'wurdegestochenC2 1'))
                App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.hs0, CardIndx.hs0, CardIndx.hs0, new Card(Col.Back)), 'wurdegestochenC2 2'))
                App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.t0, CardIndx.t0, CardIndx.t0, new Card(Col.Invisible)), 'wurdegestochenC2 3'))
                // second t card to myst
                App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.t1, CardIndx.t1, CardIndx.hs0, new Card(Col.Back)), 'wurdegestochenC2 4'))
                App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.t1, CardIndx.t1, CardIndx.t1, new Card(Col.Invisible)), 'wurdegestochenC2 5'))
                // this.drawHisAndMyNew(newInHand, false)
                App.instance.tasks.push(new GameTaskDrawHisAndMyNew(this, false, newInHand, this._deckSize))
                App.instance.tasks.push(new GameTaskSetDraggablePlayable(this, m))
                App.instance.tasks.push(new GameTaskSetUpDown(this, UpDownDir.Down))
                App.instance.tasks.push(new GameTaskBotTrigger(this, m))

                break;
        }
    }

    hisRandCardToTable(tc: CardIndx, card: Card, but: CardIndx = null): CardIndx {
        const c = this.hisRandCard(but)
        App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(c._index, c._index, tc, card), 'hisRamdCardToTable 1'))
        App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(tc, tc, tc, card), 'hisRamdCardToTable 2'))
        App.instance.tasks.push(new GameTaskMoveCard(this, new CardAni(c._index, c._sortPos, c._sortPos, new Card(Col.Invisible)), 'hisRamdCardToTable 3'))
        return c._index
    }

    async drawCards(m: SMess, immediate: boolean = false) {
        const a = App.instance
        a.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.st, CardIndx.st, CardIndx.st, new Card(Col.Back)), 'drawCards 1'));

        (m.data.hand.length > 0) && a.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.my0, immediate?CardIndx.my0:CardIndx.st, CardIndx.my0, Card.fromJson(m.data.hand[0])), 'drawCards 1'));
        (m.data.hand.length > 1) && a.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.my1, immediate?CardIndx.my1:CardIndx.st, CardIndx.my1, Card.fromJson(m.data.hand[1])), 'drawCards 2'));
        (m.data.hand.length > 2) && a.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.my2, immediate?CardIndx.my2:CardIndx.st, CardIndx.my2, Card.fromJson(m.data.hand[2])), 'drawCards 3'));
        (m.data.hand.length > 3) && a.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.my3, immediate?CardIndx.my3:CardIndx.st, CardIndx.my3, Card.fromJson(m.data.hand[3])), 'drawCards 4'));
        (m.data.hand.length > 4) && a.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.my4, immediate?CardIndx.my4:CardIndx.st, CardIndx.my4, Card.fromJson(m.data.hand[4])), 'drawCards 5'));

        (m.data.hisHandC > 0) && a.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.h0, immediate?CardIndx.h0:CardIndx.st, CardIndx.h0, new Card(Col.Back)), 'drawCards 6'));
        (m.data.hisHandC > 1) && a.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.h1, immediate?CardIndx.h1:CardIndx.st, CardIndx.h1, new Card(Col.Back)), 'drawCards 7'));
        (m.data.hisHandC > 2) && a.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.h2, immediate?CardIndx.h2:CardIndx.st, CardIndx.h2, new Card(Col.Back)), 'drawCards 8'));
        (m.data.hisHandC > 3) && a.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.h3, immediate?CardIndx.h3:CardIndx.st, CardIndx.h3, new Card(Col.Back)), 'drawCards 9'));
        (m.data.hisHandC > 4) && a.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.h4, immediate?CardIndx.h4:CardIndx.st, CardIndx.h4, new Card(Col.Back)), 'drawCards 10'));

        (m.data.deckSize > 0) && a.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.at, CardIndx.at, CardIndx.at, Card.fromJson(m.data.atout)), 'drawCards 11'));
        a.tasks.push(new GameTaskShowAtout(this, Card.fromJson(m.data.atout).col))

        if (immediate) {
            (m.data.deckSize > 0) && a.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.st, CardIndx.st, CardIndx.st, new Card(Col.Back)), 'drawCards 12'));
            (m.data.table.length > 0) && a.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.t0, CardIndx.t0, CardIndx.t0, Card.fromJson(m.data.table[0])), 'drawCards 13'));
            (m.data.table.length > 1) && a.tasks.push(new GameTaskMoveCard(this, new CardAni(CardIndx.t1, CardIndx.t1, CardIndx.t1, Card.fromJson(m.data.table[1])), 'drawCards 14'));
        }

        // console.log(`w:${this._st.app.stage.width} h:${this._st.app.stage.height}`)
    }

    isPLayable(c: CardSprite): boolean {
        if (!(this.lMess && (this.lMess.cmd == 'ausspielen' || this.lMess.cmd == 'stechen' || this.lMess.cmd == 'stechen20' || this.lMess.cmd == 'hastgestochenC1' || this.lMess.cmd == 'hastgestochenC2'))) {
            console.log(`Game isPLayable !lMess ...`)
            return false
        }
        return true
    }

    austauschenPossible(c: CardSprite): boolean {
        return (
            (this.lMess.cmd == 'ausspielen') ||
            (this.lMess.cmd == 'hastgestochenC1') ||
            (this.lMess.cmd == 'hastgestochenC2')
        ) &&
            (this._atout.col == c.col) && (c.val == Val.Bub) &&
            (!this._zu) && (this._deckSize >= Game.MinTalonCZumAustauschen)
    }

    zudrehenPossible(): boolean {
        return ((
            // (this.lMess.cmd == 'ausspielen') ||  //  Zugedreht werden kann erst nach dem ersten Stich.
            (this.lMess.cmd == 'hastgestochenC1') || 
            (this.lMess.cmd == 'hastgestochenC2')
        ) && (!this._zu) && (this._deckSize >= Game.MinTalonCZumZudrehen) && (this._deckSize < Game.MaxTalonCZumZudrehen));
    }


    hisRandCard(but: CardIndx = null): CardSprite {
        const avail = Game.hisCardindxs.map(c => this.stage().card(c)).filter(c => c.col != Col.Invisible && (but === null || but != c._index))
        return avail[Math.floor(Math.random() * avail.length)]
    }

    cardIsZwangOk(c: CardSprite): boolean {
        // console.log(`Game cardIsZwangOk ${this.lMess.cmd}, ${this.table}, crds:${this.table.cards}`)
        if (this._table.cards[0].equals(c.col, c.val)) {
            return false
        }
        if (!this._zwang) {
            return true
        }

        const tmpHand = CardList.copy(this._hand);
        tmpHand.remove(new Card(c.col, c.val));
        // console.log('Game cardIsZwangOk tmpHand', Card.log(new Card(c._col, c._val)), tmpHand.log());

        if (c.col == this._table.cards[0].col) { // farbzwang fulfilled
            // console.log('cardIsZwangOk2 tmpHand',Card.log(card), tmpHand.log());

            if (c.val > this._table.cards[0].val) { // farbzwang && stichzwang fulfilled
                return true
            } else if (c.val < this._table.cards[0].val) { // stichzwang only filfilled if there is no higher by that color
                if (tmpHand.cardListOfSameColorAndHigherThanThis(this._table.cards[0]).length == 0) {
                    return true
                } else {
                    return false
                }
            } else { // impossible, same color and same value
                console.log(`Game cardIsZwangOk impossible, same color and same value`)
                return false
            }
        } else {
            // console.log('cardIsZwangOk3 tmpHand',Card.log(card), tmpHand.log());
            if (tmpHand.filterByColor(this._table.cards[0].col).length === 0) { // no card of that color in hand
                if (c.col == this._atout.col) { // card is atout, implies that table is no atout
                    return true // atout drawn after non atout
                } else if (tmpHand.filterByColor(this._atout.col).length === 0) {
                    return true // card is no atout, and no atout in hand (farbzwang fulfilled), will never be a stich, as it is different color and no atout, stichzwang fulfilled
                } else if (tmpHand.filterByColor(this._atout.col).length > 0) { // card is no atout, i got atouts, stichzwang not filfilled
                    return false
                } else {
                    return false
                    // impossible
                }
            } else {
                // console.log(`Game cardIsZwangOk farbzwang ! fulfilled`)
                return false // farbzwang ! fulfilled
            }
        }
        // return ZugError.nixzwangokdispatched1;
    }

    //     } else if (am.cmd == "gamestart") {
    //       paused = false;
    //       atoutDisplay = null;
    //       hand.cards().clear();
    //       table.cards().clear();
    //       stackCard = null;
    //       atoutCard = null;
    //       tableCard0 = null;
    //       tableCard1 = null;
    //       parsedAtout = null;
    //       world.removeAll(world.children.toList().whereType<SchnapsenCard>());

    //       world.handPos.clear();
    //       world.hisHandPos.clear();

    //       world.myName.text = am.data!["myName"];
    //       world.hisName.text = am.data!["hisName"];
    //       // dev.log('runAction ${am.cmd} ${actions.length} end');
    //       world.myBummerlPoints.text = am.data!["myBp"].toString();
    //       world.hisBummerlPoints.text = am.data!["hisBp"].toString();

    //       return;
    //     }
    //     if (am.cmd == "iwould") {
    //       return botWouldDoThis(am);
    //     }
    //     if (am.cmd == "localcmd_ausspielen") {
    //       SchnapsenCard? c = hand.cards().firstWhereOrNull((ac) => (ac.equals(SchnapsenCard.fromString(am.data!["card"]))));
    //       await cuedAusspielen(c!);
    //       dev.log('localcmd_ausspielen ${world.handPos.map((c) => c.card.toJson())}');
    //       return;
    //     } else if (am.cmd == "localcmd_stechen") {
    //       SchnapsenCard? c = hand.cards().firstWhereOrNull((ac) => (ac.equals(SchnapsenCard.fromString(am.data!["card"]))));
    //       await cuedStechen(c!);
    //       dev.log('localcmd_stechen ${world.handPos.map((c) => c.card.toJson())}');
    //       return;
    //     } else if (am.cmd == "localcmd_austauschen") {
    //       SchnapsenCard? c = hand.cards().firstWhereOrNull((ac) => (ac.equals(SchnapsenCard.fromString(am.data!["card"]))));
    //       await cuedAustauschen(c!);
    //       return;
    //     }

    //     parseMessage(am);

    //     world.myGamePoints.text = am.data!["myGp"].toString();
    //     world.hisGamelPoints.text = am.data!["hisGp"].toString();

    //     bool isOngoing = ((state == 'player0') || (state == 'player1'));
    //     dev.log('runAction $cmd hand: ${hand.cards()}, new:${isNew.cards()} state:$state isOngoing:$isOngoing');
    //     // ####################   beginning, 5 cards, atout
    //     if (isOngoing && (isNew.length() == 5)) {
    //       showStack();
    //       await draw5Cards(am);
    //       if (atoutDisplay != null) {
    //         world.remove(atoutDisplay!);
    //       }
    //       atoutDisplay = AtoutDisplay(atoutCard!.suit);
    //       world.add(atoutDisplay!);
    //       // return;
    //     }

    //     if (am.cmd == 'zugedreht') {
    //       setCardFlip(atoutCard!, false);
    //       return;
    //     }

    //     if (((am.cmd == 'stechen') || (am.cmd == 'stechen20')) && (table.length() == 1)) {
    //       // his card to table
    //       CardAtPos hc = world.getRandNonFreeHisHandPos();
    //       tableCard0 = table.cards()[0];
    //       dev.log('his card to table');
    //       await moveHisCard(tableCard0!, hc.card, hc.indx, CardIndx.t0, true);
    //       if (am.cmd == 'stechen20') {
    //         CardAtPos p20 = world.getRandNonFreeHisHandPos();
    //         SchnapsenCard c20p = SchnapsenCard((tableCard0!.rank.fs == 'Koenig') ? Rank.fromString("Dame") : Rank.fromString("Koenig"), tableCard0!.suit);
    //         dev.log('his card to table20 ${p20.card} ${p20.indx} $c20p');

    //         world.remove(p20.card);
    //         world.add(c20p);

    //         await moveCard(c20p, p20.indx, CardIndx.t1, true);
    //         await Future.delayed(const Duration(seconds: 1));
    //         await moveCard(c20p, CardIndx.t1, p20.indx, false);
    //         world.hisHandPos.add(CardAtPos(c20p, p20.indx));
    //       }
    //     } else if ((am.cmd == 'wurdegestochenC1') && (table.length() == 2)) {
    //       // his card to table
    //       CardAtPos hc = world.getRandNonFreeHisHandPos();
    //       tableCard1 = table.cards()[1];
    //       await moveHisCard(tableCard1!, hc.card, hc.indx, CardIndx.t1, true);
    //       dev.log('his card moved to table, ${world.hisHandPos.length}');
    //     } else if (am.cmd == 'hastgestochenC1' && (table.length() == 2)) {
    //       // his card to table
    //       CardAtPos hc = world.getRandNonFreeHisHandPos();
    //       tableCard1 = table.cards()[1];
    //       await moveHisCard(tableCard1!, hc.card, hc.indx, CardIndx.t1, true);
    //       dev.log('his card moved to table, ${world.hisHandPos.length}');
    //     }

    //     dev.log('blabla4 ${am.cmd} ${table.length()}');
    //     // ####################   clear table
    //     if (((am.cmd == 'wurdegestochenC1') || (am.cmd == 'hastgestochenC1') || (am.cmd == 'wurdegestochenC2') || (am.cmd == 'hastgestochenC2')) && (table.length() == 2)) {
    //       dev.log("clear table");
    //       assert(tableCard0 != null);
    //       assert(tableCard1 != null);
    //       await Future.delayed(const Duration(seconds: 1));
    //       await moveCard(tableCard0!, CardIndx.t0, ((am.cmd == 'hastgestochenC1') || (am.cmd == 'hastgestochenC2')) ? CardIndx.ms0 : CardIndx.hs0, false);
    //       await moveCard(tableCard1!, CardIndx.t1, ((am.cmd == 'hastgestochenC1') || (am.cmd == 'hastgestochenC2')) ? CardIndx.ms0 : CardIndx.hs0, false);
    //       tableCard0 = null;
    //       tableCard1 = null;
    //     }

    //     // ####################   1 new card each
    //     if (isNew.length() == 1) {
    //       for (var i = 0; i < isNew.length(); i++) {
    //         dev.log('found a new card');
    //         // get one new card
    //         // assert(isNew.length() >= 0 && isNew.length() <= 1);
    //         if ((am.cmd == 'hastgestochenC1') || am.cmd == 'hastgestochenC2') {
    //           setCardFlip(isNew.at(i), true);
    //           if (deckSize <= 0) {
    //             world.remove(stackCard!);
    //           }
    //           await drawMyCard(isNew.at(i), CardIndx.st, true, true, am.cmd == 'play');
    //           if (deckSize <= 0) {
    //             world.remove(atoutCard!);
    //           }
    //           await drawHisCard(SchnapsenCard(Rank.fromInt(2 + i), Suit.fromInt(1)), CardIndx.st, false);
    //         } else if ((am.cmd == 'wurdegestochenC1') || am.cmd == 'wurdegestochenC2') {
    //           if (deckSize <= 0) {
    //             world.remove(stackCard!);
    //           }
    //           await drawHisCard(SchnapsenCard(Rank.fromInt(2 + i), Suit.fromInt(1)), CardIndx.st, false);
    //           if (deckSize <= 0) {
    //             world.remove(atoutCard!);
    //           }
    //           await drawMyCard(isNew.at(i), CardIndx.st, true, true, am.cmd == 'play');
    //           setCardFlip(isNew.at(i), true);
    //         }
    //       }
    //     }

    //     switch (am.cmd) {
    //       case 'ausspielen':
    //       case 'hastgestochenC1':
    //       case 'hastgestochenC2':
    //         world.writeText(isOngoing ? "Spiel aus" : "Ende");
    //         setHandPlayable(true);
    //         break;
    //       case 'stechen':
    //       case 'stechen20':
    //         world.writeText(isOngoing ? "Stechen" : "Ende");
    //         setHandPlayable(true);
    //         break;
    //       default:
    //         world.writeText("Warte");
    //         setHandPlayable(false);
    //     }
    //     // } catch (e) {
    //     //   print(e.toString());
    //     // }
    //   }

}

export default Game
export { CardIndx }














// import 'dart:async';
// import 'dart:developer' as dev;
// import 'dart:ui';

// import 'package:auto_route/auto_route.dart';
// import 'package:client855835/bloc/socket/socket_message_server.dart';
// import 'package:flame/components.dart';
// import 'package:flame/flame.dart';
// import 'package:flame/game.dart';

// import '../../bloc/game/game_cubit.dart';
// import '../../bloc/socket/socket_bloc.dart';
// import '../../bloc/socket/socket_message_client.dart';
// import 'card.list.dart';
// import 'components/atoutdisplay.dart';
// import 'components/card.dart';
// import 'rank.dart';
// import 'schnapsen_world.dart';
// import 'suit.dart';

// abstract class GameTerminatorListener {
//   void terminateMe();
// }

// class SchnapsenGame extends FlameGame<SchnapsenWorld> {
//   static const int MinTalonCZumAustauschen = 0;
//   static const int MinTalonCZumZudrehen = 0;
//   static const int MinStichCZumZudrehen = 1; // not used, nur wenn hastgestochenC1 oder C2
//   static const int MaxTalonCZumZudrehen = 10;
//   static const bool WienerischZudrehen = false; // not used, only relevant on server

//   static const double cardGap = 175.0;
//   static const double topGap = 500.0;
//   static const double cardWidth = 1000.0;
//   static const double cardHeight = 1400.0;
//   static const double cardRadius = 100.0;
//   static const double cardSpaceWidth = cardWidth + cardGap;
//   static const double cardSpaceHeight = cardHeight + cardGap;
//   static final Vector2 cardSize = Vector2(cardWidth, cardHeight);
//   static final cardRRect = RRect.fromRectAndRadius(
//     const Rect.fromLTWH(0, 0, cardWidth, cardHeight),
//     const Radius.circular(cardRadius),
//   );

//   /// Constant used to decide when a short drag is treated as a TapUp event.
//   static const double dragTolerance = cardWidth / 5;

//   /// Constant used when creating Random seed.
//   static const int maxInt = 0xFFFFFFFE; // = (2 to the power 32) - 1

//   final Cardlist hand = Cardlist();
//   final Cardlist table = Cardlist();
//   late String cmd;
//   late String action;
//   late bool zugedreht;
//   late bool zwang;
//   late int deckSize;
//   String state = 'def';
//   // final List<SMess> actions = [];
//   AtoutDisplay? atoutDisplay;

//   SchnapsenCard? stackCard;
//   SchnapsenCard? atoutCard;
//   SchnapsenCard? tableCard0;
//   SchnapsenCard? tableCard1;
//   SchnapsenCard? parsedAtout;

//   Cardlist isNew = Cardlist();
//   SMess? currenTAction;
//   late SocketBloc socket;
//   bool ichHabAusgespielt = false;
//   String bummerlId;
//   bool started = false;

//   SchnapsenGame(this.bummerlId, SocketBloc asocket) : super(world: SchnapsenWorld()) {
//     socket = asocket;
//     dev.log('SchnapsenGame constructor');
//   }

//   @override
//   Color backgroundColor() => const Color.fromARGB(255, 6, 129, 2);

//   @override
//   Future<void> onLoad() async {
//     await super.onLoad();
//   }

//   startToListen() {
//     dev.log('startToListen');
//     started = true;
//     runActions();
//   }

//   @override
//   void update(double dt) async {
//     runActions();
//     super.update(dt);
//   }


//   runActions() async {
//     if (GameCubit.instance.gameMessages.isEmpty) {
//       return;
//     }
//     if (!started) {
//       return;
//     }
//     if (currenTAction != null) {
//       return;
//     }
//     dev.log('runActions acl:${GameCubit.instance.gameMessages.length} cur:${currenTAction != null} started:$started');

//     currenTAction = GameCubit.instance.gameMessages.removeAt(0);
//     await runAction(currenTAction!);
//     currenTAction = null;
//     runActions();
//   }

//   void botWouldDoThis(SMess am) {
//     try {
//       switch (am.data!["zug"]) {
//         case 'ausspielen':
//           SchnapsenCard? parsedCard = SchnapsenCard.fromString(am.data!["card"]);
//           CardAtPos? card = world.handPos.firstWhereOrNull((p0) => (p0.card.equals(parsedCard)));
//           if (card != null) {
//             ausspielen(card.card);
//           }
//           break;
//         case 'stechen':
//           SchnapsenCard? parsedCard = SchnapsenCard.fromString(am.data!["card"]);
//           CardAtPos? card = world.handPos.firstWhereOrNull((p0) => (p0.card.equals(parsedCard)));
//           if (card != null) {
//             stechen(card.card);
//           }
//           break;
//         case 'austauschen':
//           SchnapsenCard? parsedCard = SchnapsenCard.fromString(am.data!["card"]);
//           CardAtPos? card = world.handPos.firstWhereOrNull((p0) => (p0.card.equals(parsedCard)));
//           if (card != null) {
//             austauschen(card.card);
//           }
//           break;
//         case 'zudrehen':
//           zudrehen();
//           break;
//       }
//     } catch (e) {
//       dev.log(e.toString());
//     }
//   }

//   void parseMessage(SMess am) {
//     cmd = am.cmd;
//     if ((am.data != null) && am.data!.containsKey("hand") && am.data!.containsKey("table") && am.data!.containsKey("atout")) {
//       List<SchnapsenCard> thand = am.data!["hand"].map<SchnapsenCard>((cs) => SchnapsenCard.fromString(cs)).toList();
//       List<SchnapsenCard> ttable = am.data!["table"].map<SchnapsenCard>((cs) => SchnapsenCard.fromString(cs)).toList();
//       table.setCards(ttable);
//       parsedAtout = SchnapsenCard.fromString(am.data!["atout"]);
//       zugedreht = am.data!["zu"];
//       zwang = am.data!["zwang"];
//       action = am.data!["action"];
//       deckSize = am.data!["deckSize"];
//       state = am.data!["state"];
//       isNew.cards().clear();
//       for (SchnapsenCard hc in thand) {
//         if (!hand.has(hc)) {
//           isNew.add(hc);
//           hand.add(hc);
//           // log('added to hand ${hc.toString()} ${hand.length()}');
//         }
//       }
//     }
//   }



//   Future<void> moveHisCard(SchnapsenCard nc, SchnapsenCard rc, CardIndx fromPos, CardIndx toPos, bool front) async {
//     dev.log('ActionType.moveHisCard ${world.hisHandPos.length}');
//     world.remove(rc);
//     world.hisHandPos.removeWhere((cp) => (cp.indx == fromPos));

//     world.add(nc);
//     setCardFlip(nc, front);
//     var prio = nc.priority;
//     nc.priority = 1000;
//     await nc.doMoveAsyPos(fromPos, toPos);
//     nc.priority = prio;
//     nc.isDraggable = false;
//     nc.isPlayable = false;
//   }

//   Future<void> draw5Cards(SMess am) async {
//     for (var i = 0; i < 3; i++) {
//       if (am.cmd == 'ausspielen') {
//         await drawMyCard(isNew.at(i), CardIndx.st, true, true, am.cmd == 'play');
//       } else {
//         await drawHisCard(SchnapsenCard(Rank.fromInt(2 + i), Suit.fromInt(1)), CardIndx.st, false);
//       }
//     }
//     for (var i = 0; i < 3; i++) {
//       if (am.cmd == 'ausspielen') {
//         await drawHisCard(SchnapsenCard(Rank.fromInt(2 + i), Suit.fromInt(1)), CardIndx.st, false);
//       } else {
//         await drawMyCard(isNew.at(i), CardIndx.st, true, true, am.cmd == 'play');
//       }
//     }

//     showAtout();

//     for (var i = 3; i < 5; i++) {
//       if (am.cmd == 'ausspielen') {
//         await drawMyCard(isNew.at(i), CardIndx.st, true, true, am.cmd == 'play');
//       } else {
//         await drawHisCard(SchnapsenCard(Rank.fromInt(2 + i), Suit.fromInt(1)), CardIndx.st, false);
//       }
//     }
//     for (var i = 3; i < 5; i++) {
//       if (am.cmd == 'ausspielen') {
//         await drawHisCard(SchnapsenCard(Rank.fromInt(2 + i), Suit.fromInt(1)), CardIndx.st, false);
//       } else {
//         await drawMyCard(isNew.at(i), CardIndx.st, true, true, am.cmd == 'play');
//       }
//     }
//   }

//   void showStack() {
//     if (stackCard != null) {
//       return;
//     }
//     stackCard = SchnapsenCard(Rank.fromInt(2), Suit.fromInt(2));
//     stackCard!.position = SchnapsenWorld.cardPositions[CardIndx.st.index];
//     world.add(stackCard!);
//   }

//   void setCardFlip(SchnapsenCard nc, bool front) {
//     if (front && (!nc.isFaceUp)) {
//       nc.flip();
//     } else if ((!front) && nc.isFaceUp) {
//       nc.flip();
//     }
//   }

//   Future<void> drawMyCard(SchnapsenCard nc, CardIndx fromPos, bool front, bool draggable, bool playable) async {
//     dev.log('drawMyCard $nc $CardIndx[fromPos]');
//     world.add(nc);
//     var prio = nc.priority;
//     nc.priority = 1000;
//     setCardFlip(nc, front);
//     await nc.doMoveAsyPos(fromPos, world.putAtFirstFreeHandPos(nc));
//     nc.priority = prio;
//     nc.isDraggable = draggable;
//     nc.isPlayable = playable;
//   }

//   Future<void> drawHisCard(SchnapsenCard nc, CardIndx fromPos, bool front) async {
//     // dev.log('ActionType.drawHisCard ${nc.toString()} $fromPos');
//     world.add(nc);
//     var prio = nc.priority;
//     nc.priority = 1000;
//     setCardFlip(nc, front);
//     await nc.doMoveAsyPos(fromPos, world.putAtFirstFreeHisHandPos(nc));
//     nc.priority = prio;
//     nc.isDraggable = false;
//     nc.isPlayable = false;
//   }

//   void showAtout() {
//     if (parsedAtout == null) {
//       return;
//     }
//     if (atoutCard != null) {
//       if (!atoutCard!.equals(parsedAtout!)) {
//         world.remove(atoutCard!);
//         atoutCard = parsedAtout;
//         world.add(atoutCard!);
//         atoutCard!.priority = 200;
//         setCardFlip(atoutCard!, true);
//       }
//     } else {
//       atoutCard = parsedAtout;
//       atoutCard!.position = SchnapsenWorld.cardPositions[CardIndx.at.index];
//       world.add(atoutCard!);
//       atoutCard!.priority = 200;
//       setCardFlip(atoutCard!, true);
//     }
//   }

//   Future<void> moveCard(SchnapsenCard nc, CardIndx? fromPos, CardIndx toPos, bool front) async {
//     var prio = nc.priority;
//     nc.priority = 1000;
//     setCardFlip(nc, front);
//     await nc.doMoveAsyPos(fromPos, toPos);
//     nc.priority = prio;
//     nc.isDraggable = false;
//     nc.isPlayable = false;
//   }

//   void setHandPlayable(bool truefalse) {
//     bool isOngoing = ((state == 'player0') || (state == 'player1'));
//     for (SchnapsenCard crd in hand.cards()) {
//       crd.isPlayable = isOngoing ? truefalse : false;
//     }
//   }

//   Future<void> sendZug(SchnapsenCard? card, String mess, {bool is20 = false}) async {
//     var ret = await socket.sendMessageRoundTrip(CMess(cmd: mess, data: {"card": (card != null) ? card.toJson() : "", "is20": is20 ? true : false}));
//     dev.log('sendZug ret ${ret.toString()}');
//   }

//   bool austauschenPossible(SchnapsenCard c) {
//     assert(hand.has(c));
//     return (((cmd == 'ausspielen') || (cmd == 'hastgestochenC1') || (cmd == 'hastgestochenC2')) && c.equalsColor(atoutCard!) && (!zugedreht) && (deckSize >= MinTalonCZumAustauschen));
//   }

//   Future<void> austauschen(SchnapsenCard c) async {
//     SMess cuedAc = SMess(cmd: 'localcmd_austauschen', data: {"card": c.toJson()});
//     GameCubit.instance.gameMessages.add(cuedAc);
//     runActions();
//   }

//   Future<void> cuedAustauschen(SchnapsenCard c) async {
//     dev.log('austauschen ${c.toJson()}');
//     world.game.setHandPlayable(false);
//     CardAtPos? found = world.handPos.firstWhereOrNull((p0) => (p0.card.equals(c)));
//     await moveCard(c, null, CardIndx.at, true);
//     world.handPos.removeWhere((hp) => (hp.card.equals(c)));
//     hand.remove(c);
//     c.isDraggable = false;
//     c.isPlayable = false;
//     world.handPos.add(CardAtPos(atoutCard!, found!.indx));
//     hand.add(atoutCard!);
//     await moveCard(atoutCard!, CardIndx.at, found.indx, true);
//     atoutCard!.isDraggable = true;
//     atoutCard!.isPlayable = true;
//     atoutCard = c;
//     world.game.sendZug(c, "austauschen");
//     setHandPlayable(true);
//   }

//   Future<void> ausspielen(SchnapsenCard c) async {
//     SMess cuedAc = SMess(cmd: 'localcmd_ausspielen', data: {"card": c.toJson()});
//     GameCubit.instance.gameMessages.add(cuedAc);
//     runActions();
//   }

//   Future<void> cuedAusspielen(SchnapsenCard c) async {
//     assert(tableCard1 == null);
//     assert(tableCard0 == null);
//     setHandPlayable(false);
//     tableCard0 = c;
//     c.isDraggable = false;
//     c.isPlayable = false;
//     c.isPlayable = false;
//     await moveCard(c, null, CardIndx.t0, true);
//     hand.remove(c);
//     world.handPos.removeWhere((hp) => (hp.card.equals(c)));

//     SchnapsenCard p20 = SchnapsenCard((c.rank.fs == 'Koenig') ? Rank.fromString('Dame') : Rank.fromString('Koenig'), c.suit);
//     CardAtPos? found = world.handPos.firstWhereOrNull((p0) => (p0.card.equals(p20)));
//     if (((c.rank.fs == 'Koenig') || (c.rank.fs == 'Dame')) && hand.has(p20) && (found != null)) {
//       // world.handPos.remove(found);
//       // world.add(p20);
//       dev.log('is 20 ${c.toJson()} ${p20.toJson()}');
//       await moveCard(found.card, found.indx, CardIndx.t1, true);
//       await Future.delayed(const Duration(seconds: 1));
//       await moveCard(found.card, CardIndx.t1, found.indx, true);
//       // world.handPos.add(CardAtPos(p20, found.indx));
//       found.card.isDraggable = true;
//       await sendZug(c, "ausspielen");
//     } else {
//       await sendZug(c, "ausspielen");
//     }
//   }

//   bool zudrehenPossible() {
//     //  || (cmd == 'ausspielen') //  Zugedreht werden kann erst nach dem ersten Stich.
//     return (((cmd == 'hastgestochenC1') || (cmd == 'hastgestochenC2')) && (!zugedreht) && (deckSize >= MinTalonCZumZudrehen) && (deckSize < MaxTalonCZumZudrehen));
//   }

//   Future<void> zudrehen() async {
//     setHandPlayable(false);
//     setCardFlip(atoutCard!, false);
//     await sendZug(null, "zudrehen");
//   }

//   bool ausspielenPossible(SchnapsenCard c) {
//     assert(hand.has(c));
//     return (((cmd == 'hastgestochenC1') || (cmd == 'hastgestochenC2') || (cmd == 'ausspielen')) && ((table.length() == 0) || (table.length() == 2)));
//   }

//   bool stechenPossible(SchnapsenCard c) {
//     assert(hand.has(c));
//     if (!zwang) {
//       return ((cmd == 'stechen') || (cmd == 'stechen20')) && (table.length() == 1);
//     } else {
//       return ((cmd == 'stechen') || (cmd == 'stechen20')) && (table.length() == 1) && cardIsZwangOk(c);
//     }
//   }

//   Future<void> stechen(SchnapsenCard c) async {
//     SMess cuedAc = SMess(cmd: 'localcmd_stechen', data: {"card": c.toJson()});
//     GameCubit.instance.gameMessages.add(cuedAc);
//     runActions();
//   }

//   Future<void> cuedStechen(SchnapsenCard c) async {
//     assert(tableCard0 != null);
//     assert(tableCard1 == null);
//     tableCard1 = c;
//     setHandPlayable(false);
//     hand.remove(c);
//     CardAtPos? found = world.handPos.firstWhereOrNull((p0) => (p0.card.equals(c)));
//     world.handPos.remove(found);
//     c.isDraggable = false;
//     c.isPlayable = false;
//     await moveCard(c, null, CardIndx.t1, true);
//     await sendZug(c, "stechen");
//   }

//   bool cardIsZwangOk(SchnapsenCard c) {
//     assert(table.length() == 1);
//     assert(zwang);
//     Cardlist thand = Cardlist();
//     thand.setCards(hand.cards());
//     thand.remove(c);
//     if (table.cards()[0].equalsColor(c)) {
//       // farbzwang ok
//       if (c.rank.points > table.cards()[0].rank.points) {
//         // farbzwang && stichzwang ok
//         return true;
//       } else {
//         var foundHigherSamSuit = thand.cards().firstWhereOrNull((p) => (p.equalsColor(c) && (p.rank.points > table.cards()[0].rank.points)));
//         return (foundHigherSamSuit == null); // false if have higher than table with same color in hand
//       }
//     } else {
//       var foundSamSuit = thand.cards().firstWhereOrNull((p) => (p.equalsColor(table.cards()[0])));
//       if (foundSamSuit == null) {
//         // no card of that color in hand
//         if (atoutCard!.equalsColor(c)) {
//           // card is atout, implies that table is no atout
//           return true; // atout takes non atout
//         } else {
//           var foundAtoutSuit = thand.cards().firstWhereOrNull((p) => (p.equalsColor(atoutCard!)));
//           if (foundAtoutSuit == null) {
//             return true; // card is no atout, and no atout in hand (farbzwang fulfilled), will never be a stich, as it is different color and no atout, stichzwang fulfilled
//           } else {
//             // card is no atout, i got atouts, stichzwang not followed
//             return false;
//           }
//         }
//       } else {
//         return false; // farbzwang ! ok
//       }
//     }
//   }
// }

// Sprite schnapsenSprite(double x, double y, double width, double height) {
//   return Sprite(
//     Flame.images.fromCache('klondike-sprites.png'),
//     srcPosition: Vector2(x, y),
//     srcSize: Vector2(width, height),
//   );
// }
