import $ from 'jquery';
import State from './state/state';
import StateHome from './state/state.home';
import StateLogin from './state/state.login';
import StateTurns from './state/state.turns';
import StateRooms from './state/state.rooms';
import StateProfile from './state/state.profile';
import StateComputerGame from './state/state.game.robot';
import StateInfo from './state/state.info';
import StateMessages from './state/state.messages';
import StateFriends from './state/state.friends';
import StateRules from './state/state.rules';
import StateRoom from './state/state.room';
import StateGame from './state/state.game';


class MenuItem {
    link: string;
    name: string;
    type: typeof State;
    needsLogin: boolean;

    constructor(link: string, name: string, type: typeof State, needsLogin: boolean) {
        this.link = '/' + link;
        this.name = name;
        this.type = type;
        this.needsLogin = needsLogin;
    }
    static render(mi: MenuItem): string {
        return `<a href="${mi.link}" class="html5history">${mi.name}</a>`;
    }
    match(l: string): boolean {
        return this.link == l
    }
}

class MenuItemRegex extends MenuItem {
    rexi: RegExp
    constructor(link: string, name: string, type: typeof State, needsLogin: boolean, rexi: RegExp) {
        super(link, name, type, needsLogin)
        this.rexi = rexi
    }
    match(l: string): boolean {
        return this.rexi.test(l)
    }
}

class Menu {
    items: MenuItem[] = [
        new MenuItem('profile', 'Profile', StateProfile, true),
        new MenuItem('home', 'Home', StateHome, false),
        new MenuItem('home', 'Home', StateHome, true),
        new MenuItem('computer', 'Computer', StateComputerGame, false),
        new MenuItem('computer', 'Computer', StateComputerGame, true),
        new MenuItem('login', 'Login', StateLogin, false),
        new MenuItem('turns', 'Turniere', StateTurns, true),
        new MenuItem('rooms', 'Räume', StateRooms, true),
        new MenuItem('messages', 'Nachrichten', StateMessages, true),
        new MenuItem('friends', 'Freunde', StateFriends, true),
        new MenuItem('info', 'Infos', StateInfo, false),
        new MenuItem('info', 'Infos', StateInfo, true),
        new MenuItem('rules', 'Spielregeln', StateRules, true),
    ];
    furtherStates: MenuItem[] = [
        new MenuItemRegex('room', 'Raum', StateRoom, true, /\/room\/.*/),
        new MenuItemRegex('game', 'Spiel', StateGame, true, /\/game\/.*/),
    ]
    state: State;

    constructor() {
    }

    async init(isLoggedIn: boolean) {
        $('#menu').html(this.render(isLoggedIn));
    }

    render(isLoggedIn: boolean): string {
        console.log(this.constructor.name, 'render', isLoggedIn);
        return this.items.filter((it) => (isLoggedIn == it.needsLogin)).map(MenuItem.render).join('\n');
    }

    async go(path?: string) {
        path = path || '/home'
        const nextManuItem = this.items.concat(this.furtherStates).find((it) => it.match(path));
        if (nextManuItem) {
            console.log('menu go', path, nextManuItem.name);
            if (this.state) {
                await this.state.onExit()
            }
            this.state = new nextManuItem.type(nextManuItem.link);
            await this.state.onEnter(path);
        } else {
            console.log(this.constructor.name, 'no state found', path);
        }
    }

    async goWithUrlChange(path: string) {
        if (window.location.protocol != 'file:') {
            history.pushState(null, null, path);
        }
        await this.go(path)
    }
}

export { Menu }