import { Col, Val } from "./game.card.enums";
import * as PIXI from 'pixi.js'
import platform from '../platform/platform-web'


class CardSpriteCutter {
    static tcsW: number = 2048 / 6 //341
    static tcsH: number = 1808 / 4 //452

    static ffBubR = new PIXI.Rectangle(1028, 1673, 52, 74)
    static ffDamR = new PIXI.Rectangle(1088, 1673, 52, 74)
    static ffKoeR = new PIXI.Rectangle(1146, 1673, 52, 74)
    static ffZehR = new PIXI.Rectangle(1259, 1673, 92, 74)
    static ffAssR = new PIXI.Rectangle(1205, 1673, 52, 74)

    static ffHerzR = new PIXI.Rectangle(1032, 1586, 67, 67)
    static ffKaroR = new PIXI.Rectangle(1112, 1586, 82, 82)
    static ffPikkR = new PIXI.Rectangle(1200, 1586, 67, 67)
    static ffTrefR = new PIXI.Rectangle(1276, 1586, 67, 67)

    static ddHerzR = new PIXI.Rectangle(1026, 1379, 100, 90)
    static ddScheR = new PIXI.Rectangle(1126, 1379, 100, 90)
    static ddGrueR = new PIXI.Rectangle(1026, 1479, 100, 90)
    static ddEichR = new PIXI.Rectangle(1226, 1362, 100, 110)

    static backTile = new PIXI.Rectangle(1275, 1513, 28, 28)


    static ffTopRightX = CardSpriteCutter.tcsW - 90
    static ffTopRightY = 20

    static ddTopLefttX = 5
    static ddTopRightX = CardSpriteCutter.tcsW - 90
    static ddUnterY = 120
    static ddOberY = 16

    static async show(s: PIXI.Container, texture: PIXI.Texture, col: Col, val: Val, dd: boolean) {
        s.removeChildren()
        if (col==Col.Invisible){
            return
        } else if (col == Col.Back) {
            return CardSpriteCutter.mkBack(s, texture)
        }
        switch (dd) {
            case true:
                return CardSpriteCutter.mkDD(s, texture, col, val)
            case false:
                return CardSpriteCutter.mkFF(s, texture, col, val)
        }
    }

    static mkBack(s: PIXI.Container, texture: PIXI.Texture): void {
        CardSpriteCutter.bgRectFromFFRect(s, texture, new PIXI.Rectangle(5 * CardSpriteCutter.tcsW, 0 * CardSpriteCutter.tcsH, CardSpriteCutter.tcsW, CardSpriteCutter.tcsH))
        const to = new PIXI.Rectangle(0, 0, CardSpriteCutter.tcsW, CardSpriteCutter.tcsH)
        const trim = to;
        const rotate = 0;

        const rotatedTexture = new PIXI.Texture({
            source: texture.source,
            frame: CardSpriteCutter.backTile,
            orig: new PIXI.Rectangle(0, 0, CardSpriteCutter.backTile.width, CardSpriteCutter.backTile.height),
            trim: new PIXI.Rectangle(0, 0, CardSpriteCutter.backTile.width, CardSpriteCutter.backTile.height),
            rotate: rotate,
        });
        const dude = new PIXI.TilingSprite({ texture: rotatedTexture, width: CardSpriteCutter.tcsW, height: CardSpriteCutter.tcsH });
        dude.position.set(0, 0);
        s.addChild(dude)
    }

    static mkDD(s: PIXI.Container, tx: PIXI.Texture, col: Col, val: Val): void {
        CardSpriteCutter.addShadow(s, tx)
        var srcY = 0
        switch (col) {
            case Col.Herz:
                srcY = 0 * CardSpriteCutter.tcsH
                break;
            case Col.Karo:
                srcY = 1 * CardSpriteCutter.tcsH
                break;
            case Col.Pik:
                srcY = 2 * CardSpriteCutter.tcsH
                break;
            case Col.Treff:
                srcY = 3 * CardSpriteCutter.tcsH
                break;
        }
        switch (val) {
            case Val.Bub:
                CardSpriteCutter.bgRectFromFFRect(s, tx, new PIXI.Rectangle(0 * CardSpriteCutter.tcsW, srcY, CardSpriteCutter.tcsW, CardSpriteCutter.tcsH))
                CardSpriteCutter.ddColSym(s, tx, col, val, CardSpriteCutter.ddTopLefttX, CardSpriteCutter.ddUnterY)
                break;
            case Val.Dame:
                CardSpriteCutter.bgRectFromFFRect(s, tx, new PIXI.Rectangle(1 * CardSpriteCutter.tcsW, srcY, CardSpriteCutter.tcsW, CardSpriteCutter.tcsH))
                CardSpriteCutter.ddColSym(s, tx, col, val, CardSpriteCutter.ddTopLefttX, CardSpriteCutter.ddOberY)
                break;
            case Val.Koenig:
                CardSpriteCutter.bgRectFromFFRect(s, tx, new PIXI.Rectangle(2 * CardSpriteCutter.tcsW, srcY, CardSpriteCutter.tcsW, CardSpriteCutter.tcsH))
                CardSpriteCutter.ddColSym(s, tx, col, val, CardSpriteCutter.ddTopLefttX, CardSpriteCutter.ddOberY)
                break;
            case Val.Zehn:
                CardSpriteCutter.bgRectFromFFRect(s, tx, new PIXI.Rectangle(5 * CardSpriteCutter.tcsW, 0, CardSpriteCutter.tcsW, CardSpriteCutter.tcsH))
                const dd10dy = 78
                CardSpriteCutter.ddColSym(s, tx, col, val, CardSpriteCutter.ddTopLefttX, CardSpriteCutter.ddOberY + 0 * dd10dy)
                CardSpriteCutter.ddColSym(s, tx, col, val, CardSpriteCutter.ddTopLefttX, CardSpriteCutter.ddOberY + 1 * dd10dy)
                CardSpriteCutter.ddColSym(s, tx, col, val, CardSpriteCutter.ddTopLefttX, CardSpriteCutter.ddOberY + 2 * dd10dy)
                CardSpriteCutter.ddColSym(s, tx, col, val, CardSpriteCutter.ddTopLefttX, CardSpriteCutter.ddOberY + 3 * dd10dy)
                CardSpriteCutter.ddColSym(s, tx, col, val, CardSpriteCutter.ddTopLefttX, CardSpriteCutter.ddOberY + 4 * dd10dy)
                const dd10rx = CardSpriteCutter.tcsW - 100

                CardSpriteCutter.ddColSym(s, tx, col, val, dd10rx, CardSpriteCutter.ddOberY + 0 * dd10dy)
                CardSpriteCutter.ddColSym(s, tx, col, val, dd10rx, CardSpriteCutter.ddOberY + 1 * dd10dy)
                CardSpriteCutter.ddColSym(s, tx, col, val, dd10rx, CardSpriteCutter.ddOberY + 2 * dd10dy)
                CardSpriteCutter.ddColSym(s, tx, col, val, dd10rx, CardSpriteCutter.ddOberY + 3 * dd10dy)
                CardSpriteCutter.ddColSym(s, tx, col, val, dd10rx, CardSpriteCutter.ddOberY + 4 * dd10dy)

                break;
            case Val.As:
                CardSpriteCutter.bgRectFromFFRect(s, tx, new PIXI.Rectangle(4 * CardSpriteCutter.tcsW, srcY, CardSpriteCutter.tcsW, CardSpriteCutter.tcsH))
                CardSpriteCutter.ddColSym(s, tx, col, val, CardSpriteCutter.ddTopLefttX, CardSpriteCutter.ddOberY)
                break;
        }
    }



    static mkFF(s: PIXI.Container, tx: PIXI.Texture, col: Col, val: Val): void {
        CardSpriteCutter.addShadow(s, tx)
        switch (val) {
            case Val.Bub:
                CardSpriteCutter.bgRectFromFFRect(s, tx, new PIXI.Rectangle(3 * CardSpriteCutter.tcsW, 0 * CardSpriteCutter.tcsH, CardSpriteCutter.tcsW, CardSpriteCutter.tcsH))
                CardSpriteCutter.symFF(s, tx, CardSpriteCutter.ffBubR)
                CardSpriteCutter.ffColSym(s, tx, col, val, CardSpriteCutter.ffTopRightX, CardSpriteCutter.ffTopRightY)
                break;
            case Val.Dame:
                CardSpriteCutter.bgRectFromFFRect(s, tx, new PIXI.Rectangle(3 * CardSpriteCutter.tcsW, 1 * CardSpriteCutter.tcsH, CardSpriteCutter.tcsW, CardSpriteCutter.tcsH))
                CardSpriteCutter.symFF(s, tx, CardSpriteCutter.ffDamR)
                CardSpriteCutter.ffColSym(s, tx, col, val, CardSpriteCutter.ffTopRightX, CardSpriteCutter.ffTopRightY)
                break;
            case Val.Koenig:
                CardSpriteCutter.bgRectFromFFRect(s, tx, new PIXI.Rectangle(3 * CardSpriteCutter.tcsW, 2 * CardSpriteCutter.tcsH, CardSpriteCutter.tcsW, CardSpriteCutter.tcsH))
                CardSpriteCutter.symFF(s, tx, CardSpriteCutter.ffKoeR)
                CardSpriteCutter.ffColSym(s, tx, col, val, CardSpriteCutter.ffTopRightX, CardSpriteCutter.ffTopRightY)
                break;
            case Val.Zehn:
                CardSpriteCutter.bgRectFromFFRect(s, tx, new PIXI.Rectangle(5 * CardSpriteCutter.tcsW, 0 * CardSpriteCutter.tcsH, CardSpriteCutter.tcsW, CardSpriteCutter.tcsH))
                CardSpriteCutter.symFF(s, tx, CardSpriteCutter.ffZehR)
                CardSpriteCutter.ffColSym(s, tx, col, val, CardSpriteCutter.ffTopRightX, CardSpriteCutter.ffTopRightY)
                const py10 = 88;
                const dy10 = 84;
                const ffLeftX = 28
                CardSpriteCutter.ffColSym(s, tx, col, val, ffLeftX, CardSpriteCutter.ffTopRightY + py10 + 0 * dy10)
                CardSpriteCutter.ffColSym(s, tx, col, val, ffLeftX, CardSpriteCutter.ffTopRightY + py10 + 1 * dy10)
                CardSpriteCutter.ffColSym(s, tx, col, val, ffLeftX, CardSpriteCutter.ffTopRightY + py10 + 2 * dy10)
                CardSpriteCutter.ffColSym(s, tx, col, val, ffLeftX, CardSpriteCutter.ffTopRightY + py10 + 3 * dy10)

                CardSpriteCutter.ffColSym(s, tx, col, val, CardSpriteCutter.ffTopRightX, CardSpriteCutter.ffTopRightY + py10 + 0 * dy10)
                CardSpriteCutter.ffColSym(s, tx, col, val, CardSpriteCutter.ffTopRightX, CardSpriteCutter.ffTopRightY + py10 + 1 * dy10)
                CardSpriteCutter.ffColSym(s, tx, col, val, CardSpriteCutter.ffTopRightX, CardSpriteCutter.ffTopRightY + py10 + 2 * dy10)
                CardSpriteCutter.ffColSym(s, tx, col, val, CardSpriteCutter.ffTopRightX, CardSpriteCutter.ffTopRightY + py10 + 3 * dy10)

                CardSpriteCutter.ffColSym(s, tx, col, val, CardSpriteCutter.tcsW * 0.5 - 40, CardSpriteCutter.tcsH * 0.5 - 40)
                break;
            case Val.As:
                CardSpriteCutter.bgRectFromFFRect(s, tx, new PIXI.Rectangle(5 * CardSpriteCutter.tcsW, 0 * CardSpriteCutter.tcsH, CardSpriteCutter.tcsW, CardSpriteCutter.tcsH))
                CardSpriteCutter.symFF(s, tx, CardSpriteCutter.ffAssR)
                CardSpriteCutter.ffColSym(s, tx, col, val, CardSpriteCutter.ffTopRightX, CardSpriteCutter.ffTopRightY)
                CardSpriteCutter.ffColSym(s, tx, col, val, CardSpriteCutter.tcsW * 0.5 - 40, CardSpriteCutter.tcsH * 0.5 - 40)
                CardSpriteCutter.ffColSym(s, tx, col, val, 20, CardSpriteCutter.tcsH - 90)
                break;
        }
    }

    static symFF(s: PIXI.Container, tx: PIXI.Texture, frame: PIXI.Rectangle) {
        CardSpriteCutter.blitter(s, tx, frame, new PIXI.Rectangle(20, 20, frame.width, frame.height))
    }

    static bgRectFromFFRect(s: PIXI.Container, tx: PIXI.Texture, frame: PIXI.Rectangle) {
        CardSpriteCutter.blitter(s, tx, frame, new PIXI.Rectangle(0, 0, CardSpriteCutter.tcsW, CardSpriteCutter.tcsH))
    }

    static ffColSym(s: PIXI.Container, tx: PIXI.Texture, col: Col, val: Val, px: number, py: number) {
        var srcRect;
        switch (col) {
            case Col.Herz:
                srcRect = CardSpriteCutter.ffHerzR;
                break;
            case Col.Karo:
                srcRect = CardSpriteCutter.ffKaroR;
                break;
            case Col.Pik:
                srcRect = CardSpriteCutter.ffPikkR;
                break;
            case Col.Treff:
                srcRect = CardSpriteCutter.ffTrefR;
                break;
        }
        switch (val) {
            case Val.Bub:
            case Val.Dame:
            case Val.Koenig:
                CardSpriteCutter.blitter(s, tx, srcRect, new PIXI.Rectangle(px, py, srcRect.width, srcRect.height))
                break;
            case Val.Zehn:
                CardSpriteCutter.blitter(s, tx, srcRect, new PIXI.Rectangle(px, py, srcRect.width, srcRect.height))
                break;
            case Val.As:
                CardSpriteCutter.blitter(s, tx, srcRect, new PIXI.Rectangle(px, py, srcRect.width, srcRect.height))
                break;
        }
    }

    static ddColSym(s: PIXI.Container, tx: PIXI.Texture, col: Col, val: Val, px: number, py: number) {
        if (col==Col.Invisible){
            return
        }
        var srcRect;
        switch (col) {
            case Col.Herz:
                srcRect = CardSpriteCutter.ddHerzR;
                break;
            case Col.Karo:
                srcRect = CardSpriteCutter.ddScheR;
                break;
            case Col.Pik:
                srcRect = CardSpriteCutter.ddGrueR;
                break;
            case Col.Treff:
                srcRect = CardSpriteCutter.ddEichR;
                break;
        }
        switch (val) {
            case Val.Bub:
            case Val.Dame:
            case Val.Koenig:
                CardSpriteCutter.blitter(s, tx, srcRect, new PIXI.Rectangle(px, py, srcRect.width, srcRect.height))
                break;
            case Val.Zehn:
                CardSpriteCutter.blitter(s, tx, srcRect, new PIXI.Rectangle(px, py, srcRect.width, srcRect.height))
                break;
            case Val.As:
                CardSpriteCutter.blitter(s, tx, srcRect, new PIXI.Rectangle(px, py, srcRect.width, srcRect.height))
                break;
        }
    }

    static blitter(s: PIXI.Container, tx: PIXI.Texture, from: PIXI.Rectangle, to: PIXI.Rectangle, rot: number = 0) {
        const trim = to;
        const rotate = 0;
        const rotatedTexture = new PIXI.Texture({
            source: tx.source,
            frame: from,
            orig: to,
            trim: trim,
            rotate: rotate,
            // scaleMode: 'LINEAR'
        });
        rotatedTexture.source.scaleMode = 'linear';
        const dude = new PIXI.Sprite(rotatedTexture);
        // dude.anchor.set(0.5)
        s.addChild(dude)
    }

    static addShadow(s: PIXI.Container, tx: PIXI.Texture) {
        const shSpr = new PIXI.Container()
        var maskG = new PIXI.Graphics()
        .rect(0, 0, CardSpriteCutter.tcsW, CardSpriteCutter.tcsH)
        .fill(0x000000)

        shSpr.addChild(maskG)
        s.addChild(shSpr)
        CardSpriteCutter.blitter(shSpr, tx, new PIXI.Rectangle(5 * CardSpriteCutter.tcsW, 0 * CardSpriteCutter.tcsH, CardSpriteCutter.tcsW, CardSpriteCutter.tcsH), new PIXI.Rectangle(0, 0, CardSpriteCutter.tcsW, CardSpriteCutter.tcsH))
        maskG.mask = shSpr.getChildAt(1)
        // const shdspr = new PIXI.Sprite()
        shSpr.alpha = 0.2
        shSpr.x = 20
        shSpr.y = 20
        shSpr.visible = false
    }

    static atoutDisplay(s: PIXI.Container, tx: PIXI.Texture, col: Col, isDD: boolean) {
        s.removeChildren()
        switch (col) {
            case Col.Herz:
                CardSpriteCutter.blitter(s, tx, isDD ? CardSpriteCutter.ddHerzR : CardSpriteCutter.ffHerzR, new PIXI.Rectangle(0, 0, (isDD ? CardSpriteCutter.ddHerzR : CardSpriteCutter.ffHerzR).width, (isDD ? CardSpriteCutter.ddHerzR : CardSpriteCutter.ffHerzR).height))
                break;
            case Col.Karo:
                CardSpriteCutter.blitter(s, tx, isDD ? CardSpriteCutter.ddScheR : CardSpriteCutter.ffKaroR, new PIXI.Rectangle(0, 0, (isDD ? CardSpriteCutter.ddScheR : CardSpriteCutter.ffKaroR).width, (isDD ? CardSpriteCutter.ddScheR : CardSpriteCutter.ffKaroR).height))
                break;
            case Col.Pik:
                CardSpriteCutter.blitter(s, tx, isDD ? CardSpriteCutter.ddGrueR : CardSpriteCutter.ffPikkR, new PIXI.Rectangle(0, 0, (isDD ? CardSpriteCutter.ddGrueR : CardSpriteCutter.ffPikkR).width, (isDD ? CardSpriteCutter.ddGrueR : CardSpriteCutter.ffPikkR).height))
                break;
            case Col.Treff:
                CardSpriteCutter.blitter(s, tx, isDD ? CardSpriteCutter.ddEichR : CardSpriteCutter.ffTrefR, new PIXI.Rectangle(0, 0, (isDD ? CardSpriteCutter.ddEichR : CardSpriteCutter.ffTrefR).width, (isDD ? CardSpriteCutter.ddEichR : CardSpriteCutter.ffTrefR).height))
                break;
        }
    }

    static upDownDisplay(s: PIXI.Container) {
        var maskG = new PIXI.Graphics()
        .poly([ new PIXI.Point(-50, 0), new PIXI.Point(50, 0), new PIXI.Point(0, 40) ])
        .fill(0xff9900)
        s.addChild(maskG)
    }
}

export default CardSpriteCutter