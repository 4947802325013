import MyPlatform from "./platform-base"

class PlatformWeb extends MyPlatform {
    say():string {
        return (`i am web ${this.constructor.name}`)
    }

    rootPath():string{
        return '/'
    }

    cmp():HTMLDivElement {
        const element = document.createElement('div');
        element.id = 'all';
        element.innerHTML = `
        <div id="content"></div>
        <div id="menu"></div>
        <div id="header">
            <a href="/home" class="html5history"><div class="logo"></div></a>
        </div>
        <div id="modal"></div>
        <div id="div-gpt-ad-1443956755766-0"></div>`;
        return element;
    }
}

export default new PlatformWeb()