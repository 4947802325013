import App from './app';
import { AppCallbacks } from './app.callbacks';
import { Socket, CMess, SMess } from './socket';
import storage from './utils/storage';
class UserData {
    email?: string;
    name?: string;
    password?: string;
    uid?: number;
    firstname?: string;
    lastname?: string;
    constructor() {
    }
    setFromSMEss(am?: SMess) {
        if (am != null) {
            this.uid = am.data['uid']
            this.email = am.data['email']
            this.name = am.data['name']
            this.password = am.data['password']
            this.firstname = am.data['firstname']
            this.lastname = am.data['lastname']
        }
    }
    setFromVals(uid: number, email: string, password: string, firstname: string, lastname: string) {
        this.uid = uid
        this.email = email
        this.password = password
        this.firstname = firstname
        this.lastname = lastname
    }
}
class Login {
    user?: UserData;
    // email?:string;
    // password?:string;
    device?: string;
    token?: string;
    // uid?:number;
    // token?:string;
    // uid?:number;

    async init(): Promise<boolean> {
        console.log(`${this.constructor.name} init() device:${await storage.get('device')} token:${await storage.get('token')}`)
        this.device = await storage.get('device')
        this.token = await storage.get('token')

        // if ((this.device != null) && (this.token != null)) {
        //     // console.log(`init ${this.constructor.name} device:${this.device} token:${this.token}`);

        //     return true;
        // }

        if (this.device == null) {
            const lm = await App.instance.socket.sendRoundtrip(new CMess('newdevice'))
            // console.log(`init ${this.constructor.name} got device:${lm.data['device']} m:${lm}`)
            this.device = lm.data['device']
            await storage.set('device', this.device)
        }
        if (this.token != null) {
            const to = await App.instance.socket.sendRoundtrip(new CMess('trytoken', { token: this.token }))
            console.log('trytoken')
            await this.loggedin(to)
            if (to != null && to.data['token']) {
                this.loggedin(to);
                App.instance.menu.go();
                App.instance.menu.init(true);
                return true;
            }
        }
        if ((this.user != null) && (this.user.email != null) && (this.user.password != null)) {
            const lm = await App.instance.socket.sendRoundtrip(new CMess('login'))
            //this.user = new UserData()
            //this.user.setFromSMEss(lm)
            if (lm != null && lm.data['token']) {
                await this.loggedin(lm)

                //     await storage.set('password', this.user.password)
                //     await storage.set('email', this.user.email)
                //     await storage.set('token', this.token)
                //     return true;
            }
        }
        return false;
        // this.appCb = acb;
        // this.userData = cacheUtil.get(CacheKey.userdata);
        // if (this.userData){
        //     console.log('Login init found userData', this.userData.email, this.userData.token);
        //     this.appCb.foundStoredUserData(this.userData);
        // }
    }

    async loggedin(am: SMess) {
        // this.userData = new Bar(am.data);
        // console.log(this.constructor.name, am, typeof am, typeof am.data);
        // cacheUtil.set(CacheKey.userdata, this.userData);
        this.user = new UserData()
        this.user.setFromSMEss(am)
        this.token = am.data['token']
        storage.set('token', this.token)
        if (this.user != null) {
            storage.set('uid', this.user.uid)
            storage.set('email', this.user.email)
            storage.set('name', this.user.name)
            storage.set('firstname', this.user.firstname)
            storage.set('lastname', this.user.lastname)
        }
    }

    async save() {
        // this.userData = new Bar(am.data);
        // console.log(this.constructor.name, am, typeof am, typeof am.data);
        // cacheUtil.set(CacheKey.userdata, this.userData);
        storage.set('token', this.token)
        if (this.user != null) {
            storage.set('uid', this.user.uid)
            storage.set('email', this.user.email)
            storage.set('firstname', this.user.firstname)
            storage.set('lastname', this.user.lastname)
        }
    }

    async logoff() {
        // this.userData = null;
        // cacheUtil.remove(CacheKey.userdata);
        this.token = null;
        storage.set('token', null)
        this.user = null;
        storage.set('uid', null)
        storage.set('email', null)
        storage.set('firstname', null)
        storage.set('lastname', null)
    }

    isLoggedIn(): boolean {
        return (this.user != null) && (this.user.uid > 0)
    }
}


export default Login