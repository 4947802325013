import App from "../app";
import { CMess, SMess } from "../socket";
import Ui from "../utils/ui";
import State from "./state";

class StateTurns extends State {
    data: SMess;
    // constructor() {
    //     super();
    // }
    async onEnter(data?:any): Promise<void> {
        this.data = await App.instance.socket.sendRoundtrip(new CMess('turns'));
        if (this.data) {
            await super.onEnter(data);
        }
    }
    async render(): Promise<string> {
        console.log('l', this.data.list);
        return (this.data.list || []).map((l)=>{l.time=new Date(l.time); return l;}).map((l) => `
        <div class="line fixedline" data-id="${l.id}">
            <div class="col1">
                <img src="https://${App.instance.host}/schnapsen-images/turn/${l.icon}" class="listitem-icon">
            </div><!-- col1 -->
            <div class="col2">
                <div>${l.name}</div><!-- name -->
                <div>${Ui.formatTime(l.time)}</div><!-- time -->
            </div><!-- col2 -->
            <div class="col3">
                icon
            </div><!-- col3 -->
        </div><!-- line -->`).join('\n');
    }
    mess(am: SMess): void {
        console.log(this.constructor.name, 'mess', am);
        if (am.cmd == 'turns') {
            this.data = am;
            this.render();
        }
    }
}
export default StateTurns