import { SMess } from "../socket";
import BotBase from "./bot-base";
import GameBot from "./game-bot";
import HomeBot from "./home-bot";
import LoginBot from "./login-bot";
import RobotGameBot from "./robot-game-bot";
import RoomBot from "./room-bot";
import RoomsBot from "./rooms-bot";
import TurnBot from "./turn-bot";

enum RobotStateMessageType {
    LoggedIn,
    TryRobotGame,
    GoToRooms,
    GoToCrowdedRoom,
    InviteOrConfirmRoomGame
}

class RobotSetup {
    playRobotGame: number = 0
    playRoomGame: number = 100
    playTurnGame: number = 0
    loggedin: boolean = false
    game?:any = null
}

class Bot {
    loginBot: BotBase;
    robotGameBot: RobotGameBot;
    turnBot: BotBase;
    roomsBot: BotBase;
    roomBot: BotBase;
    gameBot: GameBot;
    homeBot: BotBase;

    bots: BotBase[]

    setup: RobotSetup = new RobotSetup()

    constructor() {
        this.bots = []
        this.loginBot = new LoginBot(this);
        this.bots.push(this.loginBot)
        this.homeBot = new HomeBot(this);
        this.bots.push(this.homeBot)
        this.robotGameBot = new RobotGameBot(this);
        this.bots.push(this.robotGameBot)
        this.turnBot = new TurnBot(this);
        this.bots.push(this.turnBot)
        this.roomBot = new RoomBot(this);
        this.bots.push(this.roomBot)
        this.roomsBot = new RoomsBot(this);
        this.bots.push(this.roomsBot)
        this.gameBot = new GameBot(this);
        this.bots.push(this.gameBot)
    }

    async dispatch(me: SMess) {
        // console.log(`## Bot dispatch ${me.cmd}`)
        for (const b of this.bots) {
            await b.dispatch(me)
        }
    }

    async dispatchGameMessage(me:SMess){
        this.gameBot.dispatchGameMessage(me)
    }

    async stateMessage(me: RobotStateMessageType, d?: any) {
        for (const b of this.bots) {
            await b.stateMessage(me, d)
        }
    }
}

export { Bot, RobotStateMessageType }