import { keyable } from "./storage";

class FormItem {
    name: string;
    options?: any;
    constructor(name: string, op?: any) {
        this.name = name;
        this.options = op;
    }
    attributes(): string {
        const ret: string[] = [];
        ret.push(`id="${this.name}"`);
        (this.options.placeholder) && ret.push(`placeholder="${this.options.placeholder}"`);
        (this.options.value) && ret.push(`value="${this.options.value}"`);
        return ret.join(' ');
    }
    render(): string {
        return '';
    }
    value(): number | string {
        return $('#' + this.name).val() as string;
    }
    async validate(): Promise<string[]> {
        const errors: string[] = [];
        if (this.options != null) {
            for (const vali of this.options) {
                if ((typeof (vali) == 'object') && (vali.constructor.name == 'RegExp')) {
                    const res = vali.test();
                }
            }
        }
        return errors;
    }
}

class FormItemText extends FormItem {
    constructor(name: string, op?: any) {
        super(name, op);
    }
    render(): string {
        return `<input type="text" ${super.attributes()}>`;
    }
}

class FormItemEmail extends FormItem {
    constructor(name: string, op?: any) {
        super(name, op);
    }
    render(): string {
        return `<input type="email" ${super.attributes()}>`;
    }
}

class FormItemPassword extends FormItem {
    constructor(name: string, op?: any) {
        super(name, op);
    }
    render(): string {
        return `<input type="password" ${super.attributes()}>`;
    }
}

class Form {
    items: FormItem[];
    constructor(items: FormItem[]) {
        this.items = items;
    }
    render(): string {
        return this.items.map((it) => it.render()).join('\n');
    }
    async validate(): Promise<string[]> {
        var errors: string[] = [];
        for (const item of this.items) {
            const err = await item.validate();
            if (err) {
                errors = errors.concat(err);
            }
        }
        return errors;
    }

    read(): keyable {
        const ret:keyable = {};
        for (const item of this.items) {
            ret[item.name]= item.value();
        }
        return ret;
    }
}

export { Form, FormItem, FormItemText, FormItemEmail, FormItemPassword }