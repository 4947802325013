import App from "../app";
import { CMess, SMess } from "../socket";
import State from "./state";

class StateHome extends State {
    data: SMess;
    // constructor(){
    //     super();
    // }
    async onEnter(data?:any): Promise<void> {
        this.data = await App.instance.socket.sendRoundtrip(new CMess('news'));
        console.log('StateHome data', this.data)
        if (this.data) {
            await super.onEnter(data);
        }
    }
    async render():Promise<string>{
        // console.log('StateHome render', `https://${App.instance.host}/schnapsen-images/news/blabla.png`)
        return (this.data.list || []).map((l) => `
        <div class="line" data-id="${l.id}">
            <div class="col1">
                <img src="https://${App.instance.host}/schnapsen-images/news/${l.image?l.image:'def.png'}" class="listitem-icon">
            </div><!-- col1 -->
            <div class="col2">
                <div class="listitem-name">${l.head}</div><!-- name -->
                <div>${l.subhead}</div><!-- mem -->
                <div>${l.content}</div><!-- mem -->
            </div><!-- col2 -->
            
        </div><!-- line -->`).join('\n');
    }
}
export default StateHome