import App from "../app";
import { SMess } from "../socket";
import Utils from "../utils/utils";
import { Bot, RobotStateMessageType } from "./bot";
import BotBase from "./bot-base";

class LoginBot extends BotBase {
    constructor(b: Bot) {
        super(b);
        this.startup()

        // value:location.host=='beta.schnapsen.at'?'poveri@schnapsen.at':'rickie@schnapsen.at'
    }

    async startup() {
        await Utils.timeout(3000)
        if (!this.bot.setup.loggedin) {
            App.instance.menu.goWithUrlChange('/login')
            await Utils.timeout(1000)
            $('input#email').val(`${/Chrome/i.test(window.navigator.userAgent)?'rickie@schnapsen.at':'poveri@schnapsen.at'}`)
            $('input#password').val(`nopass`)
            App.instance.menu.state.click('login')
        }
    }

    async dispatch(me: SMess) {
        switch (me.cmd) {
            case 'loggedin':
                this.bot.setup.loggedin = true;
                console.log(`B* ${this.constructor.name} loggedin`);
                this.bot.stateMessage(RobotStateMessageType.LoggedIn);
                break;
        }
    }
}

export default LoginBot