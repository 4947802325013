enum AppTaskType {
    SMessage,
    GameSMessage,
    GameMoveCards,
    GameSetPlayableDraggable,
    GameSendCard,
    GameStateWait,
    DrawHisAndMyNew,
    GameShowUpDown,
    GameSetShowAtout,
    BotDroppedCard,
    BotZudrehen,
    GameBotTrigger,
    GameBummerlEnd
}

export { AppTaskType }