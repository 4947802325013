class SocketErrorCodes {
    static ename(c: number): string {
        switch (c) {
            case 1000: return 'CLOSE_NORMAL'
            // 1000 indicates a normal closure, meaning that the purpose for which the connection was established has been fulfilled.

            case 1001: return 'CLOSE_GOING_AWAY'
            // 1001 indicates that an endpoint is "going away", such as a server going down or a browser having navigated away from a page.

            case 1002: return 'CLOSE_PROTOCOL_ERROR'
            // 1002 indicates that an endpoint is terminating the connection due to a protocol error.

            case 1003: return 'CLOSE_UNSUPPORTED'
            // 1003 indicates that an endpoint is terminating the connection because it has received a type of data it cannot accept (e.g., an endpoint that understands only text data MAY send this if it receives a binary message).

            case 1004: return 'Reserved'
            // Reserved. The specific meaning might be defined in the future.

            case 1005: return 'CLOSED_NO_STATUS'
            // 1005 is a reserved value and MUST NOT be set as a status code in a Close control frame by an endpoint. It is designated for use in applications expecting a status code to indicate that no status code was actually present.

            case 1006: return 'CLOSE_ABNORMAL'
            // 1006 is a reserved value and MUST NOT be set as a status code in a Close control frame by an endpoint. It is designated for use in applications expecting a status code to indicate that the connection was closed abnormally, e.g., without sending or receiving a Close control frame.

            case 1007: return 'Unsupported payload'
            // 1007 indicates that an endpoint is terminating the connection because it has received data within a message that was not consistent with the type of the message (e.g., non-UTF-8 [RFC3629] data within a text message).

            case 1008: return 'Policy violation'
            // 1008 indicates that an endpoint is terminating the connection because it has received a message that violates its policy. This is a generic status code that can be returned when there is no other more suitable status code (e.g., 1003 or 1009) or if there is a need to hide specific details about the policy.

            case 1009: return 'CLOSE_TOO_LARGE'
            // 1009 indicates that an endpoint is terminating the connection because it has received a message that is too big for it to process.

            case 1010: return 'Mandatory extension'
            // 1010 indicates that an endpoint (client) is terminating the connection because it has expected the server to negotiate one or more extension, but the server didn't return them in the response message of the WebSocket handshake. The list of extensions that are needed SHOULD appear in the /reason/ part of the Close frame. Note that this status code is not used by the server, because it can fail the WebSocket handshake instead.

            case 1011: return 'Server error'
            // 1011 indicates that a server is terminating the connection because it encountered an unexpected condition that prevented it from fulfilling the request.

            case 1012: return 'Service restart'
            // 1012 indicates that the server / service is restarting.

            case 1013: return 'Try again later'
            // 1013 indicates that a temporary server condition forced blocking the client's request.

            case 1014: return 'Bad gateway'
            // 1014 indicates that the server acting as gateway received an invalid response

            case 1015: return 'TLS handshake fail'
            default: return `na:${c}`
        }
    }
}

export default SocketErrorCodes