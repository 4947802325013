import { CMess, Socket, SMess, SocketState } from './socket';
import { Menu } from './menu';
import { AppCallbacks } from './app.callbacks';
import Login from './login';
import Tournament from './game/tournament';
import Game from './game/game';
import Bummerl from './game/bummerl';
import Ui from './utils/ui';
import { Rooms } from './game/rooms';
import { Room } from './game/room';
import StateGame from './state/state.game';
import { AppTaskBase } from './tasks/task.base';
import { AppTaskMessage } from './tasks/tasks.app';
import { Bot } from './bot/bot';
import { GameTaskBummerlEnd } from './tasks/tasks.game';

class InvitationActive {
    constructor(public user: number, public room: string, public date: Date = new Date()) {
    }
}
class InvitationPassive {
    constructor(public user: number, public room: string, public date: Date = new Date()) {
    }
}


class App implements AppCallbacks {
    private static _instance: App | null = null;
    public static get instance(): App {
        if (App._instance == null) {
            console.log(this.constructor.name, 'App._instance was nullo');
            App._instance = new App();
        }
        return App._instance;
    }

    socket?: Socket;
    menu: Menu;
    login: Login;
    turn?: Tournament;
    rooms?: Rooms;
    room?: Room;
    bummerl?: Bummerl;
    host: string = 'beta.schnapsen.at';
    tasks: AppTaskBase[]
    currentTask?: AppTaskBase
    bot: Bot
    invitationsActive: InvitationActive[]
    invitationsPassive: InvitationPassive[]

    constructor() {
        console.log('App cons');
        const that = this
        // const url = new URL("worker.js", import.meta.url);
        // console.log('App window.location.origin', url.toString());

        
        this.bot = new Bot()
        this.menu = new Menu();
        this.login = new Login();
        this.tasks = []
        this.invitationsActive = []
        this.invitationsPassive = []
        setInterval(this.tick.bind(this), 100);
        setInterval(this.cleanupInvitations.bind(this), 5000);
    }

    onBlur() {
        console.log("FFFFFFFFFF FFFFFFFFFF FFFFFFFFFF blur", document.hasFocus());
    }

    onFocus() {
        console.log("FFFFFFFFFF FFFFFFFFFF FFFFFFFFFF focus", document.hasFocus(), SocketState[this.socket!.state]);
    }

    async init() {
        console.log('App init')
        const that = this

        this.socket = new Socket(this)
        await this.menu.init(false)
        this.menu.go('/info')
        window.addEventListener("focus", function () {
            that.onFocus()
        });
        window.addEventListener("blur", function () {
            that.onBlur()
        });
    }

    loggedIn(): void {
        this.menu.init(true);
    }

    cleanupInvitations() {
        const killT = new Date()
        killT.setMinutes(killT.getMinutes() - 1)
        this.invitationsActive = this.invitationsActive.filter(i => i.date < killT)
        this.invitationsPassive = this.invitationsPassive.filter(i => i.date < killT)
    }

    async click(ac: string, tgt: JQuery<HTMLElement>): Promise<void> {
        const spl = ac.trim().split(/[,\s*]|[\s+]/).filter(s => s)
        for (const s of spl) {
            if (s == 'closepopup') {
                Ui.popup_close(ac, tgt)
            } else if (this.menu.state) {
                await this.menu.state.click(s, tgt);
            }
        }
        return null;
    }

    async tick() {
        // console.log(`App tick --------------------------`)
        if (this.menu.state) {
            this.menu.state.tick()
        }
        if (this.currentTask) {
            this.currentTask.tick()
        }
        if (this.currentTask) {
            // console.log('working in task ', this.currentTask.id, AppTaskType[this.currentTask.type])
            return;
        }
        if (this.tasks.length == 0) {
            return;
        }
        this.currentTask = this.tasks.shift()
        try {
            await this.currentTask.fire()
        } catch (efire) {
            console.log(`App * efire`, efire)
        }
    }

    mess(am: SMess): void {
        this.tasks.push(new AppTaskMessage(am))
        console.log(`App mess ${this.tasks.length}`)
    }

    async dispatchMess(am: SMess) {
        if (am.cmd != 'ping') {
            console.log(`App > ${am.cmd}`) //  ${JSON.stringify(am.data)}
        }
        switch (am.cmd) {
            case 'ping':
                break;
            case 'disconnect':
                App.instance.socket?.terminate()
                break;
            case 'turns':
                if (App.instance.menu.state.constructor.name == 'StateTurns') {
                    App.instance.menu.state.mess(am)
                }
                break;
            case 'rooms':
                if (App.instance.menu.state.constructor.name == 'StateRooms') {
                    App.instance.menu.state.mess(am)
                }
                break;
            case 'room':
                if (App.instance.menu.state.constructor.name == 'StateRoom') {
                    App.instance.menu.state.mess(am)
                }
                break;
            // case 'bummerl':
            // case 'warte':
            // case 'zugedreht':
            // case 'stechen':
            // case 'stechen20':
            // case 'ausgetauscht':
            // case 'ausspielen':
            // case 'ausgespielt':
            // case 'wurdegestochenC1':
            // case 'wurdegestochenC2':
            // case 'hastgestochenC1':
            // case 'hastgestochenC2':
            // case 'iwould':
            // case 'turnstart':
            // case 'turnend':
            //   gameBloc.gameMessage(mess: mess);
            //   break;
            // case 'turnalert':
            //   // _blocHolderApp.openPopup();
            //   final locator = GetIt.instance;
            //   final dialogService = locator<DialogService>();
            //   final st = DateTime.parse(Ut.strJ(element: mess.data!, key: 'time'));

            //   if (dialogService.isDialogOpen != null) {
            //     dialogService.completeDialog(DialogResponse<dynamic>(confirmed: false));
            //   }

            //   dialogService.showDialog(
            //     title: 'Turnier Start',
            //     description: 'Turnier ${Ut.strJ(element: mess.data!, key: "name")} startet um ${ColorConstants.dateFormat.format(st.toLocal())}',
            //     dialogPlatform: DialogPlatform.Cupertino,
            //   );
            //   await Future.delayed(const Duration(seconds: 3));
            //   dialogService.completeDialog(DialogResponse<dynamic>(confirmed: false));

            //   break;
            // default:
            //   log('SocketBloc undis ${mess.cmd}');
            // case 'bummerl':
            case 'warte':
            case 'zugedreht':
            case 'stechen':
            case 'stechen20':
            case 'ausgetauscht':
            case 'ausspielen':
            case 'ausgespielt':
            case 'wurdegestochenC1':
            case 'wurdegestochenC2':
            case 'hastgestochenC1':
            case 'hastgestochenC2':
                // case 'iwould':
                if (this.bummerl && this.bummerl.game) {
                    await this.bummerl.game.dispatchInMessage(am)
                    //this.tasks.push(new GameTaskInMessage(this.bummerl.game, am))
                }
                break;
            case 'turnstart':
            case 'continueturn':
                console.log(`> ${am.cmd} id:${am.data.id}`);
                if (this.turn != null) {
                    console.log(`* App turnstart but turn != null id:${am.data['id']} this.id:${this.turn.id}`);
                    return;
                }
                this.turn = new Tournament(am.data['id'])
                break;
            case 'turnend':
                console.log(`> turnend id:${am.data.id}`);
                if (this.turn == null) {
                    console.log(`* App turnend but turn == null id:${am.data['id']}`);
                    return;
                }
                this.turn = null
                break;
            case 'bummerlstart':
            case 'continuebummerl':
                console.log(`> bummerlstart id:${am.data.id}`);
                if (this.bummerl != null) {
                    console.log(`* App bummerlstart but bummerl != null id:${am.data['id']} this.id:${this.bummerl.id}`);
                    return;
                }
                this.bummerl = new Bummerl(am.data['id'])
                await App.instance.menu.go(`/game/${am.data['id']}`)
                break;
            case 'bummerlend':
            case 'timeout':
                console.log(`> ${am.cmd} id:${am.data.id}`);
                // if (this.bummerl == null) {
                //     console.log(`* App bummerlend but bummerl == null id:${am.data['id']}`);
                //     return;
                // }
                // this.bummerl = null
                // if (this.menu.state.link == '/game') {
                //     (this.menu.state as StateGame ).app.stop()
                //     this.menu.goWithUrlChange('/home')
                // }

                this.tasks.push(new GameTaskBummerlEnd(this.bummerl.game, this.bummerl))
                break;
            case 'gamestart':
            case 'continuegame':
                console.log(`> ${am.cmd} id:${am.data.game} ${JSON.stringify(am.data)}`);
                if (this.bummerl == null) {
                    console.log(`* App gamestart but bummerl == null id:${am.data['game']}`);
                    return;
                }
                if (App.instance.menu.state.link == '/game') {
                    this.bummerl.game = new Game(am.data['game'], this.bummerl, App.instance.menu.state as StateGame)
                    this.bummerl.game.dispatchInMessage(am)
                } else {
                    console.log(`* App gamestart but state != StateGame id:${am.data['game']} statename:${App.instance.menu.state.link}`);
                }

                break;
            case 'gameend':
                console.log(`> gameend id:${am.data.game}`);
                if (!this.bummerl.game) {
                    console.log(`* App gameend but game == null id:${am.data['game']}`);
                } else if (this.bummerl.game && (this.bummerl.game.id() != am.data['game'])) {
                    console.log(`* App gameend but game.id != me.id g:${this.bummerl.game.id()} id:${am.data['game']}`);
                }
                this.bummerl.game = null
                break;
            case 'iinvited':
                console.log(`iinvited`, am.data, am.list);
                this.invitationsActive = am.list.map(i => new InvitationActive(i.u, i.room, i.date))
                break;
            case 'gotinvited':
                console.log(`gotinvited`, am.data, am.list);
                this.invitationsPassive = am.list.map(i => new InvitationPassive(i.u, i.room, i.date))
                break;
            default:
                if (this.menu.state) {
                    this.menu.state.mess(am);
                }
        }

        if (this.bot != null) {
            this.bot.dispatch(am)
        }
    }
    // cleanUpGameTasks(){
    //     this() this.g.stage().currentGameTask = null
    // }
    connected(): Promise<void> {
        console.log(this.constructor.name, 'connected');
        this.login.init();
        return null;
    }

}


export default App