import App from "../app";
import { CMess } from "../socket";
import { Form, FormItem, FormItemPassword, FormItemEmail } from "../utils/form";
import Ui from "../utils/ui";
import State from "./state";
import storage from '../utils/storage';

class StateLogin extends State {
    form:Form;
    constructor(link:string){
        super(link);
        this.form = new Form([
            new FormItemEmail('email', {placeholder:'E-Mail'}),
            new FormItemPassword('password', {placeholder:'Password'})
        ]);
    }
    async render(data?:any):Promise<string>{
        return this.form.render()+Ui.button('login');
    }
    async click(ac: string, tgt: JQuery<HTMLElement>=null): Promise<void> {
        switch (ac) {
            case 'login':
                const loginData = this.form.read();
                loginData.device = App.instance.login.device
                console.log(ac, '<s', loginData);
                const ret = await App.instance.socket.sendRoundtrip(new CMess('login', loginData));

                console.log(ac, 'r>', ret);
                if (ret && ret.data && ret.data.token) {
                    App.instance.login.loggedin(ret);
                    App.instance.menu.go('/home');
                    App.instance.menu.init(true);
                }
                
                break;
        }
        return null;
    }
}

export default StateLogin