import ReconnectingWebSocket from 'reconnecting-websocket';
import { AppCallbacks } from './app.callbacks';
import App from './app';
import { keyable } from './utils/storage';
import { Bot } from './bot/bot';
import SocketErrorCodes from './utils/socket.error.codes';

class SMess {
    cmd?: string;
    id?: number;
    data?: keyable;
    list?: keyable[];
    constructor(obj: object) {
        const es6map = new Map(Object.entries(obj));
        this.cmd = es6map.get('cmd');
        this.id = es6map.get('id');
        this.data = es6map.get('data');
        this.list = es6map.get('list');
    }
}

class CMess {
    cmd?: string;
    id?: number = 0;
    data?: keyable;//Map<string, any>;
    constructor(cmd: string, data?: keyable) {//Map<string, any>){
        this.cmd = cmd;
        this.data = data;
    }
    toJson(): any {
        const ret = {
            cmd: this.cmd,
            id: this.id,
            data: this.data
        };
        console.log('CMess toj', ret);
        return ret;
    }
}

enum SocketState {
    def, opened, closed, error
}

class RoundTrip {
    smess?: SMess;
    cmess?: CMess;
    resolve?: (value: SMess | PromiseLike<SMess>) => void;
    promise: Promise<SMess>;
    constructor(am: CMess, resolve: (value: SMess | PromiseLike<SMess>) => void) { // , prom: Promise<SMess>
        this.cmess = am;
        this.resolve = resolve;
        // this.promise = prom;
    }
}

class Socket {
    rws: ReconnectingWebSocket
    rtmessages: RoundTrip[]
    lastMessageId: number
    app: AppCallbacks
    // bot?: Bot
    terminated: boolean = false
    firstConnectionEstablished: boolean = false
    // erranous: boolean = false
    reloadOnSocketError: boolean = false
    lastOpen: number
    state: SocketState = SocketState.def
    constructor(app: AppCallbacks) {
        const that = this
        this.app = app;
        this.lastMessageId = 1;
        this.rtmessages = [];
        // this.bot = new Bot()
        this.rws = new ReconnectingWebSocket(`wss://${App.instance.host}/api/ws`);
        this.rws.addEventListener('open', () => {
            console.log('wss opened', new Date());
            this.lastOpen = Date.now()
            // this.rws.send('hello!');
            if (!this.firstConnectionEstablished) {
                this.firstConnectionEstablished = true
                app.connected();
            } else if (((that.state == SocketState.error) || (that.state == SocketState.closed)) && this.reloadOnSocketError) {
                window.location.reload()
            }
            that.state = SocketState.opened
        });
        this.rws.addEventListener('message', (me: MessageEvent<any>) => {
            try {
                // console.log('wss mess', me);
                if (me.data == `{"cmd":"ping"}`) {
                    console.log('wss returning', me.data);
                    return this.rws.send(me.data)
                }
                if (me && me.data) {
                    const sm = new SMess(JSON.parse(me.data));
                    console.log('ws m', sm.cmd);

                    const rtm = this.rtmessages.find((m) => m.cmess.id == sm.id);
                    if (rtm) {
                        this.rtmessages = this.rtmessages.filter((m) => m.cmess.id != sm.id);
                        console.log('rtmess returned', sm);
                        rtm.resolve(sm);
                        // rtm.promise.then(sm);

                        if (App.instance.bot != null) {
                            App.instance.bot.dispatch(sm)
                        }

                    } else {
                        App.instance.mess(sm);
                    }
                }
            } catch (e) {
                console.log('wss mess *', e);
            }
        });
        this.rws.addEventListener('close', (me: CloseEvent) => {
            console.log('wss closed', new Date(), SocketErrorCodes.ename(me.code), Date.now() - this.lastOpen);
            that.state = SocketState.closed
        });
        this.rws.addEventListener('error', (me: ErrorEvent) => {
            console.log('wss error', me);
            // this.rws.send('hello!');
            that.state = SocketState.error
        });
    }

    send(am: CMess) {
        console.log('send');
        am.id = 0;
        this.rws.send(JSON.stringify(am.toJson()));
    }

    async sendRoundtrip(am: CMess): Promise<SMess> {
        am.id = (this.lastMessageId++);
        const promise = new Promise<SMess>((resolve, reject) => {
            this.rtmessages.push(new RoundTrip(am, resolve));
            this.rws.send(JSON.stringify(am.toJson()));
        });
        return promise;
    }

    terminate() {
        this.terminated = true;
        this.rws.close();
    }
}


export { Socket, CMess, SMess, SocketState }