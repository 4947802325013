import { keyable } from "./storage";

class Strings {
    static s(str: string, ex?: any): string {
        var r: string = ''
        if (ex != null) {
            r = `${ex}`
        }
        console.log('Strings.s', Strings.strings[str], str)
        return (Strings.strings[str] || str).replace('%s', r);
    }
    static strings: keyable = {
        error: 'Fehler!',
        notloggedin: 'Du bist nicht angemeldet',
        logoff: 'Abmelden',
        roomfull: 'Derzeit sind zu viele Spieler im Raum'
    }
}

export default Strings