import * as PIXI from 'pixi.js'
import { Col, Val } from './game.card.enums';
import CardSpriteCutter from './cardsprite_cutter';
import { CardIndx } from './game';

class CardSprite extends PIXI.Container {
    // _sprite: PIXI.Container
    private _col: Col;
    public get col(): Col {
        return this._col;
    }
    public set col(value: Col) {
        this._col = value;
    }
    private _val: Val;
    public get val(): Val {
        return this._val;
    }
    public set val(value: Val) {
        this._val = value;
    }
    _index: CardIndx
    _sortPos: CardIndx

    _draggable:boolean= false;
    _animating:boolean = false;

    _app:PIXI.Application

    constructor(index:number, app:PIXI.Application) {
        super()
        this._index = index
        this._sortPos = index
        this._col = Col.Herz
        this._val = Val.Bub
        this.visible = false;
        this._app = app
    }

    async init() {

    }

    async show(texture:PIXI.Texture, col:Col, val:Val, isdd:boolean) {
        // if (this._index>=CardIndx.h0 && this._index<=CardIndx.h4) {
            //  console.log(`CardSprite ${this._index} show ${col}-${val}`)
        // }
        this._col = col;
        this._val = val
        this.visible = (col != Col.Invisible)
        await CardSpriteCutter.show(this, texture, col, val, isdd);
    }
}

export default CardSprite










// class SchnapsenCard extends PositionComponent with DragCallbacks, TapCallbacks, DoubleTapCallbacks, HasWorldReference<SchnapsenWorld> {
//     SchnapsenCard(Rank intRank, Suit intSuit)
//         : rank = intRank,
//           suit = intSuit,
//           super(
//             size: SchnapsenGame.cardSize,
//           );
  
//     factory SchnapsenCard.fromString(String as) {
//       List<String> spl = as.split('-');
//       // log('Card.fromString $as ${Suit.fromString(spl[0]).value}  ${Rank.fromString(spl[1]).value}');
//       return SchnapsenCard(Rank.fromString(spl[1]), Suit.fromString(spl[0]));
//     }
  
//     final Rank rank;
//     final Suit suit;
  
//     bool _faceUp = false;
//     bool _isAnimatedFlip = false;
//     bool _isFaceUpView = false;
//     bool _isDragging = false;
//     bool _isDraggable = false;
//     bool _isPlayable = false;
//     Vector2 _whereCardStarted = Vector2(0, 0);
  
//     bool equals(SchnapsenCard c) {
//       return ((suit.value == c.suit.value) && (rank.value == c.rank.value));
//     }
  
//     bool equalsColor(SchnapsenCard c) {
//       return (suit.value == c.suit.value);
//     }
  
//     set isDraggable(bool isp) {
//       _isDraggable = isp;
//     }
  
//     set isPlayable(bool isp) {
//       // log('isPlayable $isp');
//       _isPlayable = isp;
//     }
  
//     bool get isFaceUp => _faceUp;
//     bool get isFaceDown => !_faceUp;
//     void flip() {
//       if (_isAnimatedFlip) {
//         // Let the animation determine the FaceUp/FaceDown state.
//         _faceUp = _isFaceUpView;
//       } else {
//         // No animation: flip and render the card immediately.
//         _faceUp = !_faceUp;
//         _isFaceUpView = _faceUp;
//       }
//     }
  
//     @override
//     String toString() => rank.label + suit.label; // e.g. "Q♠" or "10♦"
  
//     String toJson() => '${suit.fs}-${rank.fs}';
  
//     //#region Card-Dragging
  
//     @override
//     void onTapCancel(TapCancelEvent event) {
//       // if (pile is StockPile) {
//       //   _isDragging = false;
//       //   handleTapUp();
//       // }
//       // _whereCardStarted = position.clone();
//       // doMove(
//       //   _whereCardStarted,
//       //   onComplete: () {
//       //     // pile!.returnCard(this);
//       //     // Card moves to its Foundation Pile next, if valid, or it stays put.
//       //     handleTapUp();
//       //   },
//       // );
//     }
  
//     @override
//     void onDragStart(DragStartEvent event) {
//       super.onDragStart(event);
//       // log('onDragStart $_isDraggable');
  
//       if (!_isDraggable) {
//         return;
//       }
  
//       // table = world.tableRect;
//       // atout = world.game.atout;
  
//       priority = 1000;
//       _isDragging = true;
//       _whereCardStarted = position.clone();
//     }
  
//     bool collidesWithTable = false;
//     bool collidesWithAtout = false;
  
//     isWithin(Vector2 p) {
//       Vector2 halfsize = size / 2.0;
//       return p.x > position.x - halfsize.x && p.x < position.x + halfsize.x && p.y > position.y - halfsize.y && p.y < position.y + halfsize.y;
//     }
  
//     @override
//     void onDragUpdate(DragUpdateEvent event) {
//       // log('onDragUpdate $_isDragging');
  
//       if (!_isDragging) {
//         return;
//       }
//       final delta = event.localDelta;
//       final halfSize = size / 2.0;
//       position.add(delta);
//       Table table = world.tableRect;
//       if (table.isWithin(position + halfSize) && (world.game.ausspielenPossible(this) || world.game.stechenPossible(this))) {
//         if (!collidesWithTable) {
//           collidesWithTable = true;
//           log('colli on');
//           table.hilite(true);
//         }
//       } else {
//         if (collidesWithTable) {
//           collidesWithTable = false;
//           log('colli off');
//            table.hilite(false);
//         }
  
//         Vector2 pat = SchnapsenWorld.cardPositions[CardIndx.at.index];
//         bool withinAtout = (position.x > (pat.x - halfSize.x)) && (position.x < (pat.x + halfSize.x)) && (position.y > (pat.y - halfSize.y)) && (position.y < (pat.y + halfSize.y));
//         if (withinAtout && world.game.austauschenPossible(this)) {
//           if (!collidesWithAtout) {
//             collidesWithAtout = true;
//             log('colli at on');
//           }
//         } else {
//           if (collidesWithAtout) {
//             collidesWithAtout = false;
//             log('colli at off');
//           }
//         }
//       }
//     }
  
//     @override
//     void onDragEnd(DragEndEvent event) {
//       super.onDragEnd(event);
//       if (!_isDragging) {
//         return;
//       }
//       priority = 1;
  
//       _isDragging = false;
  
//       // If short drag, return card to Pile and treat it as having been tapped.
//       final shortDrag = (position - _whereCardStarted).length < SchnapsenGame.dragTolerance;
//       if (shortDrag) {
//         // && attachedCards.isEmpty) {
//         doMove(
//           _whereCardStarted,
//           onComplete: () {
//             // pile!.returnCard(this);
//             // Card moves to its Foundation Pile next, if valid, or it stays put.
//             handleTapUp();
//           },
//         );
//         return;
//       }
  
//       // var atDelta = SchnapsenWorld.cardPositions[CardIndx.at.index] / 2 - position;
//       if (_isPlayable && collidesWithAtout && world.game.austauschenPossible(this)) {
//         world.game.austauschen(this);
//         return;
//       }
  
  
  
//       if (_isPlayable && collidesWithTable && (world.game.ausspielenPossible(this) || world.game.stechenPossible(this))) {
//         world.tableRect.hilite(false);
//         if (world.game.ausspielenPossible(this)) {
//           // world.game.setHandPlayable(false);
//           // world.game.sendZug(this, "ausspielen");
//           world.game.ausspielen(this);
//           return;
//         } else if (world.game.stechenPossible(this)) {
//           // world.game.setHandPlayable(false);
//           // world.game.sendZug(this, "stechen");
//           world.game.stechen(this);
//           return;
//         }
//       }
//       // Invalid drop (middle of nowhere, invalid pile or invalid card for pile).
//       doMove(
//         _whereCardStarted,
//         onComplete: () {
//           // pile!.returnCard(this);
//           // log('doMove onComplete');
//         },
//       );
  
//     }
  
  
//     @override
//     void onTapUp(TapUpEvent event) {
//       handleTapUp();
//     }
  
//     void handleTapUp() {
//       log('handleTapUp');
  
//     }
  
//     //#endRegion
  
//     //#region Effects
  
//     void doMove(
//       Vector2 to, {
//       double speed = 30.0,
//       double start = 0.0,
//       int startPriority = 100,
//       Curve curve = Curves.easeOutQuad,
//       VoidCallback? onComplete,
//     }) {
//       assert(speed > 0.0, 'Speed must be > 0 widths per second');
//       final dt = (to - position).length / (speed * size.x);
//       assert(dt > 0, 'Distance to move must be > 0');
//       assert(parent != null);
//       // log('doMove dt:$dt ${parent}');
//       add(
//         CardMoveEffect(
//           to,
//           EffectController(duration: dt, startDelay: start, curve: curve),
//           transitPriority: startPriority,
//           onComplete: () {
//             onComplete?.call();
//           },
//         ),
//       );
//     }
  
//     Future<void> doMoveAsyPos(CardIndx? from, CardIndx? to) async {
//       log('doMoveAsyPos $from $to');
//       assert(parent != null);
//       Completer<void> c = Completer<void>();
//       if (from != null) position = SchnapsenWorld.cardPositions[from.index];
//       doMove(SchnapsenWorld.cardPositions[to!.index], onComplete: () {
//         log('doMoveAsyPos onComplete');
//         c.complete();
//       });
//       return c.future;
//     }
  
  
//     void doMoveAndFlip(
//       Vector2 to, {
//       double speed = 10.0,
//       double start = 0.0,
//       Curve curve = Curves.easeOutQuad,
//       VoidCallback? whenDone,
//     }) {
//       assert(speed > 0.0, 'Speed must be > 0 widths per second');
//       final dt = (to - position).length / (speed * size.x);
//       assert(dt > 0, 'Distance to move must be > 0');
//       priority = 100;
//       add(
//         MoveToEffect(
//           to,
//           EffectController(duration: dt, startDelay: start, curve: curve),
//           onComplete: () {
//             turnFaceUp(
//               onComplete: whenDone,
//             );
//           },
//         ),
//       );
//     }
  
//     void turnFaceUp({
//       double time = 0.3,
//       double start = 0.0,
//       VoidCallback? onComplete,
//     }) {
//       assert(!_isFaceUpView, 'Card must be face-down before turning face-up.');
//       assert(time > 0.0, 'Time to turn card over must be > 0');
//       assert(start >= 0.0, 'Start tim must be >= 0');
//       _isAnimatedFlip = true;
//       anchor = Anchor.topCenter;
//       position += Vector2(width / 2, 0);
//       priority = 100;
//       add(
//         ScaleEffect.to(
//           Vector2(scale.x / 100, scale.y),
//           EffectController(
//             startDelay: start,
//             curve: Curves.easeOutSine,
//             duration: time / 2,
//             onMax: () {
//               _isFaceUpView = true;
//             },
//             reverseDuration: time / 2,
//             onMin: () {
//               _isAnimatedFlip = false;
//               _faceUp = true;
//               anchor = Anchor.topLeft;
//               position -= Vector2(width / 2, 0);
//             },
//           ),
//           onComplete: () {
//             onComplete?.call();
//           },
//         ),
//       );
//     }
  
  
  
//     @override
//     void render(Canvas canvas) {
//       if (_isFaceUpView) {
//         _renderFront(canvas);
//       } else {
//         _renderBack(canvas);
//       }
//     }
  
//     static final Paint backBackgroundPaint = Paint()..color = const Color(0xff380c02);
//     static final Paint backBorderPaint1 = Paint()
//       ..color = const Color(0xffdbaf58)
//       ..style = PaintingStyle.stroke
//       ..strokeWidth = 10;
//     static final Paint backBorderPaint2 = Paint()
//       ..color = const Color(0x5CEF971B)
//       ..style = PaintingStyle.stroke
//       ..strokeWidth = 35;
//     static final RRect cardRRect = RRect.fromRectAndRadius(
//       SchnapsenGame.cardSize.toRect(),
//       const Radius.circular(SchnapsenGame.cardRadius),
//     );
//     static final RRect backRRectInner = cardRRect.deflate(40);
//     static final Sprite flameSprite = schnapsenSprite(1367, 6, 357, 501);
  
//     void _renderBack(Canvas canvas) {
//       canvas.drawRRect(cardRRect, backBackgroundPaint);
//       canvas.drawRRect(cardRRect, backBorderPaint1);
//       canvas.drawRRect(backRRectInner, backBorderPaint2);
//       flameSprite.render(canvas, position: size / 2, anchor: Anchor.center);
//     }
  
//     static final Paint frontBackgroundPaint = Paint()..color = const Color.fromARGB(255, 255, 255, 255);
  
//     static final Paint blackBorderPaint = Paint()
//       ..color = const Color.fromARGB(255, 0, 0, 0)
//       ..style = PaintingStyle.stroke
//       ..strokeWidth = 10;
//     static final Sprite redJack = schnapsenSprite(81, 565, 562, 488);
//     static final Sprite redQueen = schnapsenSprite(717, 541, 486, 515);
//     static final Sprite redKing = schnapsenSprite(1305, 532, 407, 549);
//     static final Sprite blackJack = schnapsenSprite(81, 565, 562, 488);
//     static final Sprite blackQueen = schnapsenSprite(717, 541, 486, 515);
//     static final Sprite blackKing = schnapsenSprite(1305, 532, 407, 549);
  
//     void _renderFront(Canvas canvas) {
//       canvas.drawRRect(cardRRect, frontBackgroundPaint);
//       canvas.drawRRect(
//         cardRRect,
//         blackBorderPaint,
//       );
  
//       final rankSprite = suit.isBlack ? rank.blackSprite : rank.redSprite;
//       final suitSprite = suit.sprite;
//       _drawSprite(canvas, rankSprite, 0.1, 0.08);
//       _drawSprite(canvas, suitSprite, 0.1, 0.18, scale: 0.5);
//       _drawSprite(canvas, rankSprite, 0.1, 0.08, rotate: true);
//       _drawSprite(canvas, suitSprite, 0.1, 0.18, scale: 0.5, rotate: true);
//       switch (rank.value) {
//         case 1:
//           _drawSprite(canvas, suitSprite, 0.5, 0.5, scale: 2.5);
//         case 2:
//           _drawSprite(canvas, suitSprite, 0.5, 0.25);
//           _drawSprite(canvas, suitSprite, 0.5, 0.25, rotate: true);
//         case 3:
//           _drawSprite(canvas, suitSprite, 0.5, 0.2);
//           _drawSprite(canvas, suitSprite, 0.5, 0.5);
//           _drawSprite(canvas, suitSprite, 0.5, 0.2, rotate: true);
//         case 4:
//           _drawSprite(canvas, suitSprite, 0.3, 0.25);
//           _drawSprite(canvas, suitSprite, 0.7, 0.25);
//           _drawSprite(canvas, suitSprite, 0.3, 0.25, rotate: true);
//           _drawSprite(canvas, suitSprite, 0.7, 0.25, rotate: true);
//         case 5:
//           _drawSprite(canvas, suitSprite, 0.3, 0.25);
//           _drawSprite(canvas, suitSprite, 0.7, 0.25);
//           _drawSprite(canvas, suitSprite, 0.3, 0.25, rotate: true);
//           _drawSprite(canvas, suitSprite, 0.7, 0.25, rotate: true);
//           _drawSprite(canvas, suitSprite, 0.5, 0.5);
//         case 6:
//           _drawSprite(canvas, suitSprite, 0.3, 0.25);
//           _drawSprite(canvas, suitSprite, 0.7, 0.25);
//           _drawSprite(canvas, suitSprite, 0.3, 0.5);
//           _drawSprite(canvas, suitSprite, 0.7, 0.5);
//           _drawSprite(canvas, suitSprite, 0.3, 0.25, rotate: true);
//           _drawSprite(canvas, suitSprite, 0.7, 0.25, rotate: true);
//         case 7:
//           _drawSprite(canvas, suitSprite, 0.3, 0.2);
//           _drawSprite(canvas, suitSprite, 0.7, 0.2);
//           _drawSprite(canvas, suitSprite, 0.5, 0.35);
//           _drawSprite(canvas, suitSprite, 0.3, 0.5);
//           _drawSprite(canvas, suitSprite, 0.7, 0.5);
//           _drawSprite(canvas, suitSprite, 0.3, 0.2, rotate: true);
//           _drawSprite(canvas, suitSprite, 0.7, 0.2, rotate: true);
//         case 8:
//           _drawSprite(canvas, suitSprite, 0.3, 0.2);
//           _drawSprite(canvas, suitSprite, 0.7, 0.2);
//           _drawSprite(canvas, suitSprite, 0.5, 0.35);
//           _drawSprite(canvas, suitSprite, 0.3, 0.5);
//           _drawSprite(canvas, suitSprite, 0.7, 0.5);
//           _drawSprite(canvas, suitSprite, 0.3, 0.2, rotate: true);
//           _drawSprite(canvas, suitSprite, 0.7, 0.2, rotate: true);
//           _drawSprite(canvas, suitSprite, 0.5, 0.35, rotate: true);
//         case 9:
//           _drawSprite(canvas, suitSprite, 0.3, 0.2);
//           _drawSprite(canvas, suitSprite, 0.7, 0.2);
//           _drawSprite(canvas, suitSprite, 0.5, 0.3);
//           _drawSprite(canvas, suitSprite, 0.3, 0.4);
//           _drawSprite(canvas, suitSprite, 0.7, 0.4);
//           _drawSprite(canvas, suitSprite, 0.3, 0.2, rotate: true);
//           _drawSprite(canvas, suitSprite, 0.7, 0.2, rotate: true);
//           _drawSprite(canvas, suitSprite, 0.3, 0.4, rotate: true);
//           _drawSprite(canvas, suitSprite, 0.7, 0.4, rotate: true);
//         case 10:
//           _drawSprite(canvas, suitSprite, 0.3, 0.2);
//           _drawSprite(canvas, suitSprite, 0.7, 0.2);
//           _drawSprite(canvas, suitSprite, 0.5, 0.3);
//           _drawSprite(canvas, suitSprite, 0.3, 0.4);
//           _drawSprite(canvas, suitSprite, 0.7, 0.4);
//           _drawSprite(canvas, suitSprite, 0.3, 0.2, rotate: true);
//           _drawSprite(canvas, suitSprite, 0.7, 0.2, rotate: true);
//           _drawSprite(canvas, suitSprite, 0.5, 0.3, rotate: true);
//           _drawSprite(canvas, suitSprite, 0.3, 0.4, rotate: true);
//           _drawSprite(canvas, suitSprite, 0.7, 0.4, rotate: true);
//         case 11:
//           _drawSprite(canvas, suit.isRed ? redJack : blackJack, 0.5, 0.5);
//         case 12:
//           _drawSprite(canvas, suit.isRed ? redQueen : blackQueen, 0.5, 0.5);
//         case 13:
//           _drawSprite(canvas, suit.isRed ? redKing : blackKing, 0.5, 0.5);
//       }
//     }
  
//     void _drawSprite(
//       Canvas canvas,
//       Sprite sprite,
//       double relativeX,
//       double relativeY, {
//       double scale = 1,
//       bool rotate = false,
//     }) {
//       if (rotate) {
//         canvas.save();
//         canvas.translate(size.x / 2, size.y / 2);
//         canvas.rotate(3.141592654);
//         canvas.translate(-size.x / 2, -size.y / 2);
//       }
//       sprite.render(
//         canvas,
//         position: Vector2(relativeX * size.x, relativeY * size.y),
//         anchor: Anchor.center,
//         size: sprite.srcSize.scaled(scale),
//       );
//       if (rotate) {
//         canvas.restore();
//       }
//     }
  
//     //#endregion
  
  
  
  
  
  
//     @override
//     void onDoubleTapUp(DoubleTapEvent event) {
//       /// Do something
//       if ((world.game.atoutCard != null) && equals(world.game.atoutCard!) && world.game.zudrehenPossible()) {
//         world.game.zudrehen();
//       }
//     }
  
//     @override
//     void onDoubleTapCancel(DoubleTapCancelEvent event) {
//       /// Do something
//     }
  
//     @override
//     void onDoubleTapDown(DoubleTapDownEvent event) {
//       /// Do something
//     }
//   }
  
//   class CardMoveEffect extends MoveToEffect {
//     CardMoveEffect(
//       super.destination,
//       super.controller, {
//       super.onComplete,
//       this.transitPriority = 100,
//     });
  
//     final int transitPriority;
  
//     @override
//     void onStart() {
//       super.onStart();
//       parent?.priority = transitPriority;
//     }
//   }
  