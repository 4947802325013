// enum TaskType {
//     InMessage, DrawCards
// }

import App from "../app"
import Bummerl from "../game/bummerl"
import Game, { CardIndx } from "../game/game"
import Card from "../game/game.card"
import { Col, Val } from "../game/game.card.enums"
import { CMess, SMess } from "../socket"
import StateGame, { UpDownDir } from "../state/state.game"
import Utils from "../utils/utils"
import { AppTaskBase } from "./task.base"
import { AppTaskType } from "./task.type"
import * as PIXI from 'pixi.js'

class GameTaskBase extends AppTaskBase {
    g: Game
    constructor(g: Game, t: AppTaskType) {
        super(t)
        this.g = g
    }
    async fire() {
        console.log(`${this.constructor.name}`)
    }
    gameTaskDone() {
        if (this.g){
            this.g.stage().currentGameTask = null
        }
        App.instance.currentTask = null
    }
}

class CardAni {
    card: Card
    constructor(public index: CardIndx, public from: CardIndx = null, public to: CardIndx = null, card: Card = null) { // , public afterPos: CardIndx = null, public afterFace: Card = null
        this.card = card ? new Card(card.col, card.val) : null
    }
}

class GameTaskMoveCard extends GameTaskBase {
    ani: CardAni
    t0: number
    p0: PIXI.Point
    p1: PIXI.Point
    duration: number = 1000

    constructor(g: Game, mc: CardAni, public info: string) {
        super(g, AppTaskType.GameMoveCards)
        this.ani = mc
        const crd = this.g.stage().card(this.ani.index)
        this.p0 = mc.from !== null ? this.g.stage().cpos[this.ani.from] : crd.position
        this.p1 = this.g.stage().cpos[this.ani.to !== null ? this.ani.to : crd._sortPos]
        this.duration = Math.sqrt((this.p1.x - this.p0.x) * (this.p1.x - this.p0.x) + (this.p1.y - this.p0.y) * (this.p1.y - this.p0.y)) * 0.2
    }

    async fire() {
        this.t0 = Date.now()
        this.g.stage().currentGameTask = this
        const c = this.g.stage().card(this.ani.index)
        c._draggable = false
        if (this.ani.from !== null) {
            c.position = this.g.stage().cpos[this.ani.from]
        }
        if (this.ani.card !== null) {
            c.show(this.g.stage().cardsTexture, this.ani.card.col, this.ani.card.val, this.g.isdd())
        }
        if (this.ani.from == this.ani.to) {
            c.position = this.g.stage().cpos[this.ani.to]
            this.gameTaskDone()
        }
    }

    tick() {
        const now = Date.now()
        const card = this.g.stage().card(this.ani.index)
        const topos = this.p1 //g.stage().cpos[this.ani.to]
        card.x = Utils.interpolate(this.p0.x, topos.x, this.t0, this.duration, now)
        card.y = Utils.interpolate(this.p0.y, topos.y, this.t0, this.duration, now)
        if ((now - this.t0) >= this.duration) {
            card.x = topos.x
            card.y = topos.y
            this.gameTaskDone()
        }
    }
}

class GameTaskShowAtout extends GameTaskBase {
    constructor(g: Game, private col: Col) {
        super(g, AppTaskType.GameSetShowAtout)
    }

    async fire() {
        this.g.stage().showAtout(this.col)
        this.gameTaskDone()
    }
}

class GameTaskWait extends GameTaskBase {
    constructor(g: Game, private duration: number) {
        super(g, AppTaskType.GameStateWait)
    }

    async fire() {
        setTimeout(() => {
            this.gameTaskDone()
        }, this.duration)
    }
}

class GameTaskSetUpDown extends GameTaskBase {
    constructor(g: Game, private updown: UpDownDir) {
        super(g, AppTaskType.GameShowUpDown)
    }

    async fire() {
        this.g.stage().setUpDown(this.updown)
        this.gameTaskDone()
    }
}

class GameTaskSetDraggablePlayable extends GameTaskBase {
    constructor(g: Game, private m: SMess) {
        super(g, AppTaskType.GameSetPlayableDraggable)
    }

    async fire() {
        if (this.m) {
            switch (this.m.cmd) {
                case 'ausspielen':
                case 'stechen':
                case 'stechen20':
                case 'hastgestochenC1':
                case 'hastgestochenC2':
                    for (var i = 0; i < 5; i++) {
                        const crd = this.g.stage().card(CardIndx.my0 + i)
                        if (crd.col != Col.Invisible) {
                            crd._draggable = true
                        }
                    }
                    break;
                case 'warte':
                case 'wurdegestochenC1':
                case 'wurdegestochenC2':
                    for (var i = 0; i < 5; i++) {
                        const crd = this.g.stage().card(CardIndx.my0 + i)
                        crd._draggable = ((crd.col != Col.Invisible) ? true : false)
                    }
                    break
                default:
                    for (var i = 0; i < 5; i++) {
                        const crd = this.g.stage().card(CardIndx.my0 + i)
                        crd._draggable = false
                    }
            }
        } else {
            console.log(`GameTaskSetDraggablePlayable ! this.m`)
        }
        this.gameTaskDone()
    }
}

class GameTaskBotTrigger extends GameTaskBase {
    constructor(g: Game, private me:SMess) {
        super(g, AppTaskType.GameBotTrigger)
    }

    async fire() {
        App.instance.bot.dispatchGameMessage(this.me)
        this.gameTaskDone()
    }
}

class GameTaskDrawHisAndMyNew extends GameTaskBase {
    constructor(g: Game, private meFirst: boolean, private card: Card, private decksize:number) {
        super(g, AppTaskType.DrawHisAndMyNew)
    }

    async fire() {
        this.g.drawHisAndMyNew(this.card, this.meFirst, this.decksize)
        this.gameTaskDone()
    }
}

class GameTaskSendCard extends GameTaskBase {
    constructor(g: Game, private cmd: string, private card: Card) {
        super(g, AppTaskType.GameSendCard)
    }

    async fire() {
        const ret = await App.instance.socket.sendRoundtrip(new CMess(this.cmd, { card: this.card ? Card.json(this.card) : null, game: this.g.id() }))
        if (ret && ret.data && ret.data.error) {
            console.log(`GameTaskSendCard * ${this.cmd} ${ret.data.error}`)
        }
        this.gameTaskDone()
    }
}

class GameTaskBummerlEnd extends GameTaskBase {
    constructor(g: Game, private bummerl:Bummerl) {
        super(g, AppTaskType.GameBummerlEnd)
    }

    async fire() {
        if (this.bummerl == null) {
            console.log(`* App bummerlend but bummerl == null}`);
            return;
        }
        App.instance.bummerl = null
        
        if (App.instance.menu.state.link == '/game') {
            (App.instance.menu.state as StateGame ).app.stop()
            App.instance.menu.goWithUrlChange('/home')
        }
        this.gameTaskDone()
    }
}

export {
    // GameTaskInMessage,
    GameTaskMoveCard,
    GameTaskBase,
    CardAni,
    GameTaskShowAtout,
    GameTaskSetDraggablePlayable,
    GameTaskSendCard,
    GameTaskWait,
    GameTaskSetUpDown,
    GameTaskDrawHisAndMyNew,
    GameTaskBotTrigger,
    GameTaskBummerlEnd
}