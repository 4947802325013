class MyPlatform {
    say():string {
        return (`i am base ${this.constructor.name}`)
    }
    rootPath():string{
        return '/base'
    }
    cmp():HTMLDivElement {
        const element = document.createElement('div');
        element.id = 'all';
        element.innerHTML = ''
        return element;
    }
}

export default MyPlatform