import App from "../app";
import CardSprite from "../game/cardsprite";
import Game, { CardIndx } from "../game/game";
import { Col, Val } from "../game/game.card.enums";
import { CardAni, GameTaskBase, GameTaskMoveCard, GameTaskSendCard, GameTaskSetDraggablePlayable, GameTaskWait } from "../tasks/tasks.game";
import State from "./state";
import * as PIXI from 'pixi.js'
import { Texi } from "../game/game.card.texture";
import CardSpriteCutter from "../game/cardsprite_cutter";
import Card from "../game/game.card";

enum TextFileds {
    MyName=0, HisName=1, MyBummerlPoints=2, HisBummerlPoints=3, MyGamePoints=4, HisGamePoints=5, AtoutDisplay=6, InfoDisplay=7
}

enum UpDownDir {
    Up, Off, Down
}

class StateGame extends State {
    app: PIXI.Application;
    _cards: CardSprite[];
    cardsLayer: PIXI.Container
    textLayer: PIXI.Container
    debugLayer: PIXI.Container
    upDown:PIXI.Container
    static tcsS: number = 0.25;
    cpos:PIXI.Point[]
    tpos:PIXI.Point[]
    texts:PIXI.Text[]
    dragTarget:any
    dragOffset:PIXI.Point
    currentGameTask: GameTaskBase
    cardsTexture: PIXI.Texture
    myCardsRect:PIXI.Rectangle
    tableRect:PIXI.Rectangle
    atoutRect:PIXI.Rectangle
    cardDX:number
    cardSenseMarginRel:number
    cardsPosX:number
    myCardsPosY: number
    hisCardsPosY: number
    cardsWidth:number
    cardsHeight:number
    static readonly debugAlphaOn = 0.4
    static readonly debugAlphaOff = 0.1
    static readonly ofscreenTickInterval = 300
    ticker:PIXI.Ticker
    maxFPS: number = 60
    frameT: number = 1000/this.maxFPS
    workerJumpInT: number = 10 * this.frameT

    async onEnter(data?:any): Promise<void> {
        console.log('onEnter', this.constructor.name)
        await super.onEnter(data);

        const that = this

        this._cards = new Array(CardIndx.t1)
    
        this.cardsLayer = new PIXI.Container()
        this.cardsLayer.sortableChildren = true
        this.textLayer = new PIXI.Container()
        this.debugLayer = new PIXI.Container()
        this.debugLayer.alpha = StateGame.debugAlphaOff
    
        this.app = new PIXI.Application();
        
        this.app.stage.eventMode = 'static'
        this.app.stage.on('pointerup', this.onDragEnd);
        this.app.stage.on('pointerupoutside', this.onDragEnd);
        this.dragOffset = new PIXI.Point()
        
        this.upDown = new PIXI.Container()
        this.upDown.visible=false
        CardSpriteCutter.upDownDisplay(this.upDown)
        this.app.stage.addChild(this.upDown)

        


        console.log(`w:${this.app.stage.width} h:${this.app.stage.height}`)
        const cnt = document.getElementById('content');
        await this.app.init({ background: '#087107', resizeTo: window, antialias: true });

        cnt.appendChild(this.app.canvas);

        this.app.stage.width = this.app.canvas.width
        this.app.stage.height = this.app.canvas.height

        this.cpos = new Array(CardIndx.t1)
        this.tpos = new Array(TextFileds.InfoDisplay)

        for(var p=0; p<=CardIndx.t1; p++){
            this.cpos[p]=new PIXI.Point()
        }


        this.setGeometry()

        this.texts = new Array(TextFileds.InfoDisplay)
        for (var i =0; i<TextFileds.InfoDisplay; i++) {
            const txtFld = new PIXI.Text({ text: '', style: { fontFamily: 'brotesque', fontSize: 50, fill: 0x00FF00 } });
            txtFld.position = this.tpos[i]
            this.textLayer.addChild(txtFld)
            this.texts[i]=txtFld
        }

        this.ticker = PIXI.Ticker.shared;
        this.ticker.stop();
        this.ticker.autoStart = false;
        this.ticker.maxFPS = this.maxFPS
        this.ticker.minFPS = 4
        this.ticker.add((aticker) => {   
                if (that.currentGameTask) {
                    that.currentGameTask.tick()
                }
            }
        ); 


        this.app.stage.addChild(this.debugLayer)
        this.app.stage.addChild(this.textLayer)
        this.app.stage.addChild(this.cardsLayer)
        this.mkDebugLayer()

        return new Promise((resolve,reject)=>{
            var myimg = document.createElement("img")
            
            myimg.onload = function(a){
                const source = new PIXI.ImageSource({
                    resource: myimg,
                });
                that.cardsTexture = new PIXI.Texture({
                    source: source
                });
                that.cardsTexture.source.scaleMode = 'linear';
                that.ticker.start()
                resolve()
            }
            myimg.src = 'data:image/png;base64,'+Texi.t
        });
    }

    card(c:CardIndx):CardSprite{
        return this._cards[c]
    }
    
    public get cards():CardSprite[]{
        return this._cards
    }
    
    sortinger(cInHnd:CardSprite, p:PIXI.Point){
        const myCardsOnTable = Game.myCardindxs.map(cind=>this.card(cind)).filter(ot=>ot._index != cInHnd._index)

        const curPos = Game.myCardindxs.find(cui=>{
            const pui = this.cpos[cui]
            return (pui.x <= p.x + this.cardsWidth * 0.5) && ((pui.x + this.cardsWidth) > p.x + this.cardsWidth * 0.5)
        })

        var firstEmptyLeftOf:CardIndx = null
        var firstEmptyRightOf:CardIndx = null
        
        for (var lo = curPos-1; lo>=CardIndx.my0; lo--) {
            if (! myCardsOnTable.find(oti=>oti._sortPos == lo)) {
                firstEmptyLeftOf = lo
                break
            } 
        }

        for (var ro = curPos; ro<=CardIndx.my4; ro++) {
            if (! myCardsOnTable.find(oti=>oti._sortPos == ro)) {
                firstEmptyRightOf = ro
                break
            } 
        }

        const crdUnder = myCardsOnTable.find(otc=>otc._sortPos == curPos)
        // console.log('sortinger', 'cp:', curPos-CardIndx.my0, 'u:', crdUnder?crdUnder._index-CardIndx.my0:'-', 'ot:',onTable.map(o=>o._index-CardIndx.my0).join('.'), 'lh:', firstEmptyLeftOf?firstEmptyLeftOf-CardIndx.my0:'-', 'rh:', firstEmptyRightOf?firstEmptyRightOf-CardIndx.my0:'-')

        if (crdUnder && firstEmptyLeftOf) {
            const rememberSP = crdUnder._sortPos
            for (var li=firstEmptyLeftOf+1; li<=crdUnder._sortPos; li++) {
                var ci = this._cards.find(cip=>cip._sortPos == li)
                ci._sortPos -=1
                ci.position = this.cpos[ci._sortPos]
            }
            cInHnd._sortPos = rememberSP
        } else if (crdUnder && firstEmptyRightOf) {
            const rememberSP = crdUnder._sortPos
            for (var i=firstEmptyRightOf-1; i>=crdUnder._sortPos; i--) {
                var ci = this._cards.find(cip=>cip._sortPos == i)
                ci._sortPos +=1
                ci.position = this.cpos[ci._sortPos]
            }
            cInHnd._sortPos = rememberSP
        }
    }

    onDragStart(event:PIXI.FederatedPointerEvent) {
        // console.log('onDragStart', event)
        const tgt = event.target as CardSprite
        if (!tgt._draggable) {
            console.log('!_draggable', tgt._index)
            return
        }
        this.dragTarget = event.target;
        this.dragOffset.x = event.global.x - this.dragTarget.x
        this.dragOffset.y = event.global.y - this.dragTarget.y
        this.app.stage.on('globalpointermove', this.onDragMove, this);

        this.dragTarget.zIndex = 100
        tgt.getChildAt(0).visible = true //shadow
    }

    onDragMove(event:PIXI.FederatedPointerEvent) {
        var st:StateGame = this;
        // console.log('onDragMove', event, st.dragTarget)
        if (st.dragTarget) {
            let p:PIXI.Point = event.global
            p.x -= this.dragOffset.x
            p.y -= this.dragOffset.y
            st.dragTarget.parent.toLocal(p, null, st.dragTarget.position);

            if (this.myCardsRect.contains(st.dragTarget.position.x, st.dragTarget.position.y)) {
                this.sortinger(st.dragTarget, p)
                st.debugLayer.alpha = StateGame.debugAlphaOn
            } else {
                st.debugLayer.alpha = StateGame.debugAlphaOff
            }
        }
    }

    onDragEnd(event:PIXI.FederatedPointerEvent) {
        // console.log('onDragEnd',event)
        var st:StateGame = App.instance.menu.state as StateGame
        const dragTarget = st.dragTarget
        if (st.dragTarget) {
            st.app.stage.off('globalpointermove', st.onDragMove, st);
            st.dragTarget.zIndex = st.dragTarget._index
            st.dragOffset.x = 0
            st.dragOffset.y = 0
            st.dragTarget.getChildAt(0).visible = false
            st.dragTarget = null;
            st.cardDropped(dragTarget)
        }
        st.debugLayer.alpha = StateGame.debugAlphaOff
    }

    disDraggable(){
        for (var i=CardIndx.my0; i<=CardIndx.my4; i++) {
            var ci = this._cards[i]
            if (ci) {
                ci._draggable = false
            }
        }
    }

    async cardDropped(c:CardSprite){
        const game:Game = App.instance.bummerl.game
        if (this.tableRect.contains(c.x, c.y) && game.isPLayable(c) && ((game.lmess().cmd == 'ausspielen') || (game.lmess().cmd == 'hastgestochenC1') || (game.lmess().cmd == 'hastgestochenC2') || game.cardIsZwangOk(c))) {
            console.log(`cardDropped totable i:${c._index}, c:${c.col}, v:${c.val}`)
            this.disDraggable()
            // c._sortPos = CardIndx.t0
            var ist0:boolean = ((game.lmess().cmd == 'ausspielen')||(game.lmess().cmd == 'hastgestochenC1')||(game.lmess().cmd == 'hastgestochenC2'))
            App.instance.tasks.push(new GameTaskSendCard(game, ist0 ? 'ausspielen' : 'stechen', new Card(c.col, c.val)))
            App.instance.tasks.push(new GameTaskMoveCard(game, new CardAni(c._index, null, ist0 ? CardIndx.t0:CardIndx.t1, null), 'cardDropped1'))
            App.instance.tasks.push(new GameTaskMoveCard(game, new CardAni(ist0 ? CardIndx.t0:CardIndx.t1, ist0 ? CardIndx.t0:CardIndx.t1, ist0 ? CardIndx.t0:CardIndx.t1, new Card(c.col, c.val)), 'cardDropped2'))
            App.instance.tasks.push(new GameTaskMoveCard(game, new CardAni(c._index, c._sortPos, c._sortPos, new Card(Col.Invisible, c.val)), 'cardDropped3'))
            if (c.val == Val.Dame || c.val == Val.Koenig) {
                const p20 = new Card(c.col, c.val == Val.Dame ? Val.Koenig : Val.Dame)
                if (game.hand().has(p20.col, p20.val)) {
                    const p20spr = Game.myCardindxs.map(c=>this.card(c)).find(c=>(c.col == p20.col && c.val == p20.val))
                    if (p20spr) {
                        App.instance.tasks.push(new GameTaskMoveCard(game, new CardAni(p20spr._index, p20spr._sortPos, CardIndx.t1, new Card(p20spr.col, p20spr.val)), 'cardDropped4'))
                        App.instance.tasks.push(new GameTaskWait(game, 300))
                        App.instance.tasks.push(new GameTaskMoveCard(game, new CardAni(p20spr._index, CardIndx.t1, p20spr._sortPos, new Card(p20spr.col, p20spr.val)), 'cardDropped5'))
                    }
                }
            }
            App.instance.tasks.push(new GameTaskSetDraggablePlayable(game, game.lmess()))
            return
        } else if (this.atoutRect.contains(c.x, c.y) && game.austauschenPossible(c)){
            console.log(`cardDropped toatout i:${c._index}, c:${c.col}, v:${c.val}`)
            const oldAt = game.atout
            App.instance.tasks.push(new GameTaskSendCard(game, 'austauschen', new Card(c.col, c.val)))
            App.instance.tasks.push(new GameTaskMoveCard(game, new CardAni(c._index, null, CardIndx.at, new Card(c.col, c.val)), 'cardDropped6'))
            App.instance.tasks.push(new GameTaskMoveCard(game, new CardAni(CardIndx.at, null, CardIndx.at, new Card(c.col, c.val)), 'cardDropped7'))
            App.instance.tasks.push(new GameTaskMoveCard(game, new CardAni(c._index, null, null, new Card(Col.Invisible)), 'cardDropped8'))
            App.instance.tasks.push(new GameTaskWait(game, 300))
            c.val = oldAt.val
            App.instance.tasks.push(new GameTaskMoveCard(game, new CardAni(c._index, CardIndx.at, c._sortPos, oldAt), 'cardDropped9'))
            App.instance.tasks.push(new GameTaskSetDraggablePlayable(game, game.lmess()))

            return
        } 
        
        // flip back if non clause above matched
        App.instance.tasks.push(new GameTaskMoveCard(game, new CardAni(c._index), 'cardDropped-flip1'))
        game.lmess() && App.instance.tasks.push(new GameTaskSetDraggablePlayable(game, game.lmess()))
    }

    zudrehen(){
        App.instance.tasks.push(new GameTaskSendCard(App.instance.bummerl.game, 'zudrehen', null))
    }

    async render(): Promise<string> {
        console.log('render', this.constructor.name)
        return '';
    }

    async addCard(index:CardIndx){
        // console.log(`StateGame addcard `)
        var crd = this.card(index)
        if (!crd){
            crd = new CardSprite(index, this.app)
            this.cardsLayer.addChild(crd)
            crd.on('pointerdown', this.onDragStart,  this);
        }
        crd.eventMode = 'static'
        crd.cursor = 'pointer';
        this._cards[index]=crd;
        crd.position = new PIXI.Point(this.cpos[index].x, this.cpos[index].y)
        crd.scale = StateGame.tcsS
        crd.zIndex = index
        crd._draggable = false
    }

    async showAtout(col:Col){
        var at = this.textLayer.getChildByLabel('at')
        if (!at){
            at = new PIXI.Container()
            at.label = 'at'
            this.textLayer.addChild(at)
        }
        // at = new PIXI.Container()
        CardSpriteCutter.atoutDisplay(at, this.cardsTexture, col, App.instance.bummerl.game.isdd())
        at.position = this.tpos[TextFileds.AtoutDisplay]
        at.scale = StateGame.tcsS
    }

    async writeText(indx:TextFileds, text:string){
        // console.log(`writeText ${indx} ${text}`)
        // const txtFld = new PIXI.Text({ text: text, style: { fontFamily: 'brotesque', fontSize: 50, fill: 0x00FF00 } });
        // txtFld.position = this.tpos[indx]
        // this.textLayer.addChild(txtFld)
        // this.textLayer.setChildIndex(txtFld, indx)
        this.texts[indx].text = text+' '
    }

    show(index:CardIndx, col:Col=Col.Back, val:Val=null):void {
        console.log(`StateGame show ${Col[col]} ${Val[val]}`)
        this._cards[index].show(this.cardsTexture, col, val, App.instance.bummerl.game.isdd())
        this._cards[index].scale = StateGame.tcsS
    }

    setGeometry():void{
        const gx0 = 10
        const gy0 = 10
        const gw = 600
        const gh = 500
        this.cardsWidth = CardSpriteCutter.tcsW * StateGame.tcsS
        this.cardsHeight = CardSpriteCutter.tcsH * StateGame.tcsS
        this.cardSenseMarginRel = 0.36
        
        // this.table.x = gx0 + gw*0.2
        // this.table.y = gy0 + gh*0.35
        this.cardsPosX = 100
        this.myCardsPosY = 500
        this.hisCardsPosY = 50
        this.cardDX = 100
        this.setOneCPos(CardIndx.st, gx0+gw-30, gy0 + 200)
        this.setOneCPos(CardIndx.at, gx0+gw-30 + 6, gy0 + 200 + 6)
        this.setOneCPos(CardIndx.my0, this.cardsPosX +0*this.cardDX, this.myCardsPosY)
        this.setOneCPos(CardIndx.my1, this.cardsPosX +1*this.cardDX, this.myCardsPosY)
        this.setOneCPos(CardIndx.my2, this.cardsPosX +2*this.cardDX, this.myCardsPosY)
        this.setOneCPos(CardIndx.my3, this.cardsPosX +3*this.cardDX, this.myCardsPosY)
        this.setOneCPos(CardIndx.my4, this.cardsPosX +4*this.cardDX, this.myCardsPosY)
        this.setOneCPos(CardIndx.h0, this.cardsPosX +0*this.cardDX, this.hisCardsPosY)
        this.setOneCPos(CardIndx.h1, this.cardsPosX +1*this.cardDX, this.hisCardsPosY)
        this.setOneCPos(CardIndx.h2, this.cardsPosX +2*this.cardDX, this.hisCardsPosY)
        this.setOneCPos(CardIndx.h3, this.cardsPosX +3*this.cardDX, this.hisCardsPosY)
        this.setOneCPos(CardIndx.h4, this.cardsPosX +4*this.cardDX, this.hisCardsPosY)
        this.setOneCPos(CardIndx.ms0, gx0+gw-30, this.myCardsPosY + 20)
        this.setOneCPos(CardIndx.hs0, gx0+gw-30, this.hisCardsPosY - 20)

        this.myCardsRect = new PIXI.Rectangle(
            this.cardsPosX - this.cardsWidth * this.cardSenseMarginRel,
            this.myCardsPosY - this.cardsWidth * this.cardSenseMarginRel,
            4 * this.cardDX + this.cardsWidth + this.cardsWidth * this.cardSenseMarginRel * 2 - this.cardsWidth / 2,  
            this.cardsHeight + this.cardsWidth * this.cardSenseMarginRel * 2 - this.cardsHeight / 2)

        const tableMarginTopCardRel = 0.4
        this.tableRect = new PIXI.Rectangle(this.cardsPosX +0*this.cardDX, this.hisCardsPosY+this.cardsHeight*(1 + tableMarginTopCardRel), 5*this.cardDX,  this.myCardsPosY-this.hisCardsPosY-this.cardsHeight*(1 + 2*tableMarginTopCardRel))

        const atp = this.cpos[CardIndx.at]
        this.atoutRect = new PIXI.Rectangle(atp.x-this.cardsHeight*tableMarginTopCardRel, atp.y-this.cardsHeight*tableMarginTopCardRel, this.cardsWidth + 2*this.cardsWidth*tableMarginTopCardRel, this.cardsHeight + + 2*this.cardsWidth*tableMarginTopCardRel)
        this.setOneCPos(CardIndx.t0, this.tableRect.left + this.tableRect.width / 2 - this.cardsWidth *1.15, this.tableRect.top + this.tableRect.height / 2 - this.cardsHeight / 2)
        this.setOneCPos(CardIndx.t1, this.tableRect.left + this.tableRect.width / 2 + this.cardsWidth *0.15, this.tableRect.top + this.tableRect.height / 2 - this.cardsHeight / 2)

        this.tpos[TextFileds.MyName] = new PIXI.Point(10,10)
        this.tpos[TextFileds.HisName] = new PIXI.Point(gw-120,10)
        this.tpos[TextFileds.MyBummerlPoints] = new PIXI.Point(10,60)
        this.tpos[TextFileds.HisBummerlPoints] = new PIXI.Point(gw-120,60)
        this.tpos[TextFileds.MyGamePoints] = new PIXI.Point(10,120)
        this.tpos[TextFileds.HisGamePoints] = new PIXI.Point(gw-120,120)
        this.tpos[TextFileds.AtoutDisplay] = new PIXI.Point(gx0+gw+80, gy0 + 200)
        this.tpos[TextFileds.InfoDisplay] = new PIXI.Point(gx0+gw+80, gy0 + 200)
        this.upDown.position = new PIXI.Point(this.tableRect.left + (this.tableRect.right - this.tableRect.left)*0.5, this.tableRect.bottom + (this.tableRect.top - this.tableRect.bottom)*0.5)
    }

    setOneCPos(i:number, x:number, y:number):void{
        // console.log(`setOneCPos ${i} ${x} ${y}`)
        this.cpos[i].x = x
        this.cpos[i].y = y
    }

    setUpDown(ud:UpDownDir){
        switch (ud) {
            case UpDownDir.Up:
                this.upDown.visible = true
                this.upDown.getChildAt(0).angle = 180
                break;
            case UpDownDir.Down:
                this.upDown.visible = true
                this.upDown.getChildAt(0).angle = 0
                break;
            default:
                this.upDown.visible = false
                break
        }
    }



    cardlog(){
         return `MyCs:${[CardIndx.my0, CardIndx.my1, CardIndx.my2, CardIndx.my3, CardIndx.my4].map(c=>this.card(c)).map(c=>(c.visible?'V':'I')+'-'+Card.json(new Card(c.col, c.val))).join(' ')}`
    }

    async onExit(){
        this.ticker.stop()
        this.ticker.destroy()
        this.app.stage.removeAllListeners()
        this.app.stage.removeChildren()
        this.app.stop()
        this.app.stage.destroy()
        this.app.destroy()
        this.app = null
    }

    mkDebugLayer(){
        const table = new PIXI.Graphics()
        .rect(this.tableRect.left, this.tableRect.top, this.tableRect.width, this.tableRect.height)
        // .rect(this.cardsPosX-this.cardSenseMarginRel*this.cardsWidth, 
        //     this.myCardsPosY-this.cardSenseMarginRel*this.cardsHeight, 
        //     4 * this.cardDX + this.cardsWidth * (1 + 2 * this.cardSenseMarginRel), 
        //     this.cardsHeight * (1+2*this.cardSenseMarginRel))
        .rect(  
            this.myCardsRect.x,
            this.myCardsRect.y,
            this.myCardsRect.width,
            this.myCardsRect.height)
        .rect(  
            this.atoutRect.x,
            this.atoutRect.y,
            this.atoutRect.width,
            this.atoutRect.height)
        .fill('white')
        this.debugLayer.addChild(table)
    }
}
export default StateGame
export {TextFileds, UpDownDir}














// class CardAtPos {
//   SchnapsenCard card;
//   CardIndx indx;
//   CardAtPos(this.card, this.indx);
// }

// class SchnapsenWorld extends World with HasGameReference<SchnapsenGame> {
//   final _random = Random();

//   final cardGap = SchnapsenGame.cardGap;
//   final topGap = SchnapsenGame.topGap;
//   final cardSpaceWidth = SchnapsenGame.cardSpaceWidth;
//   final cardSpaceHeight = SchnapsenGame.cardSpaceHeight;

//   TextComponent<TextPaint>? commandText;

//   final List<CardAtPos> handPos = [];
//   final List<CardAtPos> hisHandPos = [];
//   late Vector2 playAreaSize;
//   late double handX;
//   late double handY;

//   static final cardPositions = List<Vector2>.filled(24, Vector2(0.0, 0.0), growable: true);
//   List<SchnapsenCard> hisCardDummies = [];
//   late Table tableRect;

//   // @override update(double dt) {

//   // }

//   @override
//   Future<void> onLoad() async {
//     playAreaSize = Vector2(7 * cardSpaceWidth + cardGap, 4 * cardSpaceHeight + topGap);

//     final gameMidX = playAreaSize.x / 2;
//     final gameMidY = playAreaSize.y / 2;

//     // mat.Paint rectPaint = mat.Paint()..color = mat.Colors.green;
//     tableRect = Table(Vector2(gameMidX - cardSpaceWidth / 2.0, gameMidY - cardSpaceHeight / 2), Vector2(cardSpaceWidth * 3.6, cardSpaceHeight * 1.8));
//     add(tableRect);

//     final camera = game.camera;
//     camera.viewfinder.visibleGameSize = playAreaSize;
//     camera.viewfinder.position = Vector2(gameMidX, 0);
//     camera.viewfinder.anchor = Anchor.topCenter;

//     double cdx = cardSpaceWidth * 1.0;
//     double mcy = playAreaSize.y * 0.7;
//     double hcy = cardSpaceHeight * (-0.5);
//     double cpx = playAreaSize.x * 0.1;

//     cardPositions[CardIndx.st.index] = Vector2(playAreaSize.x * 0.83, playAreaSize.y * 0.3);
//     cardPositions[CardIndx.at.index] = Vector2(playAreaSize.x * 0.80, playAreaSize.y * 0.3);
//     cardPositions[CardIndx.my0.index] = Vector2(cpx + 0 * cdx, mcy);
//     cardPositions[CardIndx.my1.index] = Vector2(cpx + 1 * cdx, mcy);
//     cardPositions[CardIndx.my2.index] = Vector2(cpx + 2 * cdx, mcy);
//     cardPositions[CardIndx.my3.index] = Vector2(cpx + 3 * cdx, mcy);
//     cardPositions[CardIndx.my4.index] = Vector2(cpx + 4 * cdx, mcy);

//     cardPositions[CardIndx.h0.index] = Vector2(cpx + 0 * cdx, hcy);
//     cardPositions[CardIndx.h1.index] = Vector2(cpx + 1 * cdx, hcy);
//     cardPositions[CardIndx.h2.index] = Vector2(cpx + 2 * cdx, hcy);
//     cardPositions[CardIndx.h3.index] = Vector2(cpx + 3 * cdx, hcy);
//     cardPositions[CardIndx.h4.index] = Vector2(cpx + 4 * cdx, hcy);

//     cardPositions[CardIndx.ms0.index] = Vector2(playAreaSize.x * 0.9, mcy + cardSpaceHeight * 0.6);
//     cardPositions[CardIndx.hs0.index] = Vector2(playAreaSize.x * 0.9, 0.0 - cardSpaceHeight * 0.6);

//     cardPositions[CardIndx.t0.index] = Vector2(gameMidX - cdx / 2, gameMidY - cardSpaceHeight);
//     cardPositions[CardIndx.t1.index] = Vector2(gameMidX + cdx / 2, gameMidY - cardSpaceHeight);

//     await Flame.images.load('klondike-sprites.png');

//     addButton('Beenden', gameMidX, ButtonAction.quitGame);
//     addCommandText("");
//     addMyName();
    
//     game.startToListen();
//     // game.socket.sendMessage(am:CMess(cmd: 'robotgame'));
//   }

//   CardIndx putAtFirstFreeHisHandPos(SchnapsenCard c) {
//     // log('putAtFirstFreeHisHandPos ${hisHandPos.length}');
//     assert(hisHandPos.length < 5);
//     for (var mpi in [CardIndx.h0, CardIndx.h1, CardIndx.h2, CardIndx.h3, CardIndx.h4]) {
//       CardAtPos? found = hisHandPos.firstWhereOrNull((ci) => (ci.indx == mpi));
//       if (found == null) {
//         var cap = CardAtPos(c, mpi);
//         hisHandPos.add(cap);
//         return cap.indx;
//       }
//     }
//     throw ('no empty pos found');
//   }

//   CardAtPos getRandNonFreeHisHandPos() {
//     assert(hisHandPos.isNotEmpty);
//     return hisHandPos.removeAt(_random.nextInt(hisHandPos.length));
//   }

//   CardIndx putAtFirstFreeHandPos(SchnapsenCard ac) {
//     // log('putAtFirstFreeHandPos ${handPos.length} ${ac.toString()}');
//     assert(handPos.length < 5);
//     for (var hpi in [CardIndx.my0, CardIndx.my1, CardIndx.my2, CardIndx.my3, CardIndx.my4]) {
//       CardAtPos? found = handPos.firstWhereOrNull((ci) => (ci.indx == hpi));
//       if (found == null) {
//         var cap = CardAtPos(ac, hpi);
//         handPos.add(cap);
//         return cap.indx;
//       }
//     }
//     throw ('no empty pos found');
//   }

//   void addButton(String label, double buttonX, ButtonAction action) {
//     final button = FlatButton(
//       label,
//       size: Vector2(SchnapsenGame.cardWidth, 0.6 * topGap),
//       position: Vector2(buttonX, topGap / 2),
//       onReleased: () {
//         if (action == ButtonAction.quitGame) {
//           AutoRouter.of(game.buildContext!).popForced();
//         }
//       },
//     );
//     add(button);
//   }

//   writeText(String txt) {
//     dev.log('writeText $txt');
//     commandText!.text = txt;
//     commandText!.update(0.1);
//   }

//   addCommandText(String txt) {
//     commandText = TextComponent(
//       text: txt,
//       textRenderer: TextPaint(
//         style: TextStyle(
//           fontSize: 0.16 * cardSpaceHeight,
//           fontWeight: FontWeight.bold,
//           color: const Color(0xffdbaf58),
//         ),
//       ),
//       position: Vector2(playAreaSize.x / 2, playAreaSize.y / 2),
//       anchor: Anchor.center,
//     );
//     add(commandText!);
//   }

//   late TextComponent myName;
//   late TextComponent hisName;
//   late TextComponent myBummerlPoints;
//   late TextComponent hisBummerlPoints;
//   late TextComponent myGamePoints;
//   late TextComponent hisGamelPoints;
//   addMyName() {
//     myName = TextComponent(
//       text: "",
//       textRenderer: TextPaint(
//         style: TextStyle(
//           fontSize: 0.16 * cardSpaceHeight,
//           fontWeight: FontWeight.bold,
//           color: const Color(0xffdbaf58),
//         ),
//       ),
//       position: SchnapsenWorld.cardPositions[CardIndx.st.index] + Vector2(0.0, cardSpaceHeight * 2.0),
//       anchor: Anchor.center,
//     );
//     add(myName);

//     hisName = TextComponent(
//       text: "",
//       textRenderer: TextPaint(
//         style: TextStyle(
//           fontSize: 0.16 * cardSpaceHeight,
//           fontWeight: FontWeight.bold,
//           color: const Color(0xffdbaf58),
//         ),
//       ),
//       position: SchnapsenWorld.cardPositions[CardIndx.st.index] - Vector2(0.0, cardSpaceHeight * 1.0),
//       anchor: Anchor.center,
//     );
//     add(hisName);

//     myBummerlPoints = TextComponent(
//       text: "",
//       textRenderer: TextPaint(
//         style: TextStyle(
//           fontSize: 0.16 * cardSpaceHeight,
//           fontWeight: FontWeight.bold,
//           color: const Color(0xffdbaf58),
//         ),
//       ),
//       position: SchnapsenWorld.cardPositions[CardIndx.st.index] + Vector2(0.0, cardSpaceHeight * 2.20),
//       anchor: Anchor.center,
//     );
//     add(myBummerlPoints);

//     hisBummerlPoints = TextComponent(
//       text: "",
//       textRenderer: TextPaint(
//         style: TextStyle(
//           fontSize: 0.16 * cardSpaceHeight,
//           fontWeight: FontWeight.bold,
//           color: const Color(0xffdbaf58),
//         ),
//       ),
//       position: SchnapsenWorld.cardPositions[CardIndx.st.index] - Vector2(0.0, cardSpaceHeight * 0.8),
//       anchor: Anchor.center,
//     );
//     add(hisBummerlPoints);

//     myGamePoints = TextComponent(
//       text: "",
//       textRenderer: TextPaint(
//         style: TextStyle(
//           fontSize: 0.16 * cardSpaceHeight,
//           fontWeight: FontWeight.bold,
//           color: const Color(0xffdbaf58),
//         ),
//       ),
//       position: SchnapsenWorld.cardPositions[CardIndx.st.index] + Vector2(0.0, cardSpaceHeight * 2.40),
//       anchor: Anchor.center,
//     );
//     add(myGamePoints);

//     hisGamelPoints = TextComponent(
//       text: "",
//       textRenderer: TextPaint(
//         style: TextStyle(
//           fontSize: 0.16 * cardSpaceHeight,
//           fontWeight: FontWeight.bold,
//           color: const Color(0xffdbaf58),
//         ),
//       ),
//       position: SchnapsenWorld.cardPositions[CardIndx.st.index] - Vector2(0.0, cardSpaceHeight * 0.6),
//       anchor: Anchor.center,
//     );
//     add(hisGamelPoints);
//   }
// }
