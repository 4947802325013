import App from "../app";
import { CMess, SMess } from "../socket";
import { Icon } from "../utils/icon";
import Strings from "../utils/strings";
import Ui from "../utils/ui";
import State from "./state";

// class RoomLine extends Line {
//     constructor(public id: number, public name: string) {
//         super()
//     }
//     async render(): Promise<string> {
//         return `
//         <div class="line fixedline" data-id="${this.id}">
//             <div class="col1">
//             <img src="https://${App.instance.host}/schnapsen-images/user/${this.id}" class="listitem-icon">
//             </div><!-- col1 -->
//             <div class="col2">
//                 <div class="listitem-name">${this.name}</div><!-- name -->
//             </div><!-- col2 -->
//             <!--div class="col3 icon-edit not-selectable pointer" -->
//             ${this.icons.map(i => i.render()).join('\n')}
//             </div><!-- col3 -->
//         </div><!-- line -->`;
//     }
// }
// class InviteIcon extends Icon {
//     constructor() {
//         super('invite', 'play')
//     }
//     render(da: any): string {
//         return `<div class="icon-play ${da.invI ? 'in' : ''}active" data-clickaction="${da.ac||da.name}"></div>`
//     }
// }

class StateRoom extends State {
    data: SMess;
    id: string
    // iconInvite: Icon
    // constructor() {
    //     super();
    //     // iconInvite: new InviteIcon()
    // }
    async onEnter(data?: any): Promise<void> {
        console.log(this.constructor.name, data, data?.replace(/^.*\//, ''))
        this.id = data?.replace(/^.*\//, '')
        if (!this.data) {
            this.data = await App.instance.socket.sendRoundtrip(new CMess('room', { id: this.id }));
        }
        if (this.data) {
            await super.onEnter(data);
        }
        if (this.data && this.data.data && this.data.data['e']) {
            Ui.popup({ hl: 'Nicht möglich', content: Strings.s(this.data.data['e']) })
        }
    }

    async render(): Promise<string> {
        if (!this.data) {
            this.data = await App.instance.socket.sendRoundtrip(new CMess('room', { id: this.id }));
        }
        // return await super.render();
        // console.log('l', this.data.list);
        return (this.data.list || []).map((l) => `
        <div class="line fixedline" data-id="${l.id}">
            <div class="col1">
            <img src="https://${App.instance.host}/schnapsen-images/user/${l.uid}" class="listitem-icon">
            </div><!-- col1 -->
            <div class="col2">
                <div class="listitem-name">${l.name}</div><!-- name -->
            </div><!-- col2 -->
            <!--div class="col3 icon-edit not-selectable pointer" -->
            ${this.renderInviteIcon(l)}
            ${this.rendeFriendIcon(l)}
            ${this.rendeMessageIcon(l)}
            </div><!-- col3 -->
        </div><!-- line -->`).join('\n');
    }

    rendeFriendIcon(da: any): string {
        return this.renderIcon(`friend${da.invI ? 'add' : 'sub'}`, 'friend', true)
    }
    rendeMessageIcon(da: any): string {
        return this.renderIcon(`mail`, 'message', true)
    }
    renderInviteIcon(da: any): string {
        return this.renderIcon(`play`, 'invite', da.invI?true:true)
    }

    mess(am: SMess): void {
        // console.log(this.constructor.name, 'mess', am);
        switch (am.cmd) {
            case 'room': {
                this.data = am;
                this.render();
            }
            break;
            case 'invite': {
                console.log('invite', am)
            }
            break;
        }
    }

    async click(ac: string, tgt: JQuery<HTMLElement>=null): Promise<void> {
        const uid = parseInt(tgt.closest('.line[data-id]').attr('data-id'))
        switch(ac) {
            case 'invite':
                const cret = await App.instance.socket.sendRoundtrip(new CMess('invite', { id: uid })); 
                break;
        }
        console.log(this.constructor.name, 'click', ac);
        return null;
    }
}
export default StateRoom