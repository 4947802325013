import App from "../app";
import { FileToPost } from "./file";
import { keyable } from "./storage";
import Strings from "./strings";
// import Fetch from 'fetch';

class Ui {
    static button(options: any) {
        console.log('buttie', options, typeof (options))
        const op: any = {}
        if (typeof (options) == 'string') {
            op['clickaction'] = options
            op['text'] = options
        } else {
            op['clickaction'] = options['clickaction'] || 'test'
            op['text'] = options['text'] || 'test'
            op.class = options.class||''
        }
        console.log('button op', op)
        const classes = (op.class || '').split(/[\s*|\,*]+/).filter((s: string) => s)
        if ((!(op.active === false)) && (!(classes.find((c: string) => c == 'active')))) {
            classes.push('active')
        }
        console.log('button', classes, (!(op.active === false)), (!(classes.find((c: string) => c == 'active'))))

        return `<button ${op.clickaction ? `class="${classes.join(' ')}" data-clickaction="${op.clickaction}"` : ''}>${op.text ? Strings.s(op.text, op.ex || '') : "klick"}</button>`;
    }

    async fetchGET(url: string): Promise<any> {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(
                    `Unable to Fetch Data, Please check URL
                    or Network connectivity!!`
                );
            }
            const data = await response.json();
            return data.results[0];
        } catch (error) {
            console.error('Some Error Occured:', error);
        }
    }
    static formatTime(at: Date) {
        return (('0' + at.getHours()).substr(-2)) + ':' + (('0' + at.getMinutes()).substr(-2));
    }
    static enrich(sel: string) {
        const selDom = $(sel);
        // console.log('enricosel', sel, selDom);

        selDom.find('[data-clickaction]').on('click', (ev) => {
            const tgt = $(ev.target);
            const ac = $(ev.target).attr('data-clickaction');
            const active = tgt.hasClass('active');
            if (!active) {
                console.log(ac, '! active');
                return;
            }
            tgt.removeClass('active');
            // console.log('enrico', ac, $(ev.target));
            App.instance.click(ac, tgt);
        });

        // console.log('enrico [data-file-upload]', selDom.find('[data-file-upload]'));

        selDom.find('[data-file-upload]').on('change', (ev) => {
            const tgt = $(ev.target);
            let file = (<HTMLInputElement>ev.target).files[0];
            // console.log(file);
            App.instance.menu.state.file(new FileToPost(file, tgt.attr('data-file-upload'), parseInt(tgt.attr('data-file-upload-uid')) || 0));
        });
    }
    static isFunction(f:any):boolean{
        return f && (typeof(f)=='function')
    }
    static isActive(op:any, d:any):boolean{
        return Ui.isFunction(op)?op(d):op?true:false
    }


    ////////////// popup
    static lastPopupId: number = 1
    static popup(ad: keyable): string {
        Ui.lastPopupId++;
        const id: string = `popup-${Ui.lastPopupId}`;
        const butR = ad['butR'] ? Ui.button(ad['butR']) : Ui.button({ clickaction: 'closepopup', class: 'right', text: 'ok' })
        const butL = ad['butL'] ? Ui.button(ad['butL']) : ''
        $('#modal').show().append(`<div class="popup" id="${id}"><div class="headline">${ad.hl || ''}</div><div class="content">${ad.content || ''}</div><div class="buttons">${butL}${butR}</div></div>`)
        Ui.enrich(`#${id}`)
        return id;
    }
    static popup_close(ac: string, tgt: JQuery<HTMLElement>) {
        const id = tgt.closest('.popup').attr('id')
        console.log('removing popup', id, tgt)
        $(`#${id}`).remove()
        if ($('#modal .popup').length == 0) {
            $('#modal').hide()
        }
    }
}

export default Ui;
