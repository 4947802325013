import Game from "./game";

class Bummerl {
    id: string
    game: Game
    constructor(id:string){
        this.id=id;
    }
}

export default Bummerl