import App from "../app";
import { SMess } from "../socket";
import { AppTaskBase } from "./task.base";
import { AppTaskType } from "./task.type";

class AppTaskMessage extends AppTaskBase {
    message: SMess;
    constructor(me: SMess) {
        super(AppTaskType.SMessage)
        this.message = me
    }
    async fire() {
        await super.fire()
        await App.instance.dispatchMess(this.message)
        App.instance.currentTask = null;
    }
}

export {AppTaskMessage}