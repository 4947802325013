import { SMess } from "../socket";
import { Bot, RobotStateMessageType } from "./bot";

class BotBase {
    bot: Bot;
    constructor(b: Bot) {
        this.bot = b
    }

    async dispatch(me: SMess) {

    }

    async stateMessage(am: RobotStateMessageType, d?: any) {

    }

    ranT(dt:number,dtran:number = 0){
        return Math.floor(Math.max(0, dt + Math.random()*dtran - dtran*0.5))
    }

    scheduleEvent(dt: number, am: RobotStateMessageType, d?: any): ReturnType<typeof setTimeout> {
        // (() => {
        const that = this;
        const ret: ReturnType<typeof setTimeout> = setTimeout(function () {
            // console.log('R* event fired');
            that.bot.stateMessage(am, d)
        }, dt)
        return ret
        // })()
    }
}

export default BotBase