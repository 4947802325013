import App from "../app";
import { FileToPost } from "../utils/file";
import Strings from "../utils/strings";
import Ui from "../utils/ui";
import State from "./state";

class StateProfile extends State {
    // resEv: UploadResponseEvent ;
    // constructor() {
    //     super();
    //     // this.resEv = UploadResponseEvent;
    // }
    async render(): Promise<string> {
        if (!App.instance.login.user) {
            Ui.popup({hl:Strings.s('error'), content:Strings.s('notloggedin')})
            return
        }
        console.log(this.constructor.name, App.instance.host, App.instance.login.user.uid);
        return `this is profile<br>
        name:${App.instance.login.user.name}<br>
        firstname:${App.instance.login.user.firstname}<br>
        lastname:${App.instance.login.user.lastname}<br>
        <img src="https://${App.instance.host}/schnapsen-images/user/${App.instance.login.user.uid}?size=h">` +
            `<input type="file" id="profileimageupload" data-file-upload="profile" data-file-upload-uid="${App.instance.login.user.uid}"/>
            ${Ui.button('logoff')}`;
    }
    async uploadImage() {

    }

    async sendData(data: File) {
        try {
            const url = `https://${App.instance.host}/schnapsen-images/profile/me`;

            const formData = new FormData();

            // for (const name in data) {
            formData.append('file', data);
            // }

            const response = await fetch(url, {
                method: 'POST',
                body: formData,
                headers: {
                    "authorisation": `Bearer ${App.instance.login.token}`
                }
            });

            console.log("sendData", response);

            this.render();
        } catch (e) {
            console.log(e);
        }
    }


    file(am: FileToPost): void {
        console.log(this.constructor.name, 'file', am);

        this.sendData(am.file);
    }

    async click(ac: string, tgt: JQuery<HTMLElement>=null): Promise<void> {
        console.log(this.constructor.name, 'click', ac);
        if(ac == 'logoff'){
            App.instance.login.logoff();
            App.instance.menu.go()
            App.instance.menu.init(false)
            return;
        }
        console.log(this.constructor.name, 'undis click', ac);
        return null;
    }
}


export default StateProfile