import App from "../app";
import CardSprite from "../game/cardsprite";
import Game, { CardIndx } from "../game/game";
import { Col, Val } from "../game/game.card.enums";
import { SMess } from "../socket";
import { AppTaskType } from "../tasks/task.type";
import { CardAni, GameTaskBase, GameTaskMoveCard } from "../tasks/tasks.game";
import { Bot } from "./bot";
import BotBase from "./bot-base";
import GamePlayBot, { BotAnswer, BotBrain, BotCard, BotCol, BotInMessages, BotState, BotVal, BotZugType } from "./gameplay-bot";
import * as PIXI from 'pixi.js'

class GameTaskBotDroppedCard extends GameTaskBase {
    constructor(g: Game, private cs: CardSprite) {
        super(g, AppTaskType.BotDroppedCard)
    }
    async fire() {
        // console.log(`${this.constructor.name} ${this.cs._index}`)
        await this.g.stage().cardDropped(this.cs)
        this.gameTaskDone()
    }
}

class GameTaskMoveCardToPos extends GameTaskMoveCard {
    constructor(g: Game, mc: CardAni, p1pix: PIXI.Point, public info: string) {
        super(g, mc, info)
        this.p1 = p1pix
    }
}

class GameTaskBotZudrehen extends GameTaskBase {
    constructor(g: Game) {
        super(g, AppTaskType.BotZudrehen)
    }
    async fire() {
        // console.log(`${this.constructor.name}`)
        this.g.stage().zudrehen()
        this.gameTaskDone()
    }
}

class BotDispatchInMessage extends GameTaskBase {
    constructor(g: Game, private sm: SMess) {
        super(g, AppTaskType.BotDroppedCard)
    }
    async fire() {
        // console.log(`${this.constructor.name} ${this.sm.cmd}`)
        await (App.instance.bot.gameBot as GameBot).dispatchInOrder(this.sm)
        this.gameTaskDone()
    }
}

class GameBot extends BotBase {
    brain: BotBrain
    myStiche: BotCard[]
    hisStiche: BotCard[]
    myPoints20: number
    hisPoints20: number
    lMess: SMess
    constructor(b: Bot) {
        super(b);
        this.brain = new BotBrain()
        this.myStiche = new Array()
        this.hisStiche = new Array()
    }
    reset() {
        this.myStiche.length = 0
        this.hisStiche.length = 0
        this.myPoints20 = 0
        this.hisPoints20 = 0
    }
    async dispatch(me: SMess) {
        if (App.instance.bummerl && App.instance.bummerl.game && me.cmd != 'ping') {
            App.instance.tasks.push(new BotDispatchInMessage(App.instance.bummerl.game, me))
        }
    }
    async dispatchInOrder(me: SMess) {

    }

    async dispatchGameMessage(me: SMess) {
        try {
            // console.log(`GameBot dispatchGameMessage`, me.cmd)
            switch (me.cmd) {
                case BotInMessages[BotInMessages.hastgestochenC1]:
                case BotInMessages[BotInMessages.hastgestochenC2]:
                case BotInMessages[BotInMessages.wurdegestochenC1]:
                case BotInMessages[BotInMessages.wurdegestochenC2]:
                case BotInMessages[BotInMessages.ausspielen]:
                case BotInMessages[BotInMessages.stechen]:
                case BotInMessages[BotInMessages.stechen20]:
                case BotInMessages[BotInMessages.warte]:
                case BotInMessages[BotInMessages.ausgetauscht]:
                case BotInMessages[BotInMessages.gamestart]:
                case BotInMessages[BotInMessages.gameend]:
                case BotInMessages[BotInMessages.bummerlstart]:
                case BotInMessages[BotInMessages.bummerlend]:
                case BotInMessages[BotInMessages.zugedreht]:
                case BotInMessages[BotInMessages.gemeend]:
                // case BotInMessages[BotInMessages.gspieltaus]:
                case BotInMessages[BotInMessages.timeout]:
                    if (!(App.instance.bummerl && Game.ckGameContinues(me))) {
                        console.log(`GameBot ! g continues`, me.cmd)
                        return
                    }
                    break;
                default:
                    return
            }
            if (!(App.instance.bummerl && Game.ckGameContinues(me))) {
                console.log(`GameBot ! g continues`, me.cmd)
                return
            }
            // console.log(`> GameBot dispatchInOrder ${me.cmd}`)
            switch (me.cmd) {
                case BotInMessages[BotInMessages.hastgestochenC1]:
                case BotInMessages[BotInMessages.hastgestochenC2]:
                    this.myStiche.push(BotCard.from(me.data.table[0]))
                    this.myStiche.push(BotCard.from(me.data.table[1]))
                    // console.log(`Bot dispatchInOrder )))) stichprobe m:${this.myStiche.length} h:${this.hisStiche.length} d:${me.data.deckSize} SUM:${me.data.deckSize+this.myStiche.length+this.hisStiche.length}`)
                    break
                case BotInMessages[BotInMessages.wurdegestochenC1]:
                case BotInMessages[BotInMessages.wurdegestochenC2]:
                    this.hisStiche.push(BotCard.from(me.data.table[0]))
                    this.hisStiche.push(BotCard.from(me.data.table[1]))
                    // console.log(`Bot dispatchInOrder )))) stichprobe m:${this.myStiche.length} h:${this.hisStiche.length} d:${me.data.deckSize} SUM:${me.data.deckSize+this.myStiche.length+this.hisStiche.length}`)
                    break
                default:
            }
            switch (me.cmd) {
                case BotInMessages[BotInMessages.ausspielen]:
                case BotInMessages[BotInMessages.hastgestochenC2]:
                case BotInMessages[BotInMessages.hastgestochenC1]:
                case BotInMessages[BotInMessages.stechen]:
                case BotInMessages[BotInMessages.stechen20]:
                    // this.action(GamePlayBot.inMessage(BotInMessages[me.cmd as keyof typeof BotInMessages], new BotState(me.data.zugC, me.data.state, me.data.hand.map(BotCard.from), BotCard.from(me.data.atout), me.data.table.map(BotCard.from), me.data.zwang, me.data.deckC, this.hisStiche, this.myStiche), this.brain))
                    this.delayedZug(me);
                    break;
                case BotInMessages[BotInMessages.warte]:
                case BotInMessages[BotInMessages.wurdegestochenC2]:
                case BotInMessages[BotInMessages.wurdegestochenC1]:
                case BotInMessages[BotInMessages.ausgetauscht]:
                case BotInMessages[BotInMessages.gamestart]:
                case BotInMessages[BotInMessages.gameend]:
                case BotInMessages[BotInMessages.bummerlstart]:
                case BotInMessages[BotInMessages.bummerlend]:
                case BotInMessages[BotInMessages.zugedreht]:
                case BotInMessages[BotInMessages.gemeend]:
                case BotInMessages[BotInMessages.gspieltaus]:
                case BotInMessages[BotInMessages.timeout]:
                    break;
                default:
            }
            if (me.data && me.data.hand) {
                this.lMess = me
            }
        } catch (e) {
            console.log(`> GameBot * ${me.cmd}`, e)
        }
    }
    // async dispatchInOrder(me: SMess) {
    //     try {
    //         switch (me.cmd) {
    //             case BotInMessages[BotInMessages.hastgestochenC1]:
    //             case BotInMessages[BotInMessages.hastgestochenC2]:
    //             case BotInMessages[BotInMessages.wurdegestochenC1]:
    //             case BotInMessages[BotInMessages.wurdegestochenC2]:
    //             case BotInMessages[BotInMessages.ausspielen]:
    //             case BotInMessages[BotInMessages.stechen]:
    //             case BotInMessages[BotInMessages.stechen20]:
    //             case BotInMessages[BotInMessages.warte]:
    //             case BotInMessages[BotInMessages.ausgetauscht]:
    //             case BotInMessages[BotInMessages.gamestart]:
    //             case BotInMessages[BotInMessages.gameend]:
    //             case BotInMessages[BotInMessages.bummerlstart]:
    //             case BotInMessages[BotInMessages.bummerlend]:
    //             case BotInMessages[BotInMessages.zugedreht]:
    //             case BotInMessages[BotInMessages.gemeend]:
    //             // case BotInMessages[BotInMessages.gspieltaus]:
    //             case BotInMessages[BotInMessages.timeout]:
    //                 if (!(App.instance.bummerl && Game.ckGameContinues(me))) {
    //                     console.log(`GameBot ! g continues`, me.cmd)
    //                     return
    //                 }
    //                 break;
    //             default:
    //                 return
    //         }
    //         if (!(App.instance.bummerl && Game.ckGameContinues(me))) {
    //             console.log(`GameBot ! g continues`, me.cmd)
    //             return
    //         }
    //         // console.log(`> GameBot dispatchInOrder ${me.cmd}`)
    //         switch (me.cmd) {
    //             case BotInMessages[BotInMessages.hastgestochenC1]:
    //             case BotInMessages[BotInMessages.hastgestochenC2]:
    //                 this.myStiche.push(BotCard.from(me.data.table[0]))
    //                 this.myStiche.push(BotCard.from(me.data.table[1]))
    //                 // console.log(`Bot dispatchInOrder )))) stichprobe m:${this.myStiche.length} h:${this.hisStiche.length} d:${me.data.deckSize} SUM:${me.data.deckSize+this.myStiche.length+this.hisStiche.length}`)
    //                 break
    //             case BotInMessages[BotInMessages.wurdegestochenC1]:
    //             case BotInMessages[BotInMessages.wurdegestochenC2]:
    //                 this.hisStiche.push(BotCard.from(me.data.table[0]))
    //                 this.hisStiche.push(BotCard.from(me.data.table[1]))
    //                 // console.log(`Bot dispatchInOrder )))) stichprobe m:${this.myStiche.length} h:${this.hisStiche.length} d:${me.data.deckSize} SUM:${me.data.deckSize+this.myStiche.length+this.hisStiche.length}`)
    //                 break
    //             default:
    //         }
    //         switch (me.cmd) {
    //             case BotInMessages[BotInMessages.ausspielen]:
    //             case BotInMessages[BotInMessages.hastgestochenC2]:
    //             case BotInMessages[BotInMessages.hastgestochenC1]:
    //             case BotInMessages[BotInMessages.stechen]:
    //             case BotInMessages[BotInMessages.stechen20]:
    //                 // this.action(GamePlayBot.inMessage(BotInMessages[me.cmd as keyof typeof BotInMessages], new BotState(me.data.zugC, me.data.state, me.data.hand.map(BotCard.from), BotCard.from(me.data.atout), me.data.table.map(BotCard.from), me.data.zwang, me.data.deckC, this.hisStiche, this.myStiche), this.brain))
    //                 this.delayedZug(me);
    //                 break;
    //             case BotInMessages[BotInMessages.warte]:
    //             case BotInMessages[BotInMessages.wurdegestochenC2]:
    //             case BotInMessages[BotInMessages.wurdegestochenC1]:
    //             case BotInMessages[BotInMessages.ausgetauscht]:
    //             case BotInMessages[BotInMessages.gamestart]:
    //             case BotInMessages[BotInMessages.gameend]:
    //             case BotInMessages[BotInMessages.bummerlstart]:
    //             case BotInMessages[BotInMessages.bummerlend]:
    //             case BotInMessages[BotInMessages.zugedreht]:
    //             case BotInMessages[BotInMessages.gemeend]:
    //             case BotInMessages[BotInMessages.gspieltaus]:
    //             case BotInMessages[BotInMessages.timeout]:
    //                 break;
    //             default:
    //         }
    //         if (me.data && me.data.hand) {
    //             this.lMess = me
    //         }
    //     } catch (e) {
    //         console.log(`> GameBot * ${me.cmd}`, e)
    //     }
    // }

    delayedZug(me: SMess) {
        setTimeout(function () {
            this.action(GamePlayBot.inMessage(BotInMessages[me.cmd as keyof typeof BotInMessages], new BotState(me.data.zugC, me.data.state, me.data.hand.map(BotCard.from), BotCard.from(me.data.atout), me.data.table.map(BotCard.from), me.data.zwang, me.data.deckC, this.hisStiche, this.myStiche), this.brain))
        }.bind(this), 300 + 300 * Math.random())
    }

    action(a: BotAnswer) {
        console.log(`> GameBot action ${BotZugType[a.type]} c:${a.card ? BotCol[a.card.col] + '+' + BotVal[a.card.val] : '-'}`)
        if (!(App.instance.bummerl && App.instance.bummerl.game)) {
            console.log(`GameBot !(App.instance.bummerl && App.instance.bummerl.game)`)
            return
        }

        switch (a.type) {
            case BotZugType.ausspielen:
            case BotZugType.stechen:
                const cs0 = this.cardSprite(a.card)
                if (!cs0) {
                    console.log(`not a card, ${BotCol[a.card.col]} ${BotVal[a.card.val]}`)
                    return
                }
                this.scheduleCard(cs0, new CardAni(cs0._index, null, a.type == BotZugType.ausspielen ? CardIndx.t0 : CardIndx.t1, null), 'bot movecard 1')
                break;
            case BotZugType.austauschen:
                const cs1 = this.cardSprite(a.card)
                if (!cs1) {
                    console.log(`not a card, ${BotCol[a.card.col]} ${BotVal[a.card.val]}`)
                    return
                }
                this.scheduleCard(cs1, new CardAni(cs1._index, null, CardIndx.at, null), 'bot movecard 2')
                break
            case BotZugType.zudrehen:
                const g = (App.instance.bummerl && App.instance.bummerl.game) ? App.instance.bummerl.game : null
                if (!g) {
                    return
                }
                App.instance.tasks.push(new GameTaskBotZudrehen(g))
            case BotZugType.error:
        }


    }

    cardSprite(bc: BotCard): CardSprite {
        console.log(`GameBot cardSprite col:${bc ? bc.col : 'na'} val:${bc ? bc.val : 'na'} ${Game.myCardindxs.map(i => App.instance.bummerl.game.stage().card(i)).map(c => Col[c.col] + '-' + Val[c.val]).join('|')}`)
        return App.instance.bummerl.game.stage().cards.find(c => ((Col[c.col] == BotCol[bc.col]) && (Val[c.val] == BotVal[bc.val])))
    }

    scheduleCard(cs: CardSprite, ani: CardAni, comment: string) {
        // setTimeout(function () {
        // console.log(`> GameBot scheduleCard ${ani.card}`)
        const g = (App.instance.bummerl && App.instance.bummerl.game) ? App.instance.bummerl.game : null
        if (!g) {
            return
        }
        const t = App.instance.bummerl.game.stage().tableRect
        // App.instance.tasks.push(new GameTaskMoveCardToPos(g, ani, new PIXI.Point(t.left + t.width / 2, t.top + t.height / 2), comment))
        App.instance.tasks.push(new GameTaskMoveCard(g, ani, comment))
        App.instance.tasks.push(new GameTaskBotDroppedCard(g, cs))
        // }, 3000 + 3000 * Math.random())
    }

}

export default GameBot







