import State from "./state";

class StateRules extends State {
    async onEnter(data?:any): Promise<void> {
        await super.onEnter(data);
    }
    async render(): Promise<string> {
        // console.log('render', this.constructor.name)
        return 'this is rules</br>Schnapsen spielt man zu zweit';
    }
    async click(ac: string, tgt: JQuery<HTMLElement>=null): Promise<void> {
        switch (ac) {
            // case 'openpopup':
            //     const id= Ui.popup({headline:'Nice Popup'})
            //     break;
        }
        return null;
    }
}
export default StateRules