import { SMess } from "../socket";
import { FileToPost } from "../utils/file";
import Ui from "../utils/ui";
import Utils from "../utils/utils";

class State {
    constructor(public link:string) {
        console.log('^^^^^^^^   structor', this.constructor.name, '^^^^^^^^^')
    }
    async onEnter(data?: any): Promise<void> {
        $('#content').html(await this.render());
        Ui.enrich('#content');
    }
    async render(): Promise<string> {
        return '';
    }
    async click(ac: string, tgt: JQuery<HTMLElement>=null): Promise<void> {
        console.log(this.constructor.name, 'undis click', ac);
        return null;
    }
    mess(am: SMess): void {
        console.log(this.constructor.name, 'mess', am);
    }
    file(am: FileToPost): void {
        console.log(this.constructor.name, 'file', am);
    }
    renderIcon(icon:string, action:string, active:boolean):string{
        return `<div class="icon-${icon} ${active?'':'in'}active" data-clickaction="${action}"></div>`
    }
    tick():void{
    }
    async onExit(): Promise<void> {
        // console.log('State', 'onExit');
    }
}

export default State